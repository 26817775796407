import {Reservation} from '../../models/reservation.model';

export const getEventsForReservation = (reservations: Array<Reservation>) => {
  const events = [];

  reservations
    .filter(r => r.reservationNr)
    .forEach(reservation => {
      if (!events.find(e => e.value === reservation.eventDateId)) {
        events.push({
          text: new Date(reservation.eventDateStart).toLocaleDateString(),
          value: reservation.eventDateId
        });
      }
    });

  return events;
};


export const getReservationPickerList = (reservations: Array<Reservation>) => {
  const reservationPickerList = [];

  reservations.forEach(reservation => {
    if (!reservationPickerList.find(e => e.text === reservation.reservationNr)) {
      reservationPickerList.push({text: reservation.reservationNr, value: reservation});
    }
  });

  return reservationPickerList;
};
