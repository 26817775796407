import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {TranslateService} from '@ngx-translate/core';
import {VoucherState, VoucherStatus} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {VOUCHERCODE_NOT_ACTIVATED} from '../../../../../../shared-libs/lib-core/src/models/status/voucher.status.constants';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-redeem-overview-segments',
  templateUrl: './redeem-overview-segments.component.html',
  styleUrls: ['./redeem-overview-segments.component.scss'],
})
export class RedeemOverviewSegmentsComponent implements OnInit, OnDestroy {

  @Input() canSeeHistory = false;
  @Input() state: VoucherState = 'redeem';
  @Input() readonly = false;
  @Input() voucher: VoucherStatus;
  @Output() stateChanged: EventEmitter<any> = new EventEmitter();

  public config: RedemptionConfig;
  private subscriptions: Subscription[] = [];

  constructor(
    private redemptionService: RedemptionService,
    private cachedHttpClient: HttpCachedClient,
    private uiFactory: UIFactoryService,
    private t: TranslateService,
  ) {
    this.subscriptions.push(this.redemptionService.voucher$.subscribe(v => {
      if (v) {
        this.voucher = v;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    if (this.voucher?.Status === VOUCHERCODE_NOT_ACTIVATED) {
      this.state = 'charge';
    }

    await this.fetchConfig();
  }

  private async fetchConfig() {
    this.config = await this.cachedHttpClient.getRaw('/api/redeemapp/config');
  }

  public async checkRedeemSwitch(): Promise<void> {
    if (this.voucher?.Status === VOUCHERCODE_NOT_ACTIVATED) {
      const alert = await this.uiFactory.build(UIType.ALERT,
        {
          backdropDismiss: false,
          header: await this.t.get('main-detail.checkRedeemSwitch.title').toPromise(),
          subHeader: await this.t.get('main-detail.checkRedeemSwitch.subTitle').toPromise(),
          buttons: [
            {
              text: await this.t.get('main-detail.checkRedeemSwitch.button').toPromise(),
              handler: () => {
                this.state = 'charge';
                this.notifyChanged();
              }
            }
          ]
        }
      );

      await alert.present();
    }
  }

  public notifyChanged() {
    this.stateChanged.emit(this.state);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}

