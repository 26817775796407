import {RouterModule, Routes} from '@angular/router';
import {EventManagementListComponent} from './event-management-list/event-management-list.component';
import {EventManagementDatesComponent} from './event-management-dates/event-management-dates.component';
import {NgModule} from '@angular/core';
import {EventManagementTicketsComponent} from './event-management-tickets/event-management-tickets.component';

const eventManagementRoutes: Routes = [
  {
    path: '', component: EventManagementListComponent
  },
  {
    path: 'offlineTickets/:id', component: EventManagementTicketsComponent,
  },
  {
    path: ':eventId/dates', component: EventManagementDatesComponent,
  },
  {
    path: ':eventId/dates/:dateId/participants', component: EventManagementTicketsComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(eventManagementRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class EventManagementRoutingModule {
}
