import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainPageComponent} from './main-page.component';
import {RedeemHistoryComponent} from '../redeem-overview/redeem-history/redeem-history.component';
import {RedeemDataComponent} from '../redeem-overview/redeem-data/redeem-data.component';
import {MainRoutingModule} from './main-routing.module';
import {RedeemOverviewComponent} from '../redeem-overview/redeem-overview.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IonicModule} from '@ionic/angular';
import {RedeemChargeComponent} from '../redeem-overview/redeem-charge/redeem-charge.component';
import {RedeemShowComponent} from '../redeem-overview/redeem-show/redeem-show.component';
import {SharedModule} from '../../../../../shared-libs/lib-core/src/shared.module';
import {RedeemShowOrderComponent} from '../redeem-overview/redeem-show-order/redeem-show-order.component';
import {SwiperModule} from 'swiper/angular';
import {MainUpcomingEventsComponent} from './main-upcoming-events/main-upcoming-events.component';
import {MainLastSearchedProductsComponent} from './main-last-searched-products/main-last-searched-products.component';
import {MainSearchCodeComponent} from './main-search-code/main-search-code.component';
import {RedeemOverviewSegmentsComponent} from '../redeem-overview/redeem-overview-segments/redeem-overview-segments.component';
import {MainSearchCodeModalComponent} from './main-search-code-modal/main-search-code-modal.component';
import {ProductDisplayComponent} from '../redeem-overview/redeem-show/product-display/product-display.component';
import {
  RedeemShowOfflineVoucherComponent
} from "../redeem-overview/redeem-show/redeem-show-offline-voucher/redeem-show-offline-voucher.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    MainPageComponent,
    RedeemOverviewComponent,
    RedeemShowOrderComponent,
    RedeemHistoryComponent,
    RedeemDataComponent,
    RedeemChargeComponent,
    RedeemShowComponent,
    ProductDisplayComponent,
    MainSearchCodeComponent,
    MainUpcomingEventsComponent,
    MainLastSearchedProductsComponent,
    RedeemOverviewSegmentsComponent,
    MainSearchCodeModalComponent,
    RedeemShowOfflineVoucherComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    SwiperModule,
    MainRoutingModule,
    IonicModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    MainPageComponent,
    RedeemHistoryComponent,
    RedeemOverviewComponent,
    RedeemDataComponent,
    RedeemShowComponent,
    RedeemShowOrderComponent,
  ],
  providers: []
})
export class MainModule {
}
