import {Component, OnInit} from '@angular/core';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {MainSearchCodeModalComponent} from '../main-search-code-modal/main-search-code-modal.component';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {MainScanCodeModalComponent} from '../main-scan-code-modal/main-scan-code-modal.component';
import {getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {IonRouterOutlet, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';


@Component({
  selector: 'app-main-find-codes',
  templateUrl: './main-search-code.component.html',
  styleUrls: ['./main-search-code.component.scss'],
})
export class MainSearchCodeComponent implements OnInit {

  public showCovidScanBox = false;

  constructor(
    private uiFactoryService: UIFactoryService,
    private uiManager: UIManagerService,
    private platform: Platform,
    private ionRouterOutlet: IonRouterOutlet,
    private t: TranslateService,
    private connectionService: ConnectionService,
  ) {
  }

  ngOnInit() {
  }

  /**
   * @BUG
   * Keyboard does not move with the view correclty
   * desired setting: initialBreakpoint: 0.5
   * current setting: initialBreakpoint: 0.6
   */
  public async openExtendedSearchView() {
    await Haptics.impact({style: ImpactStyle.Medium});

    if (this.connectionService.isOffline$.getValue()){
      await this.uiManager.showToast(this.t.instant('tickets.offlineNoSearch'), 'warning');
    }else {
      this.uiFactoryService.build(UIType.MODAL, {
        component: MainSearchCodeModalComponent,
        mode: 'ios',
        showBackdrop: true,
        canDismiss: true,
        backdropDismiss: true,
        breakpoints: [0.5, 0.6, 1],
        initialBreakpoint: 0.6
      }).then(t => t.present());
    }
  }

  public async openExtendedScanView() {
    await Haptics.impact({style: ImpactStyle.Medium});

    const modal = await this.uiFactoryService.build(UIType.MODAL, {
      component: MainScanCodeModalComponent,
      mode: 'ios',
      canDismiss: true,
      showBackdrop: true,
      presentingElement: getPlatform(this.platform) ? await this.ionRouterOutlet.nativeEl : null,
    });

    await modal.present();

    return modal;
  }

  removePrefix(text) {
    return text.replace('HC1:', '');
  }
}
