import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'inc-fab-single',
  templateUrl: './inc-fab-single.component.html',
  styleUrls: ['./inc-fab-single.component.scss'],
})
export class IncFabSingleComponent implements OnInit {

  @Input() horizontal: string;

  @Input() vertical: string;

  @Input() offsetBottom = 5;

  @Input() onClick: () => void | null;

  @Input() icon: string;

  constructor() {
  }

  ngOnInit() {
  }

}
