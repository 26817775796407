/**
 * Catching HTTPRequest errors indicating server communication failures
 */
export class HTTPRequestError extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, HTTPRequestError.prototype);
  }

  printError() {
    return this.message;
  }
}
