import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UIFactoryService} from '../../services/ui-factory.service';
import {SettingsService} from '../../services/settings.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-inc-slide-item',
  templateUrl: './inc-slide-item.component.html',
  styleUrls: ['./inc-slide-item.component.scss'],
})
export class IncSlideItemComponent implements OnInit, OnDestroy {

  @Input() canClose = false;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() content: string;
  @Input() imageSource: string;
  @Input() lastSlide: boolean;
  @Input() firstSlide: boolean;
  @Output() languageChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions: Subscription[] = [];

  constructor(
    private uiFactory: UIFactoryService,
    private settingsService: SettingsService) {
  }

  ngOnInit() {
  }

  public async closeModal() {
    this.subscriptions.push(this.settingsService.settings$.subscribe(async settings => {
      settings.hasSeenIntroduction = true;

      await this.settingsService.settings$.next(settings);

      await this.uiFactory.dismissModal();
    }));
  }

  public async toggleLanguage() {
    this.subscriptions.push(this.settingsService.settings$.subscribe(async settings => {
      settings.language = settings.language === 'en' ? 'de' : 'en';

      await this.settingsService.settings$.next(settings);

      this.languageChanged.emit(true);
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
