import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {VoucherStatus} from '../../models/voucher.status';
import {SettingsService} from '../../services/settings.service';
import {Subscription} from 'rxjs';
import {UIFactoryService, UIType} from '../../services/ui-factory.service';
import {AppIcon} from '../../models/app-icon';
import {UIManagerService} from '../../services/ui-manager.service';
import {RedeemOverviewComponent} from '../../../../../src/app/components/pages/redeem-overview/redeem-overview.component';
import {RedemptionService} from '../../services/redemption.service';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {RedeemShowOrderComponent} from '../../../../../src/app/components/pages/redeem-overview/redeem-show-order/redeem-show-order.component';

@Component({
  selector: 'app-event-management-ticket-scan',
  templateUrl: './inc-redemption-bottom-modal.html',
  styleUrls: ['./inc-redemption-bottom-modal.scss'],
})
export class IncRedemptionBottomModalComponent implements OnInit, OnDestroy {

  @Input() success: boolean;
  @Input() showRundown = true;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() voucherCode: string;
  @Input() orderCode: string;
  @Input() additionalInformation?: VoucherStatus;

  private subscriptions: Subscription[] = [];

  constructor(
    private cb: Clipboard,
    private settingsService: SettingsService,
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private redemptionService: RedemptionService,
  ) {
  }

  ngOnInit() {
  }

  public async scanAgain() {
    await this.uiFactory.dismissModal({repeatScan: true});
  }

  public async close() {
    await this.uiFactory.dismissModal();
  }

  public canSeeCustomerName() {
    return this.additionalInformation && this.additionalInformation.Customer?.Firstname && this.additionalInformation.Customer?.Lastname;
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public async copyToClipboard() {
    await this.cb.copy(this.voucherCode);

    this.uiFactory.build(UIType.TOAST, {
      message: 'In Zwischenablage kopiert',
      icon: AppIcon.copy,
      duration: 1500,
      mode: 'ios'
    }).then(t => t.present());
  }

  public async showVoucherDetails() {
    const loader = await this.uiManager.showLoader('Lade...');

    try {
      await this.redemptionService.fetchVoucher(this.voucherCode);
    } catch (e) {
      this.uiManager.showToast('Es existiert kein Gutschein mit diesem Code', 'warning', {duration: 2000}).then(t => t.present());
      return;
    } finally {
      await loader.dismiss();
    }

    this.uiFactory.build(UIType.MODAL, {
      component: RedeemOverviewComponent,
      showBackdrop: true,
      canDismiss: true,
      mode: 'ios',
      breakpoints: [0.66, 0.75, 1],
      initialBreakpoint: 0.75,
      componentProps: {
        state: 'data',
        readonly: true,
        closeAfterAction: true,
      }
    }).then(t => t.present());
  }

  public async showOrder() {
    const loader = await this.uiManager.showLoader('Lade...');
    let orders = null;
    try {
      orders = await this.redemptionService.fetchArticlesWithOrderNumber(this.orderCode);
    } catch (e) {
      this.uiManager.showToast('Die Bestelldaten für dieses Produkt können nicht angezeigt werden.', 'warning').then(t => t.present());
      return;
    } finally {
      await loader.dismiss();
    }

    if (!orders) {
      await this.uiManager.showToast('Der Gutschein ist der Bestellung mit der Nummer: ' + this.orderCode + ' zugeordnet, diese existiert jedoch nicht. Bei weiteren Fragen wende dich bitte an den Shopbetreiber', 'warning');
    }

    const modal = await this.uiFactory.build(UIType.MODAL, {
      component: RedeemShowOrderComponent,
      componentProps: {
        orders,
        orderId: this.orderCode,
        highlightCode: this.voucherCode
      },
      breakpoints: [1],
      initialBreakpoint: 1,
      canDismiss: true,
      showBackdrop: true,
      backdropDismiss: false,
    });

    await modal.present();
  }
}
