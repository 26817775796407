import {Component, Input, OnInit} from '@angular/core';

export type ModalType = 'success' | 'error';

@Component({
  selector: 'app-inc-modal',
  templateUrl: './inc-modal.component.html',
  styleUrls: ['./inc-modal.component.scss'],
})
export class IncModalComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() content: string;
  @Input() message: string;
  @Input() error: string;
  @Input() type: ModalType;

  constructor() { }

  ngOnInit() {}

}
