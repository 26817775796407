import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {ActionSheetController} from '@ionic/angular';
import {AuthenticationService} from '../../authentication/authentication.service';
import {SystemService} from '../../services/system.service';
import {SystemCredential} from '../../authentication/authentication';
import {TranslateService} from '@ngx-translate/core';
import {UIFactoryService, UIType} from '../../services/ui-factory.service';

@Component({
  selector: 'app-inc-system-switch',
  templateUrl: './inc-system-switch.component.html',
  styleUrls: ['./inc-system-switch.component.scss'],
})
export class IncSystemSwitchComponent implements OnInit, OnDestroy {

  @Input() currentSystem: string;
  @Output() onSuccessSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onCancelSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private storageService: StorageService,
    private actionSheetController: ActionSheetController,
    private systemService: SystemService,
    private authenticationService: AuthenticationService,
    private uiFactory: UIFactoryService,
    private t: TranslateService
  ) {
  }

  async ngOnInit() {
  }

  public async onShowSystemSwitchMenu() {
    const buttons = await this.createSystemButtons();

    setTimeout(async () => {
      const actionSheet = await this.actionSheetController.create({
        header: await this.t.get('common.availableSystems').toPromise(),
        cssClass: 'inc-action-sheet',
        buttons
      });

      await actionSheet.present();
    }, 250);
  }

  private async createSystemButtons() {
    const buttons = [];

    await this.systemService.getSystems().then(async systems => {
      for (const system of systems) {
        if (system.host !== this.currentSystem) {
          buttons.push({
            text: system.storeName,
            handler: () => this.onSystemSwitch(system)
          });
        }
      }

      buttons.push(({
        text: await this.t.get('common.cancelBtn').toPromise(),
        role: 'cancel',
        handler: () => this.onCancel()
      }));
    });

    return buttons;
  }

  private async onSystemSwitch(system: SystemCredential) {
    const loader = await this.uiFactory.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});
    await loader.present();
    this.authenticationService.switchSystemTo(system).then(() => {
      setTimeout(() => {
        this.onSuccessSwitch.emit(true);
      }, 500);
    });
    await loader.dismiss();
  }


  private onCancel() {
    this.onCancelSwitch.emit(true);
  }

  ngOnDestroy(): void {
  }
}
