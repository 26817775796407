import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {VoucherStatus} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {AuthenticationService} from '../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-redeem-history',
  templateUrl: './redeem-history.component.html',
  styleUrls: ['./redeem-history.component.scss'],
})
export class RedeemHistoryComponent implements OnInit, OnDestroy {
  @Input() config: RedemptionConfig;
  public voucher: VoucherStatus;
  public selected = 'chevron-down-outline';
  public unselected = 'chevron-up-outline';
  public state: number;

  private subscriptions: Subscription[] = [];


  constructor(
    public authenticationService: AuthenticationService,
    private redeemService: RedemptionService) {
    this.subscriptions.push(this.redeemService.voucher$.subscribe(v => {
      if (v) {
        this.voucher = v;
      }
    }));
  }

  ngOnInit() {
  }

  showState(i: number) {
    this.state = this.state === i ? 0 : i;
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
