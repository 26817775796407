import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {StatusSortedOrders} from '../../../../../../shared-libs/lib-core/src/models/order.status';
import {IonRouterOutlet, ModalController, Platform} from '@ionic/angular';
import {getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {TranslateService} from '@ngx-translate/core';
import {RedeemShowOrderComponent} from '../../redeem-overview/redeem-show-order/redeem-show-order.component';
import {RedeemOverviewComponent} from '../../redeem-overview/redeem-overview.component';
import {ReservationService} from '../../../../../../shared-libs/lib-core/src/services/reservation.service';
import {ReservationUiHelperService} from '../../../../../../shared-libs/lib-core/src/modules/reservation/reservation.ui-helper.service.ts.service';
import {SearchMode} from '../../../../../../shared-libs/lib-core/src/models/common';

@Component({
  selector: 'app-code-search',
  templateUrl: 'main-search-code-modal.component.html',
  styleUrls: ['main-search-code-modal.component.scss'],
})
export class MainSearchCodeModalComponent implements OnInit {
  @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;
  public searchMode: SearchMode = SearchMode.CODE;
  public config: RedemptionConfig;
  public search = '';

  constructor(
    private uiManagerService: UIManagerService,
    private uiFactory: UIFactoryService,
    private reservationUIHelper: ReservationUiHelperService,
    private codeRedeemService: RedemptionService,
    private reservationService: ReservationService,
    private modalController: ModalController,
    private platform: Platform,
    private httpCachedClient: HttpCachedClient,
    private translateService: TranslateService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.config = await this.httpCachedClient.getRaw<RedemptionConfig>('/api/redeemapp/config');
  }

  public async searchCode(code: string) {
    code = code.replace(/\s/g, '');
    await Haptics.impact({style: ImpactStyle.Medium});
    const loader = await this.uiManagerService.showLoader(await this.translateService.get('common.loading').toPromise());

    if (!code) {
      await this.uiManagerService.showInvalidBarcodeAlert();
      await loader.dismiss();
    } else {
      await Haptics.impact({style: ImpactStyle.Medium});

      switch (this.searchMode) {
        case SearchMode.ORDER:
          await this.handleOrderRequest(loader, code);
          break;
        case SearchMode.CODE:
          await this.handleCodeRequest(loader, code);
          break;
        case SearchMode.RESERVATION:
          await this.handleReservationRequest(loader, code);
          break;
      }
    }
  }

  private async handleCodeRequest(loader: HTMLIonLoadingElement, code: string) {
    try {
      code = code.replace(/\s/g, '');
      await this.codeRedeemService.fetchVoucher(code);
      await loader.dismiss();

      this.uiFactory.build(UIType.MODAL, {
        component: RedeemOverviewComponent,
        canDismiss: true,
        showBackdrop: true,
        backdropDismiss: true,
        presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
        cssClass: 'inc-modal',
        componentProps: {
          config: this.config
        }
      }).then(async m => {
        await m.present();
        await loader.dismiss();
      });
    } catch (e) {
      await this.uiManagerService.showVoucherNotFoundError();
    } finally {
      await loader.dismiss();
    }
  }

  private async handleOrderRequest(loader: HTMLIonLoadingElement, code: string) {
    try {
      code = code.replace(/\s/g, '');
      const orders = await this.codeRedeemService.fetchArticlesWithOrderNumber(code);
      await loader.dismiss();
      await this.showOrderDetailModal(RedeemShowOrderComponent, orders, code);
    } catch (e) {
        await this.uiManagerService.showNoOrderFound(code);
    } finally {
      await loader.dismiss();
    }
  }

  private async handleReservationRequest(loader: HTMLIonLoadingElement, code: string) {
    try {
      console.log('reservation code', code);
      await loader.dismiss();
      await this.reservationUIHelper.showParticipansForId(code);
    } catch (e) {
      await this.uiManagerService.showNoOrderFound(code);
    } finally {
      await loader.dismiss();
    }
  }

  private async showOrderDetailModal(orderComponent: Type<any>, orders: StatusSortedOrders, orderId: string) {
    const modal = await this.uiFactory.build(UIType.MODAL, {
      component: orderComponent,
      componentProps: {
        orders,
        orderId
      },
      presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
      canDismiss: true,
      showBackdrop: true,
      backdropDismiss: false,
    });

    await modal.present();
  }

  public changeToSearchMode(searchMode: any) {
    this.search = '';
    this.searchMode = searchMode.detail.value;
  }
}
