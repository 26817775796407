import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageKey, StorageService} from './storage.service';
import {SystemCredential} from '../authentication/authentication';


@Injectable({
  providedIn: 'root'
})
export class SystemService {

  #_currentSystem$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  #_isMultiSystemUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public constructor(
    private storageService: StorageService
  ) {
  }

  get isMultiSystemUser$(): BehaviorSubject<boolean> {
    return this.#_isMultiSystemUser$;
  }

  public async addSystem(system: SystemCredential): Promise<Array<SystemCredential>> {
    let systems: Array<SystemCredential> = await this.storageService.get(StorageKey.SYSTEMS) ?? [];

    systems = [...systems.filter(s => s.host !== system.host), system];

    await this.updateSystems(systems);

    return systems;
  }

  private async updateSystems(systems: SystemCredential[]) {
    await this.storageService.set(StorageKey.SYSTEMS, systems);

    this.isMultiSystemUser$.next(systems.length > 1);
  }

  public get currentSystem$() {
    return this.#_currentSystem$;
  }

  public async getSystems(): Promise<Array<SystemCredential>> {
    return await this.storageService.get<Array<SystemCredential>>(StorageKey.SYSTEMS) ?? [];
  }

  public async removeSystem(system: SystemCredential) {
    let systems = await this.storageService.get<Array<SystemCredential>>(StorageKey.SYSTEMS) ?? [];

    if (!systems.length) {
      throw new Error('No systems to delete from');
    } else {
      if (!systems.find(s => s.host === system.host)) {
        throw new Error('This system does not exist');
      } else {
        systems = systems.filter(storedSystems => storedSystems.host !== system.host);
        await this.updateSystems(systems);

        return true;
      }
    }
  }
}
