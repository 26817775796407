import {Component, OnDestroy, OnInit} from '@angular/core';
import {UIManagerService} from '../../services/ui-manager.service';
import {UIFactoryService, UIType} from '../../services/ui-factory.service';
import {ReservationService} from '../../services/reservation.service';
import {getPlatform} from '../../utilities/helper/utils';
import {IonRouterOutlet, Platform} from '@ionic/angular';
import {ReservationSequence, ReservationSequenceDate, ReservationSequenceI} from '../../models/reservation.model';
import {ReservationDateComponent} from './reservation-date/reservation-date.component';
import {ReservationUiHelperService} from './reservation.ui-helper.service.ts.service';
import {SettingsService} from '../../services/settings.service';
import {DefinedSettings} from '../../models/common';
import {Subscription} from 'rxjs';
import {ConnectionService} from '../../services/connection.service';
import {HttpCachedClient} from '../../services/http-cached-client.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit, OnDestroy {

  private _sequences: Array<ReservationSequenceI> = [];
  public sequences: Array<ReservationSequenceI> = [];
  private settings: DefinedSettings;
  public search: string;
  public hasEvents = false;
  public isLoading = true;
  private settingsSubscription: Subscription;

  constructor(
    private uiManagerService: UIManagerService,
    private uiFactory: UIFactoryService,
    private reservationService: ReservationService,
    private reservationUIHelperService: ReservationUiHelperService,
    private platform: Platform,
    private routerOutlet: IonRouterOutlet,
    private settingsService: SettingsService,
    public connectionService: ConnectionService,
    private httpCachedClient: HttpCachedClient) {
    this.settingsSubscription = this.settingsService.settings$.subscribe(settings => this.settings = settings);
  }

  async ngOnInit() {
    await this.initialize();
  }

  ngOnDestroy() {
    this.settingsSubscription.unsubscribe();
  }

  private async initialize() {
    try {
      this.isLoading = true;
      this.sequences = this._sequences = await this.reservationService.findEventsWithReservations();
      this.hasEvents = !!this.sequences.length;
    } catch (e) {
      this.hasEvents = false;
    } finally {
      this.isLoading = false;
    }
  }

  public async navigateToDate(id: string) {
    await this.uiFactory.build(UIType.MODAL, {
      component: ReservationDateComponent,
      showBackdrop: true,
      mode: 'ios',
      canDismiss: true,
      presentingElement: getPlatform(this.platform) ? this.routerOutlet.nativeEl : null,
      componentProps: {
        eventDateId: id,
        settings: this.settings
      }
    });
  }

  public searchSequence() {
    const searchableKeys: Array<keyof ReservationSequence | string> = ['name'];
    if (this.search === '') {
      this.sequences = this._sequences;
    } else {
      this.sequences = this._sequences.filter(r => searchableKeys.some(k => r[k].toLowerCase().indexOf(this.search.toLowerCase()) !== -1));
    }
  }

  public hasSequences() {
    return this._sequences.length;
  }

  public async onRefresh(event: any) {
    await this.initialize();
    setTimeout(() => {
      event.target.complete();
    }, 500);
  }

  public filterAvailableDates(dates: Array<ReservationSequenceDate>) {
    if (this.settings.displayRelevantTickets){
      dates = dates?.filter(d => d.numberOfParticipants > 0);
    }
    return dates.filter(d => Date.parse(d.start) >= Date.now());
  }
}
