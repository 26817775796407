import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {VoucherStatus} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {SystemService} from '../../../../../../shared-libs/lib-core/src/services/system.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {calculateVoucherAmount} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';

@Component({
  selector: 'app-redeem-data',
  templateUrl: './redeem-data.component.html',
  styleUrls: ['./redeem-data.component.scss'],
})
export class RedeemDataComponent implements OnDestroy {

  public voucher: VoucherStatus;
  public system: string;

  @Input() config: RedemptionConfig;

  public subscriptions: Array<Subscription> = [];
  public displayAmount: number;

  constructor(
    public authService: AuthenticationService,
    private redemptionService: RedemptionService,
    private systemService: SystemService) {

    this.subscriptions.push(this.redemptionService.voucher$.subscribe(v => {
      if (v) {
        this.voucher = v;
      }
    }));
    this.subscriptions.push(this.systemService.currentSystem$.subscribe(s => this.system = s));
    this.displayAmount = calculateVoucherAmount(this.voucher);
  }

  public voucherHasExpired(voucher: VoucherStatus): boolean {
    const oldDate = voucher.ValidThru.split('.');
    const validUntil = new Date(+oldDate[2], +oldDate[1] - 1, +oldDate[0], 0, 0, 0, 0).getTime();

    return validUntil < new Date().getTime();
  }

  public getDisplayData(ownerData): string {
    let displayData = '';
    if (ownerData.value.length > 15) {
      displayData = ownerData.value.substr(0, 15) + '... ';
     } else {
      displayData = ownerData.value + ' ';
      }
    return displayData;
  }

  public ngOnDestroy(): void {
    for (const subscr of this.subscriptions) {
      subscr.unsubscribe();
    }
  }
}
