import {Component, Input, OnInit} from '@angular/core';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {EventDate} from '../../event-management/event-management.model';
import {EventManagementTicketsComponent} from '../../event-management/event-management-tickets/event-management-tickets.component';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {IonRouterOutlet, Platform} from '@ionic/angular';
import {getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {Router} from '@angular/router';
import {SwiperOptions, Pagination} from 'swiper';
import SwiperCore from 'swiper/core';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-upcoming-events',
  templateUrl: './main-upcoming-events.component.html',
  styleUrls: ['./main-upcoming-events.component.scss'],
})
export class MainUpcomingEventsComponent implements OnInit {

  @Input() isLoadingEvents;
  @Input() upcomingEvents: Array<EventDate>;
  @Input() config: RedemptionConfig;
  public swiperOptions: SwiperOptions;

  constructor(
    public connectionService: ConnectionService,
    private routerOutlet: IonRouterOutlet,
    private uiFactory: UIFactoryService,
    private router: Router,
    private platform: Platform
  ) {
  }

  ngOnInit() {
    this.swiperOptions = {
      slidesPerView: 1.3,
      spaceBetween: 15,
      pagination: { clickable: true }
    };
  }

  public async navigateToEventDate(eventDate: EventDate) {
    this.uiFactory.build(UIType.MODAL, {
      component: EventManagementTicketsComponent,
      mode: 'ios',
      canDismiss: true,
      showBackdrop: true,
      presentingElement: getPlatform(this.platform) ? this.routerOutlet.nativeEl : null,
      componentProps: {
        eventDateId: eventDate.id,
        eventId: eventDate.eventSequenceId,
        eventTitle: eventDate.name,
      }
    }).then(t => t.present());
  }

  public showAllEvents() {
    this.router.navigate(['events']);
  }
}
