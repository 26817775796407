import {Component, Input, OnInit} from '@angular/core';
import {InformationConfig} from '../inc-info-button.component';
import {IonContent} from "@ionic/angular";

@Component({
  selector: 'app-inc-popover-content',
  templateUrl: './inc-popover-content.component.html',
  styleUrls: ['./inc-popover-content.component.scss'],
})
export class IncPopoverContentComponent implements OnInit {

  @Input() infoConfig: Array<InformationConfig>;

  constructor() {
  }

  ngOnInit() {
  }

  public async scrollToTop(ionContent: IonContent) {
    await ionContent.scrollToTop(1000);
  }
}
