import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from '@ngx-translate/core';
import {SettingsService} from '../../shared-libs/lib-core/src/services/settings.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormBuilder} from '@angular/forms';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {AuthenticationInterceptorService} from '../../shared-libs/lib-core/src/interceptors/authentication-interceptor.service';
import {SharedModule} from '../../shared-libs/lib-core/src/shared.module';
import {EventManagementModule} from './components/pages/event-management/event-management.module';
import {LoginComponent} from './components/pages/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { Clipboard } from '@ionic-native/clipboard/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,

  ],
  entryComponents: [],
  imports: [
    SharedModule,
    BrowserModule,
    RouterModule,
    IonicModule.forRoot(
      {
        swipeBackEnabled: false,
        // hardwareBackButton: true,
      }
    ),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    EventManagementModule,
  ],
  providers: [
    BarcodeScanner,
    SettingsService,
    TranslateService,
    TranslateStore,
    FormBuilder,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptorService, multi: true
    },
    Clipboard,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
