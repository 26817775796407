import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NavController} from '@ionic/angular';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {SystemService} from '../../../../../shared-libs/lib-core/src/services/system.service';
import {ConnectionService} from '../../../../../shared-libs/lib-core/src/services/connection.service';
import {UIFactoryService, UIType} from '../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {
  AuthenticationMeta,
  AuthenticationService
} from '../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {SettingsService} from '../../../../../shared-libs/lib-core/src/services/settings.service';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';
import {environment} from '../../../../environments/environment';
import {SystemCredential} from '../../../../../shared-libs/lib-core/src/authentication/authentication';
import {UIManagerService} from '../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {switchToStatusMode} from '../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-page-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  public loginFormGroup: FormGroup;
  public environment = environment;
  public hide = true;

  private subscriptions: Subscription[] = [];

  public constructor(private navController: NavController,
                     private fb: FormBuilder,
                     private authentication: AuthenticationService,
                     private translateService: TranslateService,
                     private storageService: StorageService,
                     private connectivityService: ConnectionService,
                     private systemService: SystemService,
                     private uiFactoryService: UIFactoryService,
                     private uiManager: UIManagerService,
                     private settingsService: SettingsService,
                     private router: Router) {
    this.loginFormGroup = this.fb.group(
      {
        host: ['', Validators.required],
        terminalID: ['', Validators.required],
        terminalPassword: ['', Validators.required]
      }
    );
  }

  async ngOnInit(): Promise<void> {
    await switchToStatusMode('Light');
    this.subscriptions.push(this.authentication.authenticatedUser.pipe(take(2)).subscribe(async auth => {
      if (auth) {
        const meta: AuthenticationMeta | null = await this.storageService.get<AuthenticationMeta>(StorageKey.TOKENCRED);

        if (meta) {
          meta.system = auth.host;
          await this.storageService.set(StorageKey.TOKENCRED, meta);
        }

        this.systemService.currentSystem$.next(auth.host);

        setTimeout(() => {
          this.router.navigate(['main']);
        }, 100);
      }
    }));
  }

  public async authenticate() {
    const loginResponse = await this.login();

    if (loginResponse) {
      this.loginFormGroup.reset({host: '', terminalID: '', terminalPassword: ''});
      await this.router.navigate(['main']);
    }
  }

  private async fetchAccountSystemInfo(host: string): Promise<Pick<SystemCredential, 'storeName' | 'isPortal'>> {
    try {
      const systemInformation: SystemCredential = await this.authentication.getAccountSystemInfo(host);

      if (!systemInformation.storeName) {
        systemInformation.storeName = host;
      }

      return systemInformation;
    } catch (e) {
      return {
        storeName: await this.translateService.get('accountSystem.notConfigured').toPromise(),
        isPortal: false
      };
    }
  }

  public async login(): Promise<boolean> {
    if (!this.loginFormGroup.valid) {
      await this.showInvalidFormAlert().then(alert => alert.present());

      return false;
    }

    const loader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());

    await loader.present();

    this.loginFormGroup.get('host').setValue(this.loginFormGroup.get('host').value.trim());

    const form = this.loginFormGroup.getRawValue();
    form.host = form.host.trim();
    form.terminalID = form.terminalID.trim();

    try {
      const result = await this.authentication.authenticate(form);

      if (result) {
        const systemInformation: Partial<SystemCredential> = await this.fetchAccountSystemInfo(this.loginFormGroup.get('host')?.value);

        await this.systemService.addSystem({
          ...this.loginFormGroup.getRawValue(),
          storeName: systemInformation.storeName
        });

        this.authentication.authenticatedUser.next({
          ...this.loginFormGroup.getRawValue(),
          storeName: systemInformation.storeName
        });
        return true;
      } else {
        if (this.connectivityService.isOffline$.getValue()){
          await this.showConnectionErrorAlert().then(alert => alert.present());
        } else {
          await this.showWrongCredentialsAlert().then(alert => alert.present());
        }

        return false;
      }
    } catch (error) {
      if (error.status === 401) {
        await this.showWrongCredentialsAlert();
      } else {
        await this.showConnectionErrorAlert();

        return false;
      }
    } finally {
      await loader.dismiss();
    }

    return false;
  }

  public urlFocusChanged(): void {
    const hostInput = this.loginFormGroup.get('host');

    if (!hostInput.value.toString().includes('https://')) {
      this.loginFormGroup.get('host')?.patchValue('https://');
    }
  }

  private async showInvalidFormAlert() {
    return await this.uiFactoryService.build(UIType.ALERT,
      {
        header: await this.translateService.get('common.invalidForm.title').toPromise(),
        subHeader: await this.translateService.get('common.invalidForm.subTitle').toPromise(),
        buttons: [
          await this.translateService.get('common.invalidForm.buttonText').toPromise(),
        ]
      }
    );
  }

  private async showWrongCredentialsAlert() {
    return await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('login.loginAction.wrongData.title').toPromise(),
      subHeader: await this.translateService.get('login.loginAction.wrongData.subTitle').toPromise(),
      buttons: [await this.translateService.get('login.loginAction.wrongData.buttonText').toPromise()]
    });
  }

  private async showConnectionErrorAlert() {
    return this.uiFactoryService.build(UIType.ALERT, {
        header: await this.translateService.get('login.loginAction.connectionError.title').toPromise(),
        subHeader: await this.translateService.get('login.loginAction.connectionError.subTitle').toPromise(),
        buttons: [await this.translateService.get('login.loginAction.connectionError.buttonText').toPromise()]
      }
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}

