import {Event, EventDate, EventSequence, FavouriteEvent} from '../event-management.model';
import {TranslateService} from '@ngx-translate/core';
import {EventManagementService} from '../event-management.service';
import {ActionSheetController, IonItemSliding, IonRouterOutlet, Platform} from '@ionic/angular';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../../../../shared-libs/lib-core/src/services/storage.service';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SystemService} from '../../../../../../shared-libs/lib-core/src/services/system.service';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {EventManagementTicketsComponent} from '../event-management-tickets/event-management-tickets.component';
import {getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {Route} from '../../../../../../shared-libs/lib-core/src/models/page.interface';
import {SettingsService} from '../../../../../../shared-libs/lib-core/src/services/settings.service';
import {DefinedSettings} from '../../../../../../shared-libs/lib-core/src/models/common';
import {EventManagementDatesComponent} from '../event-management-dates/event-management-dates.component';
import {CacheService} from '../../../../../../shared-libs/lib-core/src/services/cache.service';

@Component({
  selector: 'app-event-management-list',
  templateUrl: './event-management-list.component.html',
  styleUrls: ['./event-management-list.component.scss'],
})
export class EventManagementListComponent implements OnInit, OnDestroy {
  @Output() eventChoosen: EventEmitter<any> = new EventEmitter<any>();

  private _events: Array<EventSequence> = [];
  public events: Array<EventSequence> = [];

  public _favouriteEvents: Array<FavouriteEvent> = [];
  public favouriteEvents: Array<FavouriteEvent> = [];

  public subscriptions: Array<Subscription> = [];

  private settings: DefinedSettings;
  public currentSystem: string;
  public isFetching = true;
  public isEmptySearchResult = false;
  public search = '';
  public searchString: any;
  public ticketAccess: boolean;
  public initialized: boolean;

  private static hasAccessToTickets(config: RedemptionConfig) {
    return config?.ViewTickets;
  }

  constructor(
    public t: TranslateService,
    private barcodeScanner: BarcodeScanner,
    private httpCachedClient: HttpCachedClient,
    private router: Router,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private uiFactoryService: UIFactoryService,
    private storageService: StorageService,
    private actionSheetController: ActionSheetController,
    private authenticationService: AuthenticationService,
    private eventManagerService: EventManagementService,
    public connectionService: ConnectionService,
    private platform: Platform,
    private routerOutlet: IonRouterOutlet,
    private settingsService: SettingsService,
    private cacheService: CacheService,
  ) {
    this.subscriptions = [
      this.systemService.currentSystem$.subscribe(s => this.currentSystem = s),
      this.eventManagerService.favoritesUpdated$.subscribe(async favorites => {
        if (favorites?.length) {
          await this.fetchFavouritesFromStorage();
          await this.sortFavourites();
        }
      }),
      this.settingsService.settings$.subscribe(settings => {
        this.settings = settings;
      })
    ];
  }

  async ngOnInit() {
    this.fetchConfig().then(async (config: RedemptionConfig) => {

      if (EventManagementListComponent.hasAccessToTickets(config)) {
        this.ticketAccess = true;
        await this.initialize(true);
      } else {
        this.isFetching = false;
      }
    });
  }


  private async initialize(checkCache: boolean) {
    await this.fetchEvents(checkCache);
    await this.fetchFavouritesFromStorage();

    this.sortFavourites();
    this.isFetching = false;
    this.initialized = true;
  }

  public async notifyUserIsOffline() {
    this.uiFactoryService.build(UIType.TOAST, {
      message: 'Keine Internetverbindung',
      duration: 1500
    }).then(t => t.present());
  }

  public async fetchConfig(): Promise<RedemptionConfig | null> {
    try {
      if (this.connectionService.isOffline$.getValue()){
        return await this.cacheService.findOfflineVoucher('/config');
      } else {
        return await this.httpCachedClient.getRaw('/api/redeemapp/config');
      }
    } catch (e) {
      return null;
    }
  }

  public async fetchEvents(checkCache: boolean) {
    try {
      if (!this.connectionService.isOffline$.getValue()) {
        this._events = this.events = await this.httpCachedClient
          .get<Array<EventSequence>>('/api/redeemapp/eventSequence' + (this.settings.displayRelevantTickets ? '' : '?displayAll=true'), checkCache, true);
        if (!this._events) {
          await this.uiFactoryService.build(UIType.TOAST, {
            message: await this.t.get('tickets.errorCouldNotLoadEvents').toPromise(),
            duration: 1500,
          }).then(t => t.present());
        }
      } else {
        this._events = this.events = await this.cacheService.findOfflineVoucher('/events');
      }
    } catch (e) {
      await this.showNoEventsForSystemAlert().then(() => this.router.navigate(['/' + Route.MAIN]));
    }
  }

  public searchEventLists() {
    this.favouriteEvents = this._favouriteEvents.filter(e => e.event.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);
    this.events = [...this._events.filter(e => e.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)];
    if (this.searchString === '') {
      this.events = this._events;
      this.favouriteEvents = this._favouriteEvents;
    }

    this.isEmptySearchResult = this.events.length === 0 && this.searchString !== '';
  }

  private sortFavourites() {
    this.favouriteEvents = this._favouriteEvents.sort((f1: FavouriteEvent, f2: FavouriteEvent) => {
      return f1.dateId && f2.dateId ? new Date(f1.dateId).getTime() > new Date(f2.dateId).getTime() ? -1 : 1 : 1;
    });
  }

  public async markAsFavourite(slidingItem: IonItemSliding, event) {
    await slidingItem.close();

    const res = await this.eventManagerService.addToFavourites(this.currentSystem, {
      system: this.currentSystem,
      eventId: event.id
    });

    if (res) {
      const foundEvent = this._events.find(e => e.id === event.id);

      if (foundEvent) {
        this.favouriteEvents = this._favouriteEvents = [...this._favouriteEvents, {
          event: foundEvent
        }];
      }
    }

    this.sortFavourites();

    this.uiFactoryService.build(UIType.TOAST, {
      message: res ?
        await this.t.get('tickets.addToFavouritesAlert').toPromise() :
        await this.t.get('tickets.alreadyInFavourites').toPromise(),
      duration: 1500,
    }).then(t => t.present());
  }

  public async removeFromFavourites(slidingItem: IonItemSliding, event: Event, dateId: number) {
    setTimeout(() => {
      slidingItem.close();
    }, 250);

    const res = this.eventManagerService.removeFromFavourites({
      system: this.currentSystem,
      eventId: event.id,
      dateId
    });

    if (res) {
      this._favouriteEvents.splice(this._favouriteEvents.findIndex(e => {
        return e.event.id === event.id && e.dateId === dateId;
      }), 1);

      this.uiFactoryService.build(UIType.TOAST, {
        message: await this.t.get('tickets.removedFromFavorites').toPromise(),
        duration: 1500
      }).then(t => t.present());
    } else {
      this.uiFactoryService.build(UIType.TOAST, {
        message: await this.t.get('tickets.errorWhileRemovingFavorites').toPromise(),
        duration: 1500
      }).then(t => t.present());
    }
  }

  private async fetchFavouritesFromStorage() {
    this.favouriteEvents = this._favouriteEvents = await this.eventManagerService.fetchFavouritesForSystem(this.currentSystem, this._events);
  }

  public async onRefresh(event) {
    if (!this.connectionService.isOffline$.getValue()) {
      try {
        this.isFetching = true;
        await this.initialize(false);
      } catch (e) {
      } finally {
        setTimeout(() => {
          event.target.complete();
        }, 500);
      }
    }
  }

  public async navigateToEventDate(event: FavouriteEvent) {
    if (event.dateId) {
      this.uiFactoryService.build(UIType.MODAL, {
        component: EventManagementTicketsComponent,
        mode: 'ios',
        canDismiss: true,
        showBackdrop: true,
        presentingElement: getPlatform(this.platform) ? this.routerOutlet.nativeEl : null,
        componentProps: {
          eventDateId: event.dateId,
          eventId: event.event.id
        }
      }).then(t => t.present());
    } else {
      await this.router.navigate([event.event.id, 'dates'], {relativeTo: this.route});
    }
  }

  public async navigateToEvent(event: EventSequence) {
    if (event.id) {
      this.uiFactoryService.build(UIType.MODAL, {
        component: EventManagementDatesComponent,
        mode: 'ios',
        canDismiss: true,
        showBackdrop: true,
        presentingElement: getPlatform(this.platform) ? this.routerOutlet.nativeEl : null,
        componentProps: {
          eventId: event.id
        }
      }).then(t => t.present());
    }else{
      await this.router.navigate(['/' + Route.EVENTS, event.id, 'dates'], {relativeTo: this.route});
    }
  }

  private async showNoEventsForSystemAlert() {
    this.uiFactoryService.build(UIType.ALERT, {
      header: await this.t.get('tickets.noEvents').toPromise(),
      message: await this.t.get('tickets.errorNoEventsAvailableForSys').toPromise(),
      buttons: [{text: 'OK'}]
    })
      .then(t => t.present());
  }

  public filterAvailableDates(dates: Array<EventDate>) {
    if (this.settings.displayRelevantTickets) {
      dates = dates.filter(d => d.sold > 0);
    }
    return dates.filter(d => Date.parse(d.end) >= Date.now());
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
