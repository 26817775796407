import { Pipe, PipeTransform } from '@angular/core';
import {extractSystemShort} from '../helper/utils';

@Pipe({
  name: 'systemUrl'
})
export class SystemUrlPipe implements PipeTransform {

  transform(value: string): unknown {
    return extractSystemShort(value);
  }

}
