import {Component, Input, OnInit} from '@angular/core';
import {ChartType} from 'chart.js';
import {Label, MultiDataSet} from 'ng2-charts';
import {SystemService} from '../../../../../../../shared-libs/lib-core/src/services/system.service';
import {UIFactoryService} from '../../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpCachedClient} from '../../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';

export interface Figures {
  total: number;
  unredeemed: number;
  redeemed: number;
  partial: number;
}

@Component({
  selector: 'app-event-utilization',
  templateUrl: './event-management-tickets-utilization.component.html',
  styleUrls: ['./event-management-tickets-utilization.component.scss'],
})
export class EventManagementTicketsUtilizationComponent implements OnInit {
  @Input() event: string;
  @Input() eventDateId: string;

  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: MultiDataSet;
  public doughnutChartType: ChartType = 'doughnut';

  public figures: Figures;
  public isLoading: boolean;

  constructor(
    private uiFactoryService: UIFactoryService,
    private httpCacheClient: HttpCachedClient,
    private systemService: SystemService,
    private t: TranslateService
  ) {
  }

  public doughnutChartColors: any[] = [
    {
      /**
       * TODO find a suitable way to acces scss vars here
       */
      backgroundColor: [
        '#d0d0d0',
        '#1f1f1f',
        '#39d130'
      ],
    }
  ];

  private async addLabels() {
    this.doughnutChartLabels.push(await this.t.get('tickets.utilizationPartial').toPromise());
    this.doughnutChartLabels.push(await this.t.get('tickets.utilizationFull').toPromise());
    this.doughnutChartLabels.push(await this.t.get('tickets.utilizationRemaining').toPromise());
  }

  async ngOnInit() {
    this.isLoading = true;
    this.figures = await this.httpCacheClient.getRaw<Figures>('/api/events/dates/' + this.eventDateId + '/utilization');

    this.doughnutChartData = [[this.figures.partial, this.figures.redeemed, (this.figures.total - this.figures.redeemed - this.figures.partial)]];
    this.doughnutChartData.forEach((ds: any) => ds.backgroundColor = ['black', 'green']);

    await this.addLabels();

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
}
