/**
 * CAS uses either a CA or CAPH0001 Prefix for their vouchers
 * default length is 33, cut of 10
 */

const prefixCAS = 'CAPH0001';

export const stripeCASVoucher = (voucherCode: string) => {
  if (voucherCode.slice(0, 8) === prefixCAS) {
    return voucherCode.slice(-10);
  } else {
    return voucherCode;
  }
};
