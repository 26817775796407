import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-inc-scan-bar',
  templateUrl: './inc-scan-bar.component.html',
  styleUrls: ['./inc-scan-bar.component.scss'],
})
export class IncScanBarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  openSettings() {
    this.router.navigate(['settings']);
  }
}
