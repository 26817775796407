import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {

  constructor() {
  }

  private static colorTest(systemInitiatedDark) {
    if (systemInitiatedDark.matches) {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
  }

  public onToggle(event) {
    const systemDark = window.matchMedia('(prefers-color-scheme: dark)');

    systemDark.addListener(DarkModeService.colorTest);

    if (event.detail.checked) {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
  }

  public setMode(mode: boolean) {
    document.body.setAttribute('color-theme', mode ? 'dark' : 'light');
  }
}
