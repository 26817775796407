import {RedemptionService} from '../../../../../shared-libs/lib-core/src/services/redemption.service';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {VoucherState, VoucherStatus} from '../../../../../shared-libs/lib-core/src/models/voucher.status';
import {Subscription} from 'rxjs';
import {VOUCHERCODE_NOT_ACTIVATED} from '../../../../../shared-libs/lib-core/src/models/status/voucher.status.constants';
import {AuthenticationService} from '../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {HttpCachedClient} from '../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {IonRouterOutlet} from '@ionic/angular';
import {AttributeValue} from '../../../../../shared-libs/lib-core/src/models/common';
import {RedemptionConfig} from '../../../../../shared-libs/lib-core/src/models/redemptionConfig';

@Component({
  selector: 'app-page-voucher-redeem-detail',
  templateUrl: 'redeem-overview.component.html',
  styleUrls: ['redeem-overview.component.scss']
})
export class RedeemOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

  @Input() public voucher: VoucherStatus;
  @Input() public closeAfterAction = false;
  @Input() public readonly = false;
  @Input() public attributeData: Array<AttributeValue> = [];
  @Input() public state: VoucherState = 'redeem';

  public config: RedemptionConfig;
  public redeemSubscription: Subscription;

  public constructor(
    public authenticationService: AuthenticationService,
    private cachedHTTPClient: HttpCachedClient,
    private redeemService: RedemptionService
  ) {
    this.redeemSubscription = this.redeemService.voucher$.subscribe(voucher => {
      if (voucher) {
        this.voucher = voucher;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.config = await this.cachedHTTPClient.getRaw('/api/redeemapp/config');

    if (this.voucher.Status === 8) {
      this.state = 'charge';
    }
  }

  public get isNotActivated(): boolean {
    if (this.voucher) {
      if (this.voucher.Status === VOUCHERCODE_NOT_ACTIVATED) {
        if (this.voucher.InitialAmount && this.voucher.InitialAmount._ > 0) {
          return true;
        }
      }
    }

    return false;
  }


  public hasViewRedemptionHistoryPermission() {
    return this.config?.ViewRedemptionHistory && this.authenticationService.showPrivacyData();
  }

  public showVoucherHistory() {
    return this.state === 'history' && this.hasViewRedemptionHistoryPermission();
  }

  public stateChanged(state) {
    this.state = state;
  }

  ngOnDestroy(): void {
    this.redeemSubscription.unsubscribe();
  }
}
