import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {EventManagementService} from '../event-management.service';
import {EventManagementSyncService} from '../event-management-sync.service';
import {ProcessedTickets, StoredSyncEntry, SynchronisationState, SynchronizationState} from '../event-management.model';
import {TranslateService} from '@ngx-translate/core';
import {StorageKey, StorageService} from '../../../../../../shared-libs/lib-core/src/services/storage.service';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';

@Component({
  selector: 'app-event-management-history',
  templateUrl: './event-management-queue.component.html',
  styleUrls: ['./event-management-queue.component.scss'],
})
export class EventManagementQueueComponent implements OnInit, OnDestroy {
  public successList: Array<ProcessedTickets> = [];
  public failedList: Array<ProcessedTickets> = [];
  public pendingList: Array<StoredSyncEntry> = [];

  public State = SynchronizationState;
  public state: SynchronisationState = 'pending';
  public isFetching = true;
  public isOffline: boolean;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private storageService: StorageService,
    private eventManagementService: EventManagementService,
    private syncService: EventManagementSyncService,
    public connectionService: ConnectionService,
    public t: TranslateService,
  ) {
    this.subscriptions = [
      this.connectionService.isOffline$.subscribe(s => this.isOffline = s),
      this.syncService.flushedTickets$.subscribe(flushed => this.updateLists(flushed))
    ];
  }

  async ngOnInit() {
    await this.fetchStorage();
    await this.fetchPendingRequests();
  }

  private updateLists(flushedTickets: Array<ProcessedTickets>) {
    if (flushedTickets) {
      flushedTickets.forEach(item => {
        if (item.success) {
          this.successList.unshift(item);
        } else {
          this.failedList.unshift(item);
        }
      });

      this.pendingList = [...this.pendingList.filter(pending => !flushedTickets.some(f => f.code === pending.voucherCode))];
      this.filterTickets();
    }
  }

  private async fetchStorage() {
    const storedHistory = await this.storageService.get<Array<ProcessedTickets>>(StorageKey.REDEMPTIONHISTORY) ?? [];
    storedHistory.forEach(ticket => {
      if (ticket) {
        if (ticket.success) {
          this.successList.unshift(ticket);
        } else if (!ticket.success) {
          this.failedList.unshift(ticket);
        }
      }
    });
    this.isFetching = false;
    this.filterTickets();
  }

  private async fetchPendingRequests() {
    this.pendingList = await this.syncService.getTicketsForCurrentSystem() ?? [];
  }

  private filterTickets() {
    const successCodes = [];
    this.successList = this.successList.map(ticket => {
      if (!successCodes.includes(ticket.code)) {
        successCodes.push(ticket.code);
        return ticket;
      }
    }).filter(ticket => ticket !== undefined);

    const failedCodes = [];
    this.failedList = this.failedList.map(ticket => {
      if (!failedCodes.includes(ticket.code)) {
        failedCodes.push(ticket.code);
        return ticket;
      }
    }).filter(ticket => ticket !== undefined);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
