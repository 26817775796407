import {Pipe, PipeTransform} from '@angular/core';
import {
  BUNDLE_ARTICLE,
  LOYALITY,
  ORDER,
  RESERVATION,
  TICKET,
  VALUE_VOUCHER
} from '../../models/status/product.constants';
import {TranslateService} from '@ngx-translate/core';

export interface ProductTypeMap {
  icon: string;
  name: string;
}


@Pipe({
  name: 'voucherTypeIcon'
})
export class VoucherTypeIconPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {
  }

  async transform(value: string, ...args: unknown[]): Promise<ProductTypeMap> {
    switch (value) {
      case TICKET: {
        return {
          icon: 'ticket-outline',
          name: await this.translateService.get('products.ticket').toPromise()
        };
      }
      case RESERVATION: {
        return {
          icon: 'calendar-outline',
          name: await this.translateService.get('products.reservation').toPromise()
        };
      }
      case LOYALITY: {
        return {
          icon: 'gift-outline',
          name: await this.translateService.get('products.loyality').toPromise()
        };
      }
      case ORDER: {
        return {
          icon: 'reader-outline',
          name: await this.translateService.get('products.order').toPromise()
        };
      }
      case VALUE_VOUCHER: {
        return {
          icon: 'wallet-outline',
          name: await this.translateService.get('products.valueVoucher').toPromise()
        };
      }
      case BUNDLE_ARTICLE: {
        return {
          icon: 'layers-outline',
          name: await this.translateService.get('products.bundleArticle').toPromise()
        };
      }
      default:
        return {
          icon: 'wallet-outline',
          name: await this.translateService.get('products.valueVoucher').toPromise()
        };
    }
  }
}
