import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CARD, LOYALITY, TICKET} from '../../models/status/product.constants';
import {VoucherStatus} from '../../models/voucher.status';
import {AuthenticationService} from '../../authentication/authentication.service';
import {calculateVoucherAmount} from '../../utilities/helper/utils';
import {RedemptionService} from '../../services/redemption.service';
import {Subscription} from 'rxjs';

export enum ProductType {
  VOUCHER = 'VOUCHER',
  TICKET = 'TICKET',
  CARD = 'CARD',
  LOYALITY = 'LOYALITY'
}

@Component({
  selector: 'inc-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.scss'],
})
export class IncProductDisplayComponent implements OnDestroy {

  product: VoucherStatus;
  ProductType = ProductType;
  mappedType: ProductType;
  displayAmount: number;
  voucherSubscription: Subscription;

  constructor(
    public authService: AuthenticationService,
    private redemptionService: RedemptionService
  ) {
    this.voucherSubscription = redemptionService.voucher$.subscribe(v => {
      if (v) {
        this.product = v;
        this.displayAmount = calculateVoucherAmount(v);
        this.mapType(v);
      }
    });
  }

  private mapType(product: VoucherStatus) {
    switch (product.ArticleType) {
      case TICKET: {
        this.mappedType = ProductType.TICKET;
        break;
      }
      case CARD: {
        this.mappedType = ProductType.CARD;
        break;
      }
      case LOYALITY: {
        this.mappedType = ProductType.LOYALITY;
        break;
      }
      default: {
        this.mappedType = ProductType.VOUCHER;
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.voucherSubscription.unsubscribe();
  }
}
