import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe checks if any property of the given parameter has
 * a specific value. This specific value is determineted by the first parameter.
 */

@Pipe({
  name: 'someproperty'
})
export class ObjectSomepropertiesPipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        if (!value) {
            return false;
        }

        const testValue = args[0];

        for (const key in value) {
            // if there is a value explicitly specified -
            // then use this
            if (testValue) {
                if (value[key] === testValue) {
                    return true;
                }
            } else {
                if (value[key]) {
                    return true;
                }
            }
        }
        return false;
    }
}
