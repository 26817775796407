import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class VersionInterceptorService implements HttpInterceptor {
  constructor(
    private appVersion: AppVersion
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(
      new Promise<string>(async (resolve) => {
        try {
          const v = await this.appVersion.getVersionNumber();
          resolve(v);
        } catch (e) {
          resolve('Desktop-Testing');
        }
      }))
      .pipe(
        switchMap(v => {
          const headers = req.headers
            .append('x-app-inc', v);
          const requestClone = req.clone({
            headers
          });
          return next.handle(requestClone);
        })
      );
  }
}
