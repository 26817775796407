import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonItemSliding, IonRouterOutlet} from '@ionic/angular';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {VoucherStatus} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {RedeemOverviewComponent} from '../redeem-overview.component';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {StatusSortedOrders} from '../../../../../../shared-libs/lib-core/src/models/order.status';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {TranslateService} from '@ngx-translate/core';
import {UnredemtionService} from '../../../../../../shared-libs/lib-core/src/services/unredemption.service';

@Component({
  selector: 'app-orders',
  templateUrl: './redeem-show-order.component.html',
  styleUrls: ['./redeem-show-order.component.scss'],
})
export class RedeemShowOrderComponent implements OnInit {

  @Input() orders: StatusSortedOrders;
  @Input() orderId: number;

  public config: RedemptionConfig;

  @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;
  public initialized: boolean;

  private currRedeeming = false;

  constructor(
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private httpCachedClient: HttpCachedClient,
    private redemptionService: RedemptionService,
    private translateService: TranslateService,
    private unredemptionService: UnredemtionService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.config = await this.httpCachedClient.getRaw('/api/redeemapp/config');

    this.initialized = true;
  }

  async openSlidingitem(ionItemSliding: IonItemSliding, side: 'start' | 'end') {
    await ionItemSliding.open(side);
  }

  public async refresh() {
    this.orders = await this.httpCachedClient.getRaw('/api/invoiceorders/' + this.orderId);
  }

  public showsHeader(redeemable, status?){
    for (const redeemableElement of redeemable) {
      if (status){
        if (redeemableElement.curVoucherStatusId === status){
          return true;
        }
      } else{
        if (redeemableElement.curVoucherStatusId !== 8){
          return true;
        }
      }
    }
    return false;
  }

  public redeemFullOrder() {
    this.uiFactory.build(UIType.ALERT, {
      header: 'Volleinlösung bestätigen',
      message: 'Bitte bestätige die Einlösung von: ' + this.orders?.unredeemed?.filter(value => value.curVoucherStatusId !== 8).length + ' Produkten.',
      buttons: [
        {text: 'Abbrechen', role: 'cancel'},
        {text: 'Bestätigen', handler:  () => {
              this.redeemAllProducts();
          }
        }
      ],
    }).then(t => t.present());
  }

  public async showDetailViewForProduct(slider: IonItemSliding, voucherCode: string) {
    const loader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());
    await slider.close();

    try {
      const voucher: VoucherStatus = await this.redemptionService.fetchVoucher(voucherCode);

      this.redemptionService.voucher$.next(voucher);

      await loader.dismiss();

      this.uiFactory.build(UIType.MODAL, {
        component: RedeemOverviewComponent,
        showBackdrop: true,
        canDismiss: true,
        mode: 'ios',
        breakpoints: [0.66, 0.75],
        initialBreakpoint: 0.66,
        componentProps: {
          state: 'data',
          readonly: true,
          closeAfterAction: true,
        }
      }).then(t => t.present());
    } catch (e) {
      await this.uiManager.unableToFetchVoucherCode();
    }
  }

  private async redeemAllProducts() {
      await Haptics.impact({style: ImpactStyle.Medium});
      try {
        this.orders.unredeemed.forEach(article => {
          if (article.curVoucherStatusId !== 8) {
            this.redemptionService.redeemFullWithCode(this.config, article.voucherCode);
          }
        });

        await this.refresh();

        this.orders.unredeemed.forEach(article => {
          if (article.curVoucherStatusId !== 8) {
            this.addToRedeemed(article.voucherCode);
          }
        });

        await this.uiManager.voucherRedeemedSuccessfully();
        this.currRedeeming = false;
      } catch (e) {
        this.uiManager.redemptionFailedWithErrorToast('Einlösung ist fehlgeschlagen', 'warning');
      }
  }

  public async redeemSingleProductPartly(voucherCode: string, title: string, amount?: string) {
    this.uiFactory.build(UIType.ALERT, {
      header: 'Teileinlösung: ' + title,
      message: amount ? 'Wert: ' + Number(amount).toFixed(2) + ' EUR' : null,
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel'
        },
        {
          text: 'Teileinlösen',
          handler: async (data) => {
            if (data.value) {
              const loader = await this.uiManager.showLoader('Wird eingelöst...');
              const voucherValue = this.orders.unredeemed.find(v => v.voucherCode === voucherCode).curVoucherValue;

              const response = await this.redemptionService.redeemPartlyWithCode(this.config, voucherCode, data.value, data.comment);

              if (+data.value === +voucherValue) {
                await this.addToRedeemed(voucherCode);
              } else {
                const voucher = await this.redemptionService.fetchVoucher(voucherCode);
                this.orders.unredeemed.find(v => v.voucherCode === voucher.Code).curVoucherValue = voucher.CurrentAmount;
              }

              await loader.dismiss();

              if (response.Error) {
                this.uiManager.redemptionFailedWithErrorToast(response.Error.ErrorDescription, 'warning');
              } else {
                await this.uiManager.voucherRedeemedSuccessfully();
              }
            } else {
              await this.uiManager.invalidRedemptionValue();
            }
          }
        }
      ],
      inputs: [
        {
          name: 'value',
          label: 'Betrag',
          placeholder: 'Bitte den Betrag eingeben',
          type: 'number'
        },
        {
          name: 'comment',
          label: 'Kommentar',
          placeholder: '(Optional): Kommentar',
          type: 'text'
        }
      ]
    }).then(t => t.present());
  }

  public async redeemSingleProductFull(voucherCode: string, title: string, amount?: string) {
    this.uiFactory.build(UIType.ALERT, {
      header: 'Volleinlösung: ' + title,
      message: amount ? 'Wert: ' + Number(amount).toFixed(2) + ' EUR' : null,
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel'
        },
        {
          text: 'Volleinlösen',
          handler: async (data) => {
            try {
              const loader = await this.uiManager.showLoader('Wird eingelöst...');
              const response = await this.redemptionService.redeemFullWithCode(this.config, voucherCode, data.comment);
              await loader.dismiss();

              if (!response.Error) {
                await this.uiManager.voucherRedeemedSuccessfully();
                await this.addToRedeemed(voucherCode);
              } else {
                await this.uiManager.redemptionFailedWithError(response.Error.ErrorDescription);
              }
            } catch (e) {
              await this.uiManager.redemptionFailedWithError(e);
            }
          }
        }
      ],
      inputs: [
        {
          name: 'comment',
          label: 'Kommentar',
          placeholder: '(Optional): Kommentar',
          type: 'text'
        }
      ]
    }).then(t => t.present());
  }

  public async unredeemSingleProduct(voucherCode: string) {
    if (await this.unredemptionService.unredeemProduct(voucherCode)){
      await this.addToUnredeemed(voucherCode);
    }
  }

  private async addToUnredeemed(voucherCode) {
    const voucher = await this.redemptionService.fetchVoucher(voucherCode);

    this.orders.unredeemed.push({
      ...this.orders.redeemed.find(s => s.voucherCode === voucherCode),
      curVoucherValue: voucher.CurrentAmount
    });
    this.orders.redeemed = this.orders.redeemed.filter(s => s.voucherCode !== voucherCode);
  }

  private async addToRedeemed(voucherCode) {
    const voucher = await this.redemptionService.fetchVoucher(voucherCode);

    this.orders.redeemed.push({
      ...this.orders.unredeemed.find(s => s.voucherCode === voucherCode),
      curVoucherValue: voucher.CurrentAmount
    });
    this.orders.unredeemed = this.orders.unredeemed.filter(s => s.voucherCode !== voucherCode);
  }
}

