import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {AuthenticationService} from '../../shared-libs/lib-core/src/authentication/authentication.service';
import {SettingsService} from '../../shared-libs/lib-core/src/services/settings.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DisplayMode, MenuAction, Page, Route} from '../../shared-libs/lib-core/src/models/page.interface';
import {Subscription} from 'rxjs';
import {ConnectionService} from '../../shared-libs/lib-core/src/services/connection.service';
import {UIFactoryService, UIType} from '../../shared-libs/lib-core/src/services/ui-factory.service';
import {StorageKey, StorageService} from '../../shared-libs/lib-core/src/services/storage.service';
import {SystemService} from '../../shared-libs/lib-core/src/services/system.service';
import {SystemCredential} from '../../shared-libs/lib-core/src/authentication/authentication';
import {EventManagementSyncService} from './components/pages/event-management/event-management-sync.service';
import {DarkModeService} from '../../shared-libs/lib-core/src/services/dark-mode.service';
import {AccountSystemComponent} from './components/pages/account-system/account-system.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {LoginComponent} from './components/pages/login/login.component';
import {SettingsComponent} from './components/pages/settings/settings.component';
import {MainPageComponent} from './components/pages/main/main-page.component';
import {ImprintComponent} from './components/pages/imprint/imprint.component';
import {CachedConfig, CacheEntry, CacheService} from '../../shared-libs/lib-core/src/services/cache.service';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';
import {Environment} from '../../shared-libs/lib-core/src/models/environment';
import {environment} from 'src/environments/environment';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {EventManagementListComponent} from './components/pages/event-management/event-management-list/event-management-list.component';
import {Event, ProcessedTickets, Ticket} from './components/pages/event-management/event-management.model';
import {HttpCachedClient} from '../../shared-libs/lib-core/src/services/http-cached-client.service';
import {switchToStatusMode} from '../../shared-libs/lib-core/src/utilities/helper/utils';
import {ReservationComponent} from '../../shared-libs/lib-core/src/modules/reservation/reservation.component';
import {DefinedSettings} from '../../shared-libs/lib-core/src/models/common';
import {UIManagerService} from '../../shared-libs/lib-core/src/services/ui-manager.service';

@Component({
  selector: 'app-incert-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public mainPages: Array<Page>;
  public subActions: Array<Page>;
  public authUser: SystemCredential | null;
  public isOffline: any;
  public environment: Environment = environment;
  private subscriptions: Subscription[] = [];
  private settings: DefinedSettings;

  public constructor(private synchronisationService: EventManagementSyncService,
                     public authenticationService: AuthenticationService,
                     public connectionService: ConnectionService,
                     private darkModeService: DarkModeService,
                     private settingsService: SettingsService,
                     private httpAccess: HttpCachedClient,
                     private uiFactory: UIFactoryService,
                     private uiManagerService: UIManagerService,
                     private translate: TranslateService,
                     private cacheService: CacheService,
                     private menu: MenuController,
                     private router: Router,
                     public platform: Platform,
                     public systemService: SystemService,
                     public storageService: StorageService
  ) {
    this.subscriptions.push(this.authenticationService.authenticatedUser.subscribe(async auth => {
      if (auth) {
        this.authUser = auth;
        await this.router.navigate([Route.MAIN]);
      } else {
        await this.router.navigate([Route.LOGIN]);
      }
    }));

    this.subscriptions.push(this.settingsService.settings$.subscribe(async settings => {
      if (settings) {
        this.settings = settings;
      }
    }));
  }

  public async ngOnInit(): Promise<void> {
    await this.initialize();

    if (Capacitor.isPluginAvailable('keyboard')) {
      await Keyboard.setResizeMode({mode: KeyboardResize.Body});
    }
    await switchToStatusMode('Light');
  }

  private async initialize() {
    // pre-actions before authentication
    await this.initDisplayMode();
    await this.initMenu();
    await this.initAutoLogin();
    await this.initAuthentication();
    await this.validateQueueData();
    await this.validateCacheData();
    if (this.settings.offlineMode && this.settings.cacheOfflineEvents) {
    await this.initTicketCaching();
    }
    // init system
    await this.translate.use(this.settingsService.settings$.getValue().language);
    await this.synchronisationService.initializeSynchronisation(15000);

    this.subscriptions.push(this.connectionService.connectivity.subscribe(online => this.checkForOfflineLogin(online)));
  }

  public async checkForOfflineLogin(onlineStatus: boolean) {
    if (!onlineStatus) {
      this.connectionService.isOffline$.next(true);
      const systems = await this.systemService.getSystems();
      if (systems.length) {
        this.uiFactory.build(UIType.LOADR, {message: 'Connecting...', duration: 1500, mode: 'ios'}).then(async t => {
          await t.present();

          const systemInUse = this.authenticationService.authenticatedUser.getValue();
          if (systemInUse) {
            const loader = await this.uiFactory.build(UIType.LOADR, {message: await this.translate.get('common.loading').toPromise()});
            await loader.present();
            await this.authenticationService.switchSystemTo(systemInUse);
            await loader.dismiss();
          }
        });
      }
    }else {
      if (!this.settings.isOffline) {
        this.connectionService.isOffline$.next(false);
      } else {
        this.connectionService.isOffline$.next(true);
      }
    }
  }

  private async initAutoLogin() {
    let systemToAuthenticate;
    const systems = await this.systemService.getSystems();

    try {
      if (!systems.length) {
        return;
      }

      const tokenData = await this.authenticationService.getAccessToken();

      if (tokenData) {
        this.authenticationService.authToken = tokenData.accessToken.access_token;
      }

      if (!this.connectionService.checkConnection()) {
        // DO OFFLINE LOGIN
        if (tokenData) {
          if (tokenData.system) {
            const tokenSystem = tokenData.system;
            const system = systems.find(t => t.host === tokenSystem);

            if (system) {
              systemToAuthenticate = system;
            } else {
              systemToAuthenticate = systems[0];
            }
          }
        }

        if (!systemToAuthenticate) {
          systemToAuthenticate = systems[0];
        }
        this.authenticationService.authenticatedUser.next(systemToAuthenticate);
        this.systemService.currentSystem$.next(systemToAuthenticate.host);
      } else {

        // try ONLINE login
        if (tokenData) {
          if (tokenData.system) {
            const tokenSystem = tokenData.system;
            const system = systems.find(t => t.host === tokenSystem);

            if (system) {
              systemToAuthenticate = system;
            }
          }

          if (systemToAuthenticate) {
            this.authenticationService.authenticatedUser.next(systemToAuthenticate);
            this.systemService.currentSystem$.next(systemToAuthenticate.host);
          } else {
            const loader = await this.uiFactory.build(UIType.LOADR, {message: 'Lade'});
            await loader.present();
            await this.authenticationService.switchSystemTo(systems[0]);
            await loader.dismiss();
          }
        } else {
          const loader = await this.uiFactory.build(UIType.LOADR, {message: 'Lade'});
          await loader.present();
          await this.authenticationService.switchSystemTo(systems[0]);
          await loader.dismiss();
        }
      }
    } catch (e) {
      this.uiFactory.build(UIType.TOAST, {message: 'Login fehlgeschlagen', duration: 1500}).then(t => t.present());
    }
  }

  private async initMenu(): Promise<void> {
    this.mainPages = [
      {
        component: LoginComponent,
        display: DisplayMode.ALLOW_ANONYMOUS,
        route: Route.LOGIN,
        title: 'menu.loginPage',
        icon: 'key'
      },
      {
        component: MainPageComponent,
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        route: Route.MAIN,
        title: 'menu.redeemPage',
        icon: 'qr-code'
      },
      {
        component: EventManagementListComponent,
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        route: Route.EVENTS,
        title: 'menu.eventPage',
        icon: 'ticket',
        cyTag: 'app-menu-events'
      },
      {
        component: ReservationComponent,
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        route: Route.RESERVATION,
        title: 'menu.reservationPage',
        icon: 'clipboard'
      },
      {
        component: AccountSystemComponent,
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        route: Route.SYSTEMS,
        title: 'menu.systems',
        icon: 'settings'
      },
      {
        component: ContactComponent,
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        route: Route.CONTACT,
        title: 'menu.contact',
        icon: 'people'
      },
      {
        component: ImprintComponent,
        display: DisplayMode.ALLOW_ANONYMOUS,
        route: Route.IMPRINT,
        title: 'menu.imprint',
        icon: 'reader'
      },
    ];
    this.subActions = [
      {
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        component: SettingsComponent,
        route: Route.SETTINGS,
        title: 'menu.settingsPage',
        icon: 'options',
        onClick: () => null
      },
      {
        display: DisplayMode.REQUIRE_AUTHENTICATION,
        title: 'menu.logout',
        icon: 'log-out',
        onClick: () => this.logout()
      },
    ];
  }

  private async initDisplayMode() {
    this.subscriptions.push(this.settingsService.settings$.subscribe(settings => {
      try {
        this.darkModeService.setMode(settings.darkmode);
      } catch (e) {
        this.darkModeService.setMode(false);
      }
    }));
  }

  private async initAuthentication(): Promise<void> {
    this.subscriptions.push(this.httpAccess.unauthorizedSubject.subscribe(() => {
      if (this.router.url !== '/login') {
        this.authenticationService.logout();

        this.uiFactory.build(UIType.TOAST, {
          message: 'Ihre Zugangsdaten stimmen nicht. Bitte loggen Sie sich erneut ein!',
          position: 'top',
          duration: 3000,
        }).then(toast => toast.present());
      }
    }));
  }

  private async initTicketCaching() {
    this.subscriptions.push(this.settingsService.settings$.subscribe(async (settings) => {
      if (!this.connectionService.isOffline$.getValue()) {
        if (settings.offlineMode && settings.cacheOfflineEvents) {
          const cacheConfig = await this.cacheService.findOfflineVoucher<CachedConfig>('/cachedConfig');
          let validEventIds: Array<number> = await this.getValidEventIds();
          if (!cacheConfig) {
            validEventIds = await this.cacheUpcomingEvents(validEventIds);
          } else {
            const today = new Date(Date.now());
            const cached = new Date(cacheConfig.cachedAt);

            if (today.getFullYear() !== cached.getFullYear() || today.getMonth() !== cached.getMonth() ||
              today.getDate() !== cached.getDate() || validEventIds.sort().join(',') !== cacheConfig.cachedIds.sort().join(',')) {
              validEventIds = await this.cacheUpcomingEvents(validEventIds);
            } else {
              return;
            }
          }
          let cache = await this.storageService.get<Array<CacheEntry>>(StorageKey.OFFLINE);
          const uniqueURI = this.cacheService.buildLookupKey('/cachedConfig');
          cache = await this.cacheService.offlineStore(cache, uniqueURI, {
            cachedIds: validEventIds,
            cachedAt: new Date()
          });
          await this.storageService.set<Array<CacheEntry>>(StorageKey.OFFLINE, cache);
        }
      }
    }));
  }

  private async getValidEventIds(): Promise<Array<number>> {
    const events = await this.httpAccess.getRaw<Array<Event>>('/api/frontend/eventSequence?includeEventDates&excludeEventProducts=true');
    let validEventIds: Array<number> = [];
    const today = new Date(Date.now());
    for (const event of events) {
      validEventIds = [
        ...validEventIds,
        ...event.eventDates.filter(d => {
          const date = new Date(d.start);
          return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() &&
            (date.getDate() === today.getDate() || date.getDate() === today.getDate() + 1) && d.sold > 0;
        }).map(d => d.id)
      ];
    }
    return validEventIds;
  }

  private async cacheUpcomingEvents(validEventIds: Array<number>) {
    const cachedIds: number[] = [];
    for (const id of validEventIds) {
      if (await this.httpAccess.storeForOffline(id)) {
        await this.uiManagerService.showToast(this.translate.instant('tickets.ticketDownloadSuccess'), 'ticket');
        cachedIds.push(id);
      } else {
        await this.uiManagerService.showToast(this.translate.instant('tickets.ticketAutoDownloadFailed'), 'ticket');
      }
    }
    return cachedIds;
  }

  public checkPermissionsForRoute(p: Page | MenuAction): boolean {
    if (this.authUser) {
      return p.display !== DisplayMode.ALLOW_ANONYMOUS;
    } else {
      return p.display !== DisplayMode.REQUIRE_AUTHENTICATION;
    }
  }

  private async validateQueueData() {
    const toDelete: Array<ProcessedTickets> = [];

    let history = await this.storageService.get<Array<ProcessedTickets>>(StorageKey.HISTORY) ?? [];

    history.forEach(list => {
      if (new Date().getTime() > list.validUntil) {
        toDelete.push(list);
      }
    });

    history = history
      .filter(queueElement => !toDelete
      .find(e => e.code === queueElement.code && e.action === queueElement.action));

    await this.storageService.set(StorageKey.HISTORY, history);
  }

  private async validateCacheData() {
    await this.cacheService.warmUpCache();
  }

  public async closeMenu() {
    await Haptics.impact({style: ImpactStyle.Medium});
    await this.menu.close();
  }

  public async logout() {
    await this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
