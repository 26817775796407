import {AfterContentChecked, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ORDER, RESERVATION, TICKET} from '../../../../../../shared-libs/lib-core/src/models/status/product.constants';
import {SwiperOptions} from 'swiper';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {SwiperComponent} from 'swiper/angular';
import {CacheService} from '../../../../../../shared-libs/lib-core/src/services/cache.service';
import {StatusSortedOrders} from '../../../../../../shared-libs/lib-core/src/models/order.status';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {RedeemShowOrderComponent} from '../../redeem-overview/redeem-show-order/redeem-show-order.component';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {RedeemOverviewComponent} from '../../redeem-overview/redeem-overview.component';
import {IonRouterOutlet, Platform} from '@ionic/angular';
import {VoucherHistory} from '../../../../../../shared-libs/lib-core/src/models/voucher';
import {getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {take} from 'rxjs/operators';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {TranslateService} from '@ngx-translate/core';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {Subscription} from 'rxjs';
import {
  ReservationUiHelperService
} from '../../../../../../shared-libs/lib-core/src/modules/reservation/reservation.ui-helper.service.ts.service';

@Component({
  selector: 'app-last-searched-products',
  templateUrl: './main-last-searched-products.component.html',
  styleUrls: ['./main-last-searched-products.component.scss'],
})
export class MainLastSearchedProductsComponent implements OnInit, AfterContentChecked, OnDestroy {

  @Input() isLoadingHistory = true;
  @ViewChild('swiper') swiper: SwiperComponent;

  public history: Array<VoucherHistory>;
  public swiperOptions: SwiperOptions;
  public ORDER = ORDER;
  public TICKET = TICKET;
  public RESERVATION = RESERVATION;
  private config: RedemptionConfig;
  private subscriptions: Subscription[] = [];

  constructor(
    private redemptionService: RedemptionService,
    private reservationUiHelperService: ReservationUiHelperService,
    private uiManager: UIManagerService,
    private uiFactory: UIFactoryService,
    private connectionService: ConnectionService,
    private cacheService: CacheService,
    private routerOutlet: IonRouterOutlet,
    private platform: Platform,
    private httpCachedClient: HttpCachedClient,
    private translateService: TranslateService
  ) {
    this.subscriptions.push(cacheService.searchHistory$.subscribe(history => {
      this.history = history;
    }));
  }

  ngAfterContentChecked(): void {
    if (this.swiper) {
      this.swiper.updateSwiper({});
    }
  }

  async ngOnInit(): Promise<void> {
    this.config = await this.httpCachedClient.getRaw('/api/redeemapp/config');
    this.swiperOptions = {
      slidesPerView: 2.5,
      spaceBetween: 5
    };
  }

  public async openRecentlySearchedVoucher(voucherHistory: VoucherHistory) {
    if (this.connectionService.isOffline$.getValue()) {
      await this.uiManager.showNoInternetConnectionToast();
      return;
    }

    switch (voucherHistory.type) {
      case ORDER: {
        await this.showOrderInformation(voucherHistory);
        break;
      }
      case RESERVATION: {
        await this.showReservationInformation(voucherHistory);
        break;
      }
      default: {
        await this.showVoucherInformation(voucherHistory);
      }
    }
  }

  private hasPermissionToQueryOrders() {
    if (this.config.CanQueryOrders) {
      return true;
    } else {
      this.uiManager.showToast('Fehlende Rechte für Bestellansicht. Bitte kontaktiere Deinen Portalbetreiber', 'warning', {duration: 4000}).then(t => t.present());
      return false;
    }
  }

  private async showReservationInformation(voucherHistory: VoucherHistory) {
    const loader = await this.uiManager.showLoader(await this.translateService.get('common.searchReservation').toPromise());
    try {
      const reservation = await this.reservationUiHelperService.showParticipansForId(voucherHistory.voucherCode, false);
      if (!reservation) {
        await this.uiManager.showVoucherNotFoundError();
      }
    } catch (e) {
      await this.uiManager.showVoucherNotFoundError();
    } finally {
      await loader.dismiss();
    }
  }

  private async showOrderInformation(voucherHistory: VoucherHistory) {
    if (this.hasPermissionToQueryOrders()) {
      let orders: StatusSortedOrders = null;
      const loader = await this.uiManager.showLoader(await this.translateService.get('common.searchOrder').toPromise());
      try {
        orders = await this.redemptionService.fetchArticlesWithOrderNumber(voucherHistory.voucherCode);
      } catch (e) {
        await loader.dismiss();
        await this.uiManager.showNoOrderFound(voucherHistory.voucherCode);
        return;
      } finally {
        await loader.dismiss();
      }

      if (orders) {
        await this.showOrderProductDetailPage(orders, voucherHistory.voucherCode);
      } else {
        await this.uiManager.showNoOrderFound(voucherHistory.voucherCode);
      }
    }
  }

  private async showVoucherInformation(voucherHistory: VoucherHistory) {
    const loader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());
    try {
      const voucher = await this.redemptionService.fetchVoucher(voucherHistory.voucherCode);

      if (!voucher) {
        await this.uiManager.showVoucherNotFoundError();
      }

      this.uiFactory.build(UIType.MODAL, {
        component: RedeemOverviewComponent,
        canDismiss: true,
        showBackdrop: true,
        backdropDismiss: true,
        presentingElement: getPlatform(this.platform) ? this.routerOutlet.nativeEl : null,
      }).then(m => m.present());
    } catch (e) {
      await this.uiManager.showVoucherNotFoundError();
    } finally {
      await loader.dismiss();
    }
  }

  private async showOrderProductDetailPage(orders: StatusSortedOrders, code: string) {
    const modal = await this.uiFactory.build(UIType.MODAL, {
      component: RedeemShowOrderComponent,
      componentProps: {
        orders,
        orderId: code
      },
      presentingElement: getPlatform(this.platform) ? this.routerOutlet.nativeEl : null,
      canDismiss: true,
      showBackdrop: true,
      backdropDismiss: false,
    });

    await modal.present();

    return alert;
  }

  public async removeProduct(event: any, voucher: VoucherHistory) {
    event.stopPropagation();

    await this.uiManager.showConfirmDialog(
      'Bestätigen',
      'Den Gutschein aus dem lokalen Verlauf löschen?',
      'Bestätigen',
      'Abbrechen',
      () => {
        this.cacheService.searchHistory$
          .pipe(take(1))
          .subscribe(async history => {
            this.cacheService.searchHistory$.next(history.filter(s => s.voucherCode !== voucher.voucherCode));
            await this.uiManager.showToast('Artikel aus Verlauf entfernt', 'checkmark', {duration: 1500});
          });
      }
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
