import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder} from '@angular/forms';
import {RedeemErrorMapper} from '../../../../../../shared-libs/lib-core/src/utilities/mapper/redeem.error.mapper';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {VoucherStatus} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {RedeemType} from '../../../../../../shared-libs/lib-core/src/models/redeem.type';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {IonItemSliding, IonRefresher} from '@ionic/angular';
import {EmailValidator} from '../../../../../../shared-libs/lib-core/src/utilities/helper/email.validator';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {AuthenticationService} from '../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {VOUCHERCODE_VALID} from '../../../../../../shared-libs/lib-core/src/models/status/voucher.status.constants';
import {AttributeValue} from '../../../../../../shared-libs/lib-core/src/models/common';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {Subscription} from 'rxjs';
import {UnredemtionService} from '../../../../../../shared-libs/lib-core/src/services/unredemption.service';

@Component({
  selector: 'app-redeem-card',
  templateUrl: './redeem-show.component.html',
  styleUrls: ['./redeem-show.component.scss'],
})
export class RedeemShowComponent implements OnInit, OnDestroy {

  @Input() config: RedemptionConfig;
  @Input() closeAfterAction = false;
  @Input() attributeData: Array<AttributeValue> = [];

  public voucher: VoucherStatus;
  public initialized: boolean;
  public comment: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private t: TranslateService,
    private formBuilder: FormBuilder,
    private redemptionService: RedemptionService,
    private uiFactoryService: UIFactoryService,
    private uiManager: UIManagerService,
    public connectionService: ConnectionService,
    public authenticationService: AuthenticationService,
    private unredemtionService: UnredemtionService,
  ) {
    this.subscriptions.push(this.redemptionService.voucher$.subscribe(v => {
      if (v) {
        this.voucher = v;
      }
    }));
  }

  ngOnInit() {
  }

  public canRedeem() {
    return this.config?.RedemptionUnitRedeemPermission ||
      this.config?.FullyRedeemPermission ||
      this.config?.PartialRedeemPermission;
  }

  public async redeemFull(): Promise<void> {

    if (this.voucher.EventData) {
      const now = new Date();
      const endDate = new Date(this.voucher.EventData.end);
      if (now > endDate) {
        const result = await new Promise(async (resolve) => {
          const t = await this.uiFactoryService.build(UIType.ALERT,
            {
              header: await this.t.get('main-detail.main.eventElapsed.title').toPromise(),
              message: await this.t.get('main-detail.main.eventElapsed.message').toPromise(),
              buttons: [
                {
                  text:  await this.t.get('common.no').toPromise(),
                  handler: () => {
                    resolve(false);
                  }
                },
                {
                  text:  await this.t.get('common.yes').toPromise(),
                  handler: () => {
                    resolve(true);
                  }
                },

              ]

            });
          await t.present();
        });
        if (!result) {
          return;
        }
      }
    }

    await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.t.get('main-detail.main.fullRedeemConfirmation.title').toPromise(),
      message: await this.t.get('main-detail.main.fullRedeemConfirmation.message').toPromise(),
      buttons: [{
        text: await this.t.get('main-detail.main.fullRedeemConfirmation.abortButton').toPromise(),
        role: 'cancel'
      },
        {
          text: await this.t.get('main-detail.main.fullRedeemConfirmation.okButton').toPromise(),
          handler: async () => {
            const loader = await this.uiFactoryService.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});
            await loader.present();

            this.redemptionService.redeemFull(this.config, this.voucher, this.comment)
              .then((redeemResult) => {
                  loader.dismiss();
                  this.processRedemptionResult(redeemResult, RedeemType.FULL);
                },
                err => {
                  loader.dismiss();
                  this.showRedeemError(err);
                }
              );
          }
        }
      ]
    }).then(t => t.present());
  }

  public async redeemPartly(amount?: number, email?: string): Promise<void> {
    if (!amount) {
      await this.redeemPartlyWithAskForAmount();
      return;
    }

    const loader = await this.uiFactoryService.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});

    await loader.present();
    const redeemResult = await this.redemptionService.redeemPartly(this.config, this.voucher, amount, this.comment, email);
    await loader.dismiss();

    await this.processRedemptionResult(redeemResult, RedeemType.PARTLY);
  }

  public async redeemPartlyWithAskForAmount(): Promise<void> {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.t.get('main-detail.main.partAmountInput.title').toPromise(),
      subHeader: await this.t.get('main-detail.main.partAmountInput.subTitle').toPromise(),
      buttons: [
        {
          text: await this.t.get('main-detail.main.partAmountInput.abortButton').toPromise(),
          role: 'cancel'
        },
        {
          text: await this.t.get('main-detail.main.partAmountInput.okButton').toPromise(),
          handler: (data) => {
            // only on print at home voucher is makes sends to ask for email, otherwise forget about it
            if (this.config.Redemption.PromptEmail &&
              (this.voucher.ArticleType === '1' || this.voucher.ArticleType === '2' || this.voucher.ArticleType === '3')
            ) {
              this.redeemPartlyAskForEmail(data.value).then(() => {
                  /* check this line */
                }
              );
            } else {
              this.redeemPartly(data.value);
            }
          }
        }
      ],
      inputs: [
        {
          name: 'value',
          placeholder: await this.t.get('main-detail.main.partAmountInput.valuePlaceholder').toPromise(),
          type: 'number'
        }
      ]
    });

    await alert.present();
  }

  public async redeemPartlyAskForEmail(amount: number): Promise<void> {

    const wrongMailTitle: string = await this.t.get('main-detail.main.wrongEmail.title').toPromise();
    const wrongMailSubtitle: string = await this.t.get('main-detail.main.wrongEmail.title').toPromise();
    const wrongMailOkButton: string = await this.t.get('main-detail.main.wrongEmail.okButton').toPromise();

    const alert = await this.uiFactoryService.build(UIType.ALERT, {
        header: await this.t.get('main-detail.main.partAskForEmail.title').toPromise(),
        subHeader: await this.t.get('main-detail.main.partAskForEmail.subTitle').toPromise(),
        inputs: [
          {
            name: 'email',
            placeholder: 'E-Mail'
          },
        ],
        buttons: [
          {
            text: await this.t.get('main-detail.main.partAskForEmail.skipButton').toPromise(),
            handler: () => this.redeemPartly(amount)
          },
          {
            text: await this.t.get('main-detail.main.partAskForEmail.okButton').toPromise(),
            handler: async (data) => {

              if (!EmailValidator.validateEmail(data.email)) {
                const al = await this.uiFactoryService.build(UIType.ALERT,
                  {
                    header: wrongMailTitle,
                    subHeader: wrongMailSubtitle,
                    buttons: [
                      {
                        text: wrongMailOkButton,
                        handler: () => {
                          this.redeemPartlyAskForEmail(amount).then(() => {
                          });
                        }
                      }
                    ]
                  }
                );

                await al.present();
              } else {
                this.redeemPartly(amount, data.email).then();
              }
            }
          }
        ]
      }
    );

    await alert.present();
  }

  public async redeemOneRedemptionUnit(redemptionUnit: any): Promise<void> {
    if (!this.config.RedemptionUnitRedeemPermission) {
      const ctrl = await this.uiFactoryService.build(UIType.TOAST, {
        message: 'Fehlende Berechtigung zum Einlösen',
        duration: 2000
      });

      await ctrl.present();
      return;
    }
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.t.get('main-detail.main.redemptionUnitConfirmation.title').toPromise(),
      message: await this.t.get('main-detail.main.redemptionUnitConfirmation.message',
        {name: redemptionUnit.RedUnitName}).toPromise(),
      buttons: [
        {
          text: await this.t.get('main-detail.main.redemptionUnitConfirmation.abortButton').toPromise(),
          role: 'cancel'
        },
        {
          text: await this.t.get('main-detail.main.redemptionUnitConfirmation.okButton').toPromise(),
          handler: async () => {
            const loader = await this.uiFactoryService.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});

            await loader.present();
            this.redemptionService.redeemOneRedemptionUnit(this.config, this.voucher, redemptionUnit, this.comment).then((redeemResult) => {
                loader.dismiss();
                this.processRedemptionResult(redeemResult, RedeemType.REDEMPTION_UNIT);
              },
              err => {
                loader.dismiss();
                this.showRedeemError(err);
              }
            );
          }
        }
      ]
    });
    await alert.present();
  }

  public async redeemBundleInOne() {
    await this.uiManager.showConfirmDialog(
      'Bundle einlösen',
      'Bist du sicher, dass du das gesamte Bundle einlösen möchtest?',
      'Einlösen',
      'Abbrechen',
      () => {
        this.redemptionService.redeemFullRedemptionUnitArticle(this.config, this.voucher, this.comment)
          .then(redeemResult => this.processRedemptionResult(redeemResult, RedeemType.FULL));
      });
  }

  protected async showRedeemError(error: any): Promise<void> {
    const alert = await this.uiFactoryService.build(UIType.ALERT,
      {
        header: await this.t.get('main-detail.main.error.title').toPromise(),
        subHeader: await this.t.get('main-detail.main.error.subTitle').toPromise(),
        buttons: [
          await this.t.get('main-detail.main.error.okButton').toPromise(),
        ],
        cssClass: 'alert-error'
      }
    );

    await alert.present();
  }

  public async refresh(event?: any) {
    if (!this.connectionService.isOffline$.getValue()) {
      this.redemptionService.fetchVoucher(this.voucher.Code).then(voucher => {
        this.redemptionService.voucher$.next(voucher);
      });
    }

    if (event) {
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    }
  }

  /**
   * After the redemption, process the result, indicate about the status and reload the voucher
   */
  private async processRedemptionResult(result: any, redeemType: RedeemType) {
    if (result.Success) {
      if (this.closeAfterAction) {
        await this.uiFactoryService.dismissModal();
      }

      const loader = await this.uiFactoryService.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});

      await loader.present();
      this.comment = '';
      this.voucher = await this.redemptionService.fetchVoucher(this.voucher.Code);

      await loader.dismiss();

      this.redemptionService.voucher$.next(this.voucher);

      let message = await this.t.get('main-detail.main.result.default').toPromise();

      switch (redeemType) {
        case RedeemType.FULL:
          message = await this.t.get('main-detail.main.result.full').toPromise();
          break;

        case RedeemType.PARTLY:
          message = await this.t.get('main-detail.main.result.partly').toPromise();
          break;

        case RedeemType.REDEMPTION_UNIT:
          message = await this.t.get('main-detail.main.result.redemptionUnit').toPromise();
          break;
      }

      this.uiManager.showToast(message, 'checkmark', {position: 'bottom', duration: 3000})
        .then(t => t.present());
    } else {

      let errorMessage: string = result.Error.ErrorDescription;

      if (result.Error.ErrorCode) {
        errorMessage = await RedeemErrorMapper.getMessage(this.t, result.Error.ErrorCode);
      }

      const alert = await this.uiFactoryService.build(UIType.ALERT, {
        message: errorMessage,
        header: await this.t.get('main-detail.main.result.errorTitle').toPromise(),
        cssClass: 'alert-error',
        buttons: ['OK']
      });

      await alert.present();
    }
  }

  public hasAccessToHistory() {
    return this.voucher?.Status === 3 && this.config?.ViewRedemptionHistory &&
      this.authenticationService.showPrivacyData();
  }

  public hasRedemptionUnits(): boolean {
    if (!this.voucher) {
      return false;
    }

    if (this.voucher.RedemptionUnits) {
      return this.voucher.RedemptionUnits.length > 0;
    }

    return false;
  }

  slide(slider: IonItemSliding) {
    slider.open('end');
  }

  canRedeemBundleInOne() {
    return this.voucher.RedemptionUnits?.length &&
      this.config?.FullyRedeemPermission &&
      this.voucher.Status === VOUCHERCODE_VALID;
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public async unredeemSingleProduct(voucherCode: string) {
     await this.unredemtionService.unredeemProduct(voucherCode);
     await this.refresh();
  }
}
