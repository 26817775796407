import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../../../../shared-libs/lib-core/src/services/settings.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'page-impressum',
  templateUrl: 'imprint.component.html',
  styleUrls: ['imprint.component.scss']
})
export class ImprintComponent implements OnInit, OnDestroy {

  public languageUsed: string;
  private subscriptions: Subscription[];

  public constructor(
    private settingsService: SettingsService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage();
  }

  public async getLanguage() {
    this.subscriptions.push(this.settingsService.settings$.subscribe(language => {
      this.languageUsed = language.language;
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
