import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UIFactoryService, UIType} from '../../services/ui-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {switchToStatusMode} from "../../utilities/helper/utils";

export interface UpdateSlideConfig {
  title: string;
  subTitle: string;
  content: string;
  imageSourceUri: string;
}

@Component({
  selector: 'app-update-indicator',
  templateUrl: './inc-update-indicator.component.html',
  styleUrls: ['./inc-update-indicator.component.scss'],
})
export class IncUpdateIndicatorComponent implements OnInit, OnDestroy {

  /**
   * @BugWorkaround
   * https://github.com/ionic-team/ionic-framework/issues/20356#issuecomment-591301060
   */
  public didInit: boolean;

  public updateSlideConfig: Array<UpdateSlideConfig> = [];
  public slideOpts = {initialSlide: 0, speed: 400};

  constructor(
    private router: Router,
    private uiFactory: UIFactoryService,
    private t: TranslateService
  ) {
  }

  async ngOnInit() {
    await this.createSlides();
    setTimeout(() => {
      this.didInit = true;
    }, 250);
  }

  private async createSlides() {
    this.updateSlideConfig = [
      {
        title: await this.t.get('updateIndicator.updateSuccessfull').toPromise(),
        subTitle: await this.t.get('updateIndicator.updateShowNewThings').toPromise(),
        content: await this.t.get('updateIndicator.swipeRightToStart').toPromise(),
        imageSourceUri: 'intro.png'
      },
      {
        title: await this.t.get('updateIndicator.newDesign').toPromise(),
        subTitle: await this.t.get('updateIndicator.newDesignLook').toPromise(),
        content: await this.t.get('updateIndicator.newDesignText').toPromise(),
        imageSourceUri: 'logo-circle.png'
      },
      {
        title: await this.t.get('updateIndicator.multiSystemUserTitle').toPromise(),
        subTitle: await this.t.get('updateIndicator.multiSystemUserText').toPromise(),
        content: await this.t.get('updateIndicator.multiSystemUserContent').toPromise(),
        imageSourceUri: 'account_systems.png'
      },
      {
        title: await this.t.get('updateIndicator.ticketsTitle').toPromise(),
        subTitle: await this.t.get('updateIndicator.ticketsText').toPromise(),
        content: await this.t.get('updateIndicator.ticketsContent').toPromise(),
        imageSourceUri: 'tickets.png'
      },
      {
        title: await this.t.get('updateIndicator.darkmodeTitle').toPromise(),
        subTitle: await this.t.get('updateIndicator.darkmodeText').toPromise(),
        content: await this.t.get('updateIndicator.darkmodeContent').toPromise(),
        imageSourceUri: 'light.png',
      },
      {
        title: await this.t.get('updateIndicator.utilizationTitle').toPromise(),
        subTitle: await this.t.get('updateIndicator.utilizationText').toPromise(),
        content: await this.t.get('updateIndicator.utilizationContent').toPromise(),
        imageSourceUri: 'utilization.PNG'
      },
      {
        title: await this.t.get('updateIndicator.finalTitle').toPromise(),
        subTitle: await this.t.get('updateIndicator.finalText').toPromise(),
        content: await this.t.get('updateIndicator.finalContent').toPromise(),
        imageSourceUri: 'ready_to_go.png'
      }
    ];
  }

  public async navigate() {
    await this.uiFactory.dismissModal();

    // todo is this really needed?
    await this.router.navigate(['main']);
  }

  ngOnDestroy(): void {
    this.updateSlideConfig = [];
  }

  async restartModal() {
    await this.createSlides();
    await this.uiFactory.dismissModal();
    await this.uiFactory.build(UIType.MODAL, {
      component: IncUpdateIndicatorComponent,
      backdropDismiss: false,
      cssClass: 'inc-modal',
      canDismiss: false,
      showBackdrop: true
    });
  }
}
