import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../services/settings.service';
import {DefinedSettings} from '../../models/common';
import {Subscription} from 'rxjs';
import {ConnectionService} from '../../services/connection.service';


@Component({
  selector: 'app-inc-offline-header',
  templateUrl: './inc-offline-header.component.html',
  styleUrls: ['./inc-offline-header.component.scss'],
})
export class IncOfflineHeaderComponent implements OnInit, OnDestroy {

  public settings: DefinedSettings;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public settingsService: SettingsService,
    public connectionsService: ConnectionService,
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.settingsService.settings$.subscribe(settings => {
      this.settings = settings;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleIsOffline(e) {
    if (this.settings?.offlineMode) {
      this.settingsService.settings$.next(this.settings);
    }
    this.connectionsService.checkConnection();
  }

}
