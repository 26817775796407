import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-inc-imprint-header',
  templateUrl: './inc-imprint-header.component.html',
  styleUrls: ['./inc-imprint-header.component.scss'],
})
export class IncImprintHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
