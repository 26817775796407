import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-inc-no-content',
  templateUrl: './inc-no-content.component.html',
  styleUrls: ['./inc-no-content.component.scss'],
})
export class IncNoContentComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;

  constructor(
    private t: TranslateService
  ) { }

  ngOnInit() {}

}
