import {Injectable, OnDestroy} from '@angular/core';
import {cleanHostValue, SystemCredential} from './authentication';
import {BehaviorSubject, Subscription} from 'rxjs';
import {StorageKey, StorageService} from '../services/storage.service';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../services/system.service';
import {HttpClient} from '@angular/common/http';
import {CacheService} from '../services/cache.service';

export interface TokenMeta {
  access_token: string;
  token_type: string;
  expires_in: string;
  expirationTime: string;
}

export interface AuthenticationMeta {
  accessToken: TokenMeta;
  system: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  public constructor(
    private storageService: StorageService,
    private settingsService: SystemService,
    private httpClient: HttpClient,
    private systemService: SystemService,
    private translateService: TranslateService,
    private cacheService: CacheService
  ) {
    this.systemSubscr = this.systemService.currentSystem$.subscribe(system => this.currentSystem = system);
  }

  public get authenticatedUser() {
    return this._authenticatedUser;
  }

  private systemSubscr: Subscription;
  public _authenticatedUser: BehaviorSubject<SystemCredential | null> = new BehaviorSubject<SystemCredential | null>(null);
  public authToken: any;

  private currentSystem: string;

  private static validateLoginRequestResponse(loginRequestResponse: TokenMeta) {
    if (!loginRequestResponse) {
      throw new Error('no token meta received from /authentication');
    }
  }

  private static async isTokenValid(accessToken: number) {
    if (accessToken) {
      return new Date().getTime() < new Date(accessToken).getTime();
    } else {
      return false;
    }
  }

  private async sendLoginRequest(credentials: SystemCredential) {
    return await this.httpClient.post<TokenMeta>(credentials.host + '/api/authentication', {
      username: credentials.terminalID,
      password: credentials.terminalPassword
    }).toPromise();
  }

  public async authenticate(credentials: SystemCredential): Promise<boolean> {
    credentials.host = cleanHostValue(credentials.host);

    try {
      const loginRequestResponse = await this.sendLoginRequest(credentials);

      this.authToken = loginRequestResponse.access_token;

      AuthenticationService.validateLoginRequestResponse(loginRequestResponse);

      await this.storeToken(loginRequestResponse, credentials.host);

      const additionalSystemInformation = await this.fetchAdditionalSystemInformation(credentials.host);

      const fullSystemCredentials: SystemCredential = {
        host: credentials.host,
        storeName: additionalSystemInformation.storeName,
        isPortal: additionalSystemInformation.isPortal,
        terminalID: credentials.terminalID,
        terminalPassword: credentials.terminalPassword,
      };

      await this.systemService.addSystem(fullSystemCredentials);

      this.systemService.currentSystem$.next(credentials.host);

      await this.cacheService.warmUpCache();

      this.authenticatedUser.next(fullSystemCredentials);

      return true;
    } catch (response) {
      return false;
    }
  }

  private async storeToken(tokenMeta: TokenMeta, system: string) {
    tokenMeta.expirationTime = this.getTokenExpirTime();

    this.authToken = tokenMeta.access_token;

    await this.storageService.set(StorageKey.TOKENCRED, {accessToken: tokenMeta, system});
  }

  public getTokenExpirTime() {
    const dt = new Date();

    dt.setDate(dt.getDate() + 1);

    return dt.getTime() + '';
  }

  public async switchSystemTo(credentials: SystemCredential): Promise<void> {
    this.authenticate(credentials).then(async () => {
      this.authenticatedUser.next(credentials);
      this.settingsService.currentSystem$.next(credentials.host);
      await this.cacheService.warmUpCache();
    }).catch(async e => {});
  }

  public async getAccessToken(): Promise<AuthenticationMeta | null> {
    const tokenData = await this.storageService.get<AuthenticationMeta>(StorageKey.TOKENCRED);
    if (!tokenData || !tokenData.accessToken.access_token) {
      return null;
    } else {
      const isValid = await AuthenticationService.isTokenValid(Number(tokenData.accessToken.expirationTime));

      if (isValid) {
        return tokenData;
      } else {
        return null;
      }
    }
  }

  public async logout(): Promise<void> {
    await this.storageService.remove(StorageKey.TOKENCRED);

    const creds = (await this.storageService.get<Array<SystemCredential>>(StorageKey.SYSTEMS) ?? [])
      .filter(f => f.host !== this.currentSystem);

    await this.storageService.set(StorageKey.SYSTEMS, creds);

    this.authenticatedUser.next(null);
  }

  private async fetchAdditionalSystemInformation(host: string): Promise<Pick<SystemCredential, 'storeName' | 'isPortal'>> {
    const additionalSystemInformation: SystemCredential = await this.getAccountSystemInfo(host);

    if (!additionalSystemInformation.storeName) {
      additionalSystemInformation.storeName = host;
    }

    return additionalSystemInformation;
  }

  public async getAccountSystemInfo(host: string): Promise<SystemCredential> {
    return this.httpClient.get<SystemCredential>(host + '/api/redeemapp/system/information').toPromise();
  }

  /**
   * show privacy content such as redemption views, if the system is not a portal.
   */
  public showPrivacyData() {
    return !this.authenticatedUser.getValue()?.isPortal;
  }

  ngOnDestroy(): void {
    this.systemSubscr.unsubscribe();
  }
}

