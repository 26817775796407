import {Injectable, OnDestroy} from '@angular/core';
import {StorageKey, StorageService} from './storage.service';
import {SystemService} from './system.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {VoucherHistory} from '../models/voucher';
import {take} from 'rxjs/operators';
import {VoucherStatus} from '../models/voucher.status';
import {SettingsService} from './settings.service';
import {DefinedSettings} from '../models/common';
import {Ticket} from '../../../../src/app/components/pages/event-management/event-management.model';


export interface CacheEntry {
  uri: string;
  data: any;
  ttl: number;
}

export interface CachedConfig {
  cachedIds: Array<number>;
  cachedAt: number;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService implements OnDestroy {

  constructor(
    private storageService: StorageService,
    private systemService: SystemService,
    private settingsService: SettingsService,
  ) {
    this.subscriptions.push(this.systemService.currentSystem$.subscribe(s => {
      this.system = s;
    }));

    this.subscriptions.push(this.settingsService.settings$.subscribe(s => {
      this.settings = s;
    }));

    this.persistSearchHistoryOnChanges();
  }

  public get cache$() {
    return this._cache$;
  }

  public get searchHistory$(): BehaviorSubject<Array<VoucherHistory>> {
    return this._searchHistory$;
  }

  private TTL = 192800;
  private system: string;
  private subscriptions: Subscription[] = [];
  private _cache$: BehaviorSubject<Array<CacheEntry>> = new BehaviorSubject<Array<CacheEntry>>([]);
  private _searchHistory$: BehaviorSubject<Array<VoucherHistory>> = new BehaviorSubject<Array<VoucherHistory>>([]);
  private _offlineVouchers$: BehaviorSubject<Array<CacheEntry>> = new BehaviorSubject<Array<CacheEntry>>([]);
  private settings: DefinedSettings;

  public static validateTTL(cacheEntry: CacheEntry) {
    return (cacheEntry?.ttl - CacheService.getCurrentTime()) >= 0;
  }

  private static getCurrentTime() {
    return new Date().getTime();
  }

  private persistSearchHistoryOnChanges() {
    this.subscriptions.push(this.searchHistory$.subscribe(async newSystemHistoryList => {
      let persistedEntriesAll = await this.storageService.get<Array<VoucherHistory>>(StorageKey.HISTORY) ?? [];

      persistedEntriesAll = persistedEntriesAll.filter(historyItem => {
        if (!historyItem?.validUntil) return false;
        if (historyItem.validUntil > new Date().getTime()) return true;
      });

      await this.storageService.set(StorageKey.HISTORY, [
        ...persistedEntriesAll.filter(s => s.system !== this.system),
        ...newSystemHistoryList
      ]);
    }));
  }

  public offlineStore(cache: any, uniqueURI: string, data) {
    if (cache.find(c => c.uri === uniqueURI)) {
      cache[cache.findIndex(c => c.uri === uniqueURI)] = {
        uri: uniqueURI,
        data,
        ttl: this.getNewTTL(uniqueURI)
      };
    } else {
      cache = [
        ...cache,
        {
          uri: uniqueURI,
          data,
          ttl: this.getNewTTL(uniqueURI)
        }
      ];
    }
    return cache;
  }

  public async changeTicketStatus(voucher: Partial<VoucherStatus>){
    const uniqueURI = this.buildLookupKey('/' + voucher.Code);
    const cache = await this.storageService.get<Array<CacheEntry>>(StorageKey.OFFLINE) ?? [];

    cache[cache.findIndex(c => c.uri === uniqueURI)] = {
      uri: uniqueURI,
      data: {
        voucherCode: voucher.Code,
        curVoucherStatusId: voucher.Status === 10 ? 9 : 10,
      },
      ttl: this.getNewTTL(uniqueURI)
    };
    await this.storageService.set<Array<CacheEntry>>(StorageKey.OFFLINE, cache);
    this._offlineVouchers$.next(cache);
  }

  public async store(endpoint: string, cacheEntryData: any): Promise<boolean> {
    try {

      if (Array.isArray(cacheEntryData) && !cacheEntryData.length) {
        return false;
      }

      if (typeof cacheEntryData === 'object' && cacheEntryData === null) {
        return false;
      }

      const uniqueURI = this.buildLookupKey(endpoint);
      let cache = await this.storageService.get<Array<CacheEntry>>(StorageKey.CACHE) ?? [];

      if (cache.find(c => c.uri === uniqueURI)) {
        cache[cache.findIndex(c => c.uri === uniqueURI)] = {
          uri: uniqueURI,
          data: cacheEntryData,
          ttl: this.getNewTTL(uniqueURI)
        };
      } else {
        cache = [
          ...cache,
          {
            uri: uniqueURI,
            data: cacheEntryData,
            ttl: this.getNewTTL(uniqueURI)
          }
        ];
      }

      await this.storageService.set<Array<CacheEntry>>(StorageKey.CACHE, cache);

      this._cache$.next(cache);

      return true;
    } catch (e) {
      return false;
    }
  }

  public async find<T>(endpoint: string): Promise<Array<T> | T | null> {
    const cache = this._cache$.value;
    const lookupUri = this.buildLookupKey(endpoint);
    const existingEntry = cache.find(c => c.uri === lookupUri);

    if (existingEntry) {
      if (CacheService.validateTTL(existingEntry)) {
        return existingEntry.data;
      } else {
        await this.filterInvalidCacheItems(cache, lookupUri, StorageKey.CACHE);

        return null;
      }
    } else {
      return null;
    }
  }

  public async findOfflineVoucher<T>(voucherCode: string): Promise< T | null> {
    const vouchers = this._offlineVouchers$.value;
    const lookupUri = this.buildLookupKey(voucherCode);
    const existingEntry = vouchers.find(v => v.uri === lookupUri);
    if (existingEntry) {
      if (CacheService.validateTTL(existingEntry)) {
        return existingEntry.data;
      } else {
        await this.filterInvalidCacheItems(vouchers, lookupUri, StorageKey.OFFLINE);

        return null;
      }
    } else {
      return null;
    }
  }

  public async findOfflineVouchersEventDate(eventDateId): Promise<Array<Ticket> | null> {
    const vouchers = this._offlineVouchers$.value;
    const tickets: Array<Ticket> = [];
    vouchers.map(voucher => {
      if (voucher.data.eventDateId === eventDateId) {
        tickets.push(voucher.data);
      }
    });
    return tickets;
  }

  /**
   * calculate the TTL based on received values
   * define more substrs to better time some requests values, events or more.
   */
  public getNewTTL(endpoint: string) {
    if (endpoint.includes('upcoming')) {
      return new Date().getTime() + 50000 * 1000;
    } else if (endpoint.includes('config')) {
      return new Date().getTime() + 86400000;
    } else {
      return new Date().getTime() + this.TTL * 1000;
    }
  }

  public async warmUpCache() {
    await this.warmupCacheForCacheItems();
    await this.warmUpCacheForLastSearchedItems();
    await this.warmUpCacheForOfflineVoucher();
  }

  public async warmupCacheForCacheItems() {
    const cache = await this.storageService.get<Array<CacheEntry>>(StorageKey.CACHE) ?? [];

    const cacheEntry: Array<CacheEntry> = [];

    cache.forEach(cacheItem => {
      if (CacheService.validateTTL(cacheItem)) {
        cacheEntry.push(cacheItem);
      }
    });

    this.cache$.next(cacheEntry);
    await this.storageService.set(StorageKey.CACHE, cacheEntry);
  }

  public async warmUpCacheForLastSearchedItems() {
    const history = await this.storageService.get<Array<VoucherHistory>>(StorageKey.HISTORY) ?? [];

    this.subscriptions.push(this.systemService.currentSystem$
      .pipe(take(1))
      .subscribe(system => {
        this.searchHistory$.next(history.filter(entry => entry.system === system));
      }));
  }

  public async warmUpCacheForOfflineVoucher() {
    const vouchers = await this.storageService.get<Array<CacheEntry>>(StorageKey.OFFLINE) ?? [];
    const voucherEntries: Array<CacheEntry> = [];

    vouchers.forEach(voucherItem => {
      if (CacheService.validateTTL(voucherItem)) {
        voucherEntries.push(voucherItem);
      }
    });

    this._offlineVouchers$.next(voucherEntries);
    await this.storageService.set(StorageKey.OFFLINE, voucherEntries);
  }

  private async filterInvalidCacheItems(cache: Array<CacheEntry>, lookupUri: string, cacheKey: StorageKey) {
    const updatedCache = cache.filter(c => c.uri !== lookupUri);
    await this.storageService.set(cacheKey, updatedCache);
    if (cacheKey === StorageKey.CACHE){
      this._cache$.next(updatedCache);
    } else if (cacheKey === StorageKey.OFFLINE){
      this._offlineVouchers$.next(updatedCache);
    }
  }

  public buildLookupKey(endpoint: string) {
    return this.system + endpoint;
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
