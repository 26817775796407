import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Event, EventSequence, FavouriteEvent, StoredFavourite, TicketAction} from './event-management.model';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventManagementService {

  #searchObsv$: Subject<string> = new Subject<string>();
  #favoritesUpdated$: BehaviorSubject<Array<StoredFavourite>> = new BehaviorSubject<Array<StoredFavourite>>([]);
  #subproductAdded$: Subject<string> = new Subject<string>();
  #ticketAction$: Subject<TicketAction> = new Subject<TicketAction>();

  constructor(
    private storageService: StorageService
  ) {
  }

  get searchObs$(): Subject<string> {
    return this.#searchObsv$;
  }

  get subproductAdded$(): Subject<string> {
    return this.#subproductAdded$;
  }

  get ticketAction$(): Subject<TicketAction> {
    return this.#ticketAction$;
  }

  get favoritesUpdated$(): BehaviorSubject<Array<StoredFavourite>> {
    return this.#favoritesUpdated$;
  }

  public async fetchFavouritesForSystem(system: string, events: Array<EventSequence>): Promise<Array<FavouriteEvent> | []> {
    try {
      return this.storageService.get<Array<StoredFavourite>>(StorageKey.FAVOURITES).then(storedFavourites => {
        if (storedFavourites) {
          return storedFavourites
            .filter(favSys => favSys.system === system)
            .map(element => {
              return ({
                event: events.find((e) => e.id.toString() === element.eventId.toString()),
                dateId: element.dateId,
              });
            });
        }
        return [];
      });
    } catch (e) {
      return [];
    }
  }

  public async addToFavourites(system: string, favouriteItem: StoredFavourite): Promise<boolean> {
    try {
      return this.storageService.get<Array<StoredFavourite>>(StorageKey.FAVOURITES).then(storedFavourites => {
        if (storedFavourites) {
          if (storedFavourites.find(f => f.system === system && f.eventId === favouriteItem.eventId && f.dateId === favouriteItem.dateId)) {
            return false;
          }
          const storedResult = this.storageService.set(StorageKey.FAVOURITES, [...storedFavourites, favouriteItem]);
          this.#favoritesUpdated$.next([...storedFavourites, favouriteItem]);

          return storedResult;
        } else {
          const storedResult = this.storageService.set(StorageKey.FAVOURITES, [favouriteItem]);
          this.#favoritesUpdated$.next([favouriteItem]);

          return storedResult;
        }
      });
    } catch (e) {
      return false;
    }
  }

  public removeFromFavourites(favouriteItem: Partial<StoredFavourite>) {
    return this.storageService.get<Array<StoredFavourite>>(StorageKey.FAVOURITES).then(storedFavourites => {
      if (storedFavourites) {
        if (favouriteItem.eventId && favouriteItem.dateId) {
          return this.storageService.set(StorageKey.FAVOURITES,
            storedFavourites
              .splice(storedFavourites
                .findIndex(e => e.eventId === favouriteItem.eventId && e.dateId === favouriteItem.dateId), 1)
          );
        } else {
          storedFavourites
            .splice(storedFavourites
              .findIndex(e => e.eventId === favouriteItem.eventId && !e.dateId), 1);
          if (storedFavourites.length > 0){
            return this.storageService.set(StorageKey.FAVOURITES, storedFavourites);
          }else{
            this.storageService.remove(StorageKey.FAVOURITES);
          }
        }
      } else {
        return null;
      }
    });
  }
}
