import {Pipe, PipeTransform} from '@angular/core';

@Pipe(
    {
        name: 'invoucherbackgroundstatecolor'
    }
)
export class VoucherStateBackgroundColorPipe implements PipeTransform {

    public transform(value: any, ...args: any[]) {
        switch (value) {
            default:
                return '#000000';
        }
    }
}
