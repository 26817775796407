
import {
  INVALID_PIN_CODE,
  REDEEM_AMOUNT_TOO_HIGH,
  REDEEM_AMOUNT_TOO_LOW,
  VOUCHER_CANCELED,
  VOUCHER_CODE_NOT_FOUND,
  VOUCHER_EXPIRED, VOUCHER_FULLY_REDEEMED,
  VOUCHER_NOT_PAYED,
  VOUCHER_NOT_VALID_FOR_THIS_STATION,
} from '../../models/status/voucher.redeem.error.constants';
import {TranslateService} from '@ngx-translate/core';
import {UNSUFFICIENT_RIGHTS} from '../../models/status/voucher.status.constants';

export class RedeemErrorMapper {

  /**
   *  TODO Check in next update, they are probably all wrong or no longer valid.
   */
  public static async getMessage(t: TranslateService, code: number): Promise<string> {
    switch (code) {
      case UNSUFFICIENT_RIGHTS:
        return await t.get('redeemError.unsufficientRights').toPromise();
      case VOUCHER_CODE_NOT_FOUND:
        return await t.get('redeemError.voucherFullyRedeemed').toPromise();
      case VOUCHER_FULLY_REDEEMED:
        return await t.get('redeemError.voucherFullyRedeemed').toPromise();
      case VOUCHER_CANCELED:
        return await t.get('redeemError.voucherCanceled').toPromise();
      case REDEEM_AMOUNT_TOO_LOW:
        return await t.get('redeemError.redeemAmountTooLow').toPromise();
      case REDEEM_AMOUNT_TOO_HIGH:
        return await t.get('redeemError.redeemAmountTooHigh').toPromise();
      case VOUCHER_EXPIRED:
        return await t.get('redeemError.voucherExpired').toPromise();
      case VOUCHER_NOT_PAYED:
        return await t.get('redeemError.voucherNotPayed').toPromise();
      case VOUCHER_NOT_VALID_FOR_THIS_STATION:
        return await t.get('redeemError.voucherNotValidForThisStation').toPromise();
      case INVALID_PIN_CODE:
        return await t.get('redeemError.invalidPinCode').toPromise();
      default:
        return await t.get('redeemError.default').toPromise();
    }
  }
}
