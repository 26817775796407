import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-inc-modal-header',
  templateUrl: './inc-modal-header.component.html',
  styleUrls: ['./inc-modal-header.component.scss'],
})
export class IncModalHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;

  constructor() { }

  ngOnInit() {}

}
