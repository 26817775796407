import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IonItemSliding} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {EventManagementService} from '../../event-management.service';
import {SubProduct, Ticket} from '../../event-management.model';
import {EventManagementSyncService} from '../../event-management-sync.service';
import {RedemptionService} from '../../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {SystemService} from '../../../../../../../shared-libs/lib-core/src/services/system.service';
import {UIFactoryService, UIType} from '../../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {StorageService} from '../../../../../../../shared-libs/lib-core/src/services/storage.service';
import {HttpCachedClient} from '../../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';

@Component({
  selector: 'app-event-sub-products-modal',
  templateUrl: './event-management-tickets-subproduct.component.html',
  styleUrls: ['./event-management-tickets-subproduct.component.scss'],
})
export class EventManagementTicketsSubproductComponent implements OnInit {

  @Input() public ticket: Ticket; // contains the subproducts to be redeemed

  public redeemedList: Array<SubProduct> = [];
  public unredeemList: Array<SubProduct> = [];
  public subproducts: Array<SubProduct> = [];
  public isFetching = false;

  constructor(
    private router: Router,
    private uiFactory: UIFactoryService,
    private redeemService: RedemptionService,
    private systemService: SystemService,
    private httpClient: HttpCachedClient,
    private storageService: StorageService,
    private syncService: EventManagementSyncService,
    private eventManagementService: EventManagementService,
    public t: TranslateService
  ) {
  }

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    this.isFetching = true;

    for (const subproduct in this.ticket.subproducts) {
      if (this.ticket.subproducts.hasOwnProperty(subproduct)) {
        this.subproducts.push(this.ticket.subproducts[subproduct]);
      }
    }

    await this.compareWithStorage();

    this.isFetching = false;
  }

  private setSubproductStatus(subproducts: Array<SubProduct>) {
    subproducts.forEach(subproduct => {
      if (subproduct.attr_model_is_redeemed === 1) {
        this.redeemedList.push(subproduct);
      } else {
        this.unredeemList.push(subproduct);
      }
    });
  }

  private async compareWithStorage() {
    const syncedQueue = await this.syncService.getTicketsForCurrentSystem() ?? [];

    if (!syncedQueue.length) {
      this.setSubproductStatus(this.subproducts);

      return;
    }

    this.subproducts.forEach(subproduct => {
      const exists = syncedQueue.find(product =>
        product.voucherCode === this.ticket.voucherCode &&
        product.attributeKey === subproduct.attributes_model);

      if (exists) {
        if (exists.action === 'redeem') {
          this.redeemedList.push(subproduct);
        } else {
          this.unredeemList.push(subproduct);
        }
      } else {
        if (subproduct.attr_model_is_redeemed === 1) {
          this.redeemedList.push(subproduct);
        } else {
          this.unredeemList.push(subproduct);
        }
      }
    });
  }

  public async onAddTicketToSyncStorage(slider: IonItemSliding, subProduct: SubProduct) {
    try {
      await this.syncService.updateStorage([{
        action: 'redeem',
        voucherCode: this.ticket.voucherCode,
        attributeKey: subProduct.attributes_model
      }]);

      this.unredeemList = this.unredeemList.filter(u => u !== subProduct);
      this.redeemedList.push(subProduct);

      await this.uiFactory.build(UIType.TOAST, {message: await this.t.get('tickets.subproductRedemptionSuccess').toPromise(), duration: 1500}).then(t => t.present());
    } catch (e) {
      await this.uiFactory.build(UIType.TOAST, {message: await this.t.get('tickets.subproductRedemptionFailure').toPromise(), duration: 1500}).then(t => t.present());
    }

    await slider.close();
  }

  public async onCancelRedemptionTicket(slider: IonItemSliding, subProduct: SubProduct) {
    try {
      await this.syncService.updateStorage([{
        action: 'cancel',
        voucherCode: this.ticket.voucherCode,
        attributeKey: subProduct.attributes_model
      }]);

      this.redeemedList = this.redeemedList.filter(sp => sp !== subProduct);
      this.unredeemList.push(subProduct);

      await this.uiFactory.build(UIType.TOAST, {message: await this.t.get('tickets.subproductCancelSuccess').toPromise(), duration: 1500}).then(t => t.present());
    } catch (e) {
      await this.uiFactory.build(UIType.TOAST, {message: await this.t.get('tickets.subproductCancelFailure').toPromise(), duration: 1500}).then(t => t.present());
    }

    await slider.close();
  }

  public async onNavigateBack() {
    await this.uiFactory.dismissModal();
  }

  public async onCompleteRedeemTicket() {
    try {
      await this.syncService.updateStorage([{
        action: 'redeem',
        voucherCode: this.ticket.voucherCode,
        attributeKey: ''
      }]);

      this.eventManagementService.subproductAdded$.next(this.ticket.voucherCode);

      this.unredeemList = [];
      this.subproducts.forEach(sP => this.redeemedList.push(sP));

      await this.uiFactory.dismissModal();
      await this.uiFactory.build(UIType.TOAST, {message: await this.t.get('common.subproductRedeemed').toPromise(), duration: 1500}).then(t => t.present());
    }catch (e) {
      await this.uiFactory.build(UIType.TOAST, {message: await this.t.get('common.subproductRedeemedFailed').toPromise(), duration: 1500}).then(t => t.present());
    }
  }

  async openSlider(slider: IonItemSliding, side: 'start' | 'end') {
    await slider.open(side);
  }
}
