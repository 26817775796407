import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageKey, StorageService} from './storage.service';
import {BehaviorSubject} from 'rxjs';
import {DefinedSettings} from '../models/common';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private defaultSettings: DefinedSettings = {
    showTicketRedemptions: true,
    darkmode: false,
    language: 'de',
    timeQuickRedemption: '5000',
    timeSingleRedemption: '5000',
    hasSeenIntroduction: false,
    askForCancellations: true,
    showReservationInformation: true,
    lastSearchVouchersValidForMs: '86400000',
    displayRelevantTickets: false,
    offlineMode: false,
    cacheOfflineEvents: false,
    isOffline: false,
  };

  private _settings$: BehaviorSubject<DefinedSettings> = new BehaviorSubject<DefinedSettings>(this.defaultSettings);

  constructor(
    private t: TranslateService,
    private storageService: StorageService) {

    this.loadSettingsFromStorage();

    this._settings$.subscribe(async (settings) => {
      await this.storageService.set(StorageKey.SETTINGS, settings);
    });
  }

  private async loadSettingsFromStorage() {
    const existingSettings = await this.storageService.get<DefinedSettings>(StorageKey.SETTINGS);

    if (existingSettings) {
      this._settings$.next({
        lastSearchVouchersValidForMs: existingSettings.lastSearchVouchersValidForMs ?? this.defaultSettings.lastSearchVouchersValidForMs,
        timeQuickRedemption: existingSettings.timeQuickRedemption ?? this.defaultSettings.timeQuickRedemption,
        language: existingSettings.language ?? this.defaultSettings.language,
        darkmode: existingSettings.darkmode ?? this.defaultSettings.darkmode,
        askForCancellations: existingSettings.askForCancellations ?? this.defaultSettings.askForCancellations,
        timeSingleRedemption: existingSettings.timeSingleRedemption ?? this.defaultSettings.timeSingleRedemption,
        hasSeenIntroduction: existingSettings.hasSeenIntroduction ?? this.defaultSettings.hasSeenIntroduction,
        showTicketRedemptions: existingSettings.showTicketRedemptions ?? this.defaultSettings.showTicketRedemptions,
        showReservationInformation: existingSettings.showReservationInformation ?? this.defaultSettings.showReservationInformation,
        displayRelevantTickets: existingSettings.displayRelevantTickets ?? this.defaultSettings.displayRelevantTickets,
        offlineMode: existingSettings.offlineMode ?? this.defaultSettings.offlineMode,
        cacheOfflineEvents: existingSettings.cacheOfflineEvents ?? this.defaultSettings.cacheOfflineEvents,
        isOffline: existingSettings.isOffline ?? this.defaultSettings.isOffline,
      });
    }
  }

  get settings$(): BehaviorSubject<DefinedSettings> {
    return this._settings$;
  }
}
