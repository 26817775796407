import {Component, Input, OnInit} from '@angular/core';
import {UIFactoryService} from '../../services/ui-factory.service';

@Component({
  selector: 'app-inc-modal-close-button',
  templateUrl: './inc-modal-close-button.component.html',
  styleUrls: ['./inc-modal-close-button.component.scss'],
})
export class IncModalCloseButtonComponent implements OnInit {
  @Input() iconColor = '#000';

  constructor(
    private uiFactoryService: UIFactoryService
  ) { }

  ngOnInit() {}

  public async emit() {
    await this.uiFactoryService.dismissModal();
  }
}
