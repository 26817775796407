import {Component, Input, OnInit} from '@angular/core';
import {PickerController} from '@ionic/angular';
import {Reservation} from '../../../../models/reservation.model';
import {ReservationService} from '../../../../services/reservation.service';
import {UIFactoryService, UIType} from '../../../../services/ui-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {getEventsForReservation, getReservationPickerList} from '../../reservation.functions';
import {ReservationUiHelperService} from '../../reservation.ui-helper.service.ts.service';
import {ScanService} from '../../../../services/scan.service';
import {EventDate} from '../../../../../../../src/app/components/pages/event-management/event-management.model';

@Component({
  selector: 'app-reservation-check-all-overlay',
  templateUrl: './reservation-check-all-overlay.component.html',
  styleUrls: ['./reservation-check-all-overlay.component.scss'],
})
export class ReservationCheckAllOverlayComponent implements OnInit {
  @Input() reservations: Array<Reservation>;
  public selectedEventDateId: number;
  public selectedReservationNr: string;
  public search: string;
  public eventDates: Array<Partial<EventDate>>;

  constructor(
    private pickerController: PickerController,
    private reservationUIHelper: ReservationUiHelperService,
    private reservationService: ReservationService,
    private uiFactory: UIFactoryService,
    private translationService: TranslateService,
    private scanService: ScanService,
  ) {
  }

  ngOnInit() {

  }

  public async createEventPicker() {
    if (!this.selectedReservationNr) {
      this.uiFactory.build(UIType.TOAST, {
        message: 'Bitte wähle zuerst eine Reservierung aus'
      }).then(t => t.present());
      return;
    }

    const events = getEventsForReservation(this.reservations);

    this.pickerController.create({
      mode: 'ios',
      columns: [
        {
          name: 'events',
          options: events
        }
      ],
      buttons: [
        {
          text: await this.translationService.get('common.cancelBtn').toPromise(),
          role: 'cancel'
        },
        {
          text: await this.translationService.get('common.confirmBtn').toPromise(),
          handler: (value) => {
            this.selectedEventDateId = value.events.value;
          }
        }
      ]
    }).then(t => t.present());
  }

  public async createReservationPicker() {
    const reservations = getReservationPickerList(this.reservations);

    this.pickerController.create({
      mode: 'ios',
      columns: [
        {
          name: 'reservations',
          options: reservations
        }
      ],
      buttons: [
        {
          text: await this.translationService.get('common.cancelBtn').toPromise(),
          role: 'cancel'
        },
        {
          text: await this.translationService.get('common.confirmBtn').toPromise(),
          handler: (value) => {
            this.selectedReservationNr = value.reservations.value.reservationNr;
          }
        }
      ]
    }).then(t => t.present());
  }

  public async checkInAll() {
    if (!this.selectedReservationNr || !this.selectedEventDateId) {
      await this.uiFactory.build(UIType.TOAST, {
        icon: 'alert-circle-outline',
        message: await this.translationService.get('common.checkYourInput').toPromise(),
      }).then(t => t.present());
    } else {
      const response = await this.reservationService.updateAllForDateWithReservation(this.selectedReservationNr, this.selectedEventDateId);

      await this.uiFactory.getModalController().dismiss(true);
    }
  }

  getDateById() {
    return this.reservations.find(r => r.eventDateId === this.selectedEventDateId).eventDateStart;
  }

  public async searchCode(search: string) {
    await this.reservationUIHelper.showParticipansForId(search);
  }

  public async scanCode() {
    await this.scanService.scanCode();
  }
}
