import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonItemSliding, PickerController, Platform} from '@ionic/angular';
import {ReservationEventDate, ReservationSequenceDate} from '../../../models/reservation.model';
import {UIFactoryService, UIType} from '../../../services/ui-factory.service';
import {ReservationCheckComponent} from '../reservation-check/reservation-check.component';
import {getPlatform} from '../../../utilities/helper/utils';
import {ReservationService} from '../../../services/reservation.service';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DefinedSettings} from '../../../models/common';
import {HttpCachedClient} from '../../../services/http-cached-client.service';

@Component({
  selector: 'app-inc-reservation-date',
  templateUrl: 'reservation-date.component.html',
  styleUrls: ['reservation-date.component.scss'],
})
export class ReservationDateComponent implements OnInit {
  @ViewChild('dateTime') sTime;

  @Input()
  eventDateId: string;

  @Input()
  settings: DefinedSettings;

  private _dates: Array<ReservationEventDate>;
  public dates: Array<ReservationEventDate>;
  public search: string;
  public timePeriodFilterIsActive = false;
  public form: FormGroup;

  constructor(
    private t: TranslateService,
    private reservationService: ReservationService,
    private uiFactory: UIFactoryService,
    private platform: Platform,
    private pickerController: PickerController,
    private fb: FormBuilder,
    private httpCachedClient: HttpCachedClient,
  ) {
  }

  async ngOnInit() {
    await this.getDates();
  }

  async getDates(){
    this._dates = this.dates = await this.httpCachedClient
      .get(`/api/redeemapp/reservation/eventDate/${this.eventDateId}`
        + (this.settings.displayRelevantTickets ? '' : '?displayAll=true'));
  }

  async openSlider(slider: IonItemSliding, side: 'start' | 'end') {
    await slider.open(side);
  }

  public async showParticipants(sequenceDate: ReservationEventDate) {
    const res = await this.reservationService.findReservationsByEventId(sequenceDate.id);
    await this.uiFactory.build(UIType.MODAL, {
      component: ReservationCheckComponent,
      showBackdrop: true,
      canDismiss: true,
      presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
      componentProps: {
        _reservations$: new BehaviorSubject(res),
        eventDateId: sequenceDate.id,
        start: sequenceDate.start,
        reservationTitle: sequenceDate.name
      }
    });
  }

  public resetFilter() {
    this.timePeriodFilterIsActive = false;
    this.dates = this._dates;
  }

  public async onRefresh(event: any) {
    await this.getDates();
    setTimeout(() => {
      event.target.complete();
    });
  }

  public isOutDated(date: ReservationSequenceDate) {
    return !(Date.parse(date.start) >= Date.now());
  }
}
