import {Component, Input, OnInit} from '@angular/core';
import {FabButtonConfig} from './inc-fab.interface';

@Component({
  selector: 'inc-fab',
  templateUrl: './inc-fab.component.html',
  styleUrls: ['./inc-fab.component.scss'],
})
export class IncFabComponent implements OnInit {

  @Input() config: FabButtonConfig | undefined;

  constructor() { }

  ngOnInit() {}

}
