import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateformatter'
})
export class DateformatterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    try {
      const d = new Date(value);
      if (d.getDay()) {
        if (d.getMinutes() === 0) {
          return d.getDay() + '.' + d.getMonth() + '.' + d.getFullYear() + ': ' + d.getHours() + ' Uhr';
        } else if (d.getMinutes().toString().length === 1) {
          return d.getDay() + '.' + d.getMonth() + '.' + d.getFullYear() + ': ' + d.getHours() + ':' + '0' + d.getMinutes() + ' Uhr';
        } else {
          return d.getDay() + '.' + d.getMonth() + '.' + d.getFullYear() + ': ' + d.getHours() + ':' + d.getMinutes() + ' Uhr';
        }

      } else {
        return 'N/V';
      }
    } catch (e) {
      return value;
    }
  }

}
