import {Pipe, PipeTransform} from '@angular/core';
import {VoucherStatus} from '../../models/voucher.status';

@Pipe({
  name: 'voucherStateBackground'
})
export class VoucherStateBackgroundPipe implements PipeTransform {

  transform(value: VoucherStatus, ...args: unknown[]): unknown {
    switch (value.Status) {
      case 0:
        return '#f68181';
      case 1:
        return value.CurrentAmount === value.InitialAmount._ ? '#aaffaa' : '#ffffaa';
      case 2:
        return '#ffaaaa';
      case 3:
        return '#d4aaff';
      case 6:
        return '#ffaaaa';
      default:
        return '#aaffaa';
    }
  }


}
