import {Component, Input, OnInit} from '@angular/core';
import {RedemptionConfig} from '../../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {VoucherStatus} from '../../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {EventManagementSyncService} from '../../../event-management/event-management-sync.service';
import {CacheService} from '../../../../../../../shared-libs/lib-core/src/services/cache.service';

@Component({
  selector: 'app-redeem-show-offline-voucher',
  templateUrl: './redeem-show-offline-voucher.component.html',
  styleUrls: ['./redeem-show-offline-voucher.component.scss'],
})
export class RedeemShowOfflineVoucherComponent {

  @Input()
  config: RedemptionConfig;

  @Input()
  voucher: Partial<VoucherStatus>;

  constructor(
    private syncService: EventManagementSyncService,
    private cacheService: CacheService,
  ) {
  }


  public async redeemFull(){
    if (this.voucher.Status === 10) {
      await this.syncService.updateStorage([{
        voucherCode: this.voucher.Code,
        attributeKey: '',
        action: 'redeem',
      }]);
      await this.cacheService.changeTicketStatus(this.voucher);
      this.voucher.Status = 9;
    }
  }

  public async unredeemSingleProduct(){
    if (this.voucher.Status === 9){
      await this.syncService.updateStorage([{
        voucherCode: this.voucher.Code,
        attributeKey: '',
        action: 'cancel',
      }]);
      await this.cacheService.changeTicketStatus(this.voucher);
      this.voucher.Status = 10;
    }
  }
}
