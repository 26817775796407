import {AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {EventDate} from '../event-management/event-management.model';
import {RedemptionService} from '../../../../../shared-libs/lib-core/src/services/redemption.service';
import {SystemService} from '../../../../../shared-libs/lib-core/src/services/system.service';
import {UIFactoryService} from '../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {SystemCredential} from '../../../../../shared-libs/lib-core/src/authentication/authentication';
import {AuthenticationService} from '../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';
import {environment} from '../../../../environments/environment';
import {ConnectionService} from '../../../../../shared-libs/lib-core/src/services/connection.service';
import {SwiperComponent} from 'swiper/angular';
import {CacheService} from '../../../../../shared-libs/lib-core/src/services/cache.service';
import {HttpCachedClient} from '../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {UIManagerService} from '../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {SettingsService} from '../../../../../shared-libs/lib-core/src/services/settings.service';
import {VoucherHistory} from '../../../../../shared-libs/lib-core/src/models/voucher';
import {RedemptionConfig} from '../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {DefinedSettings} from '../../../../../shared-libs/lib-core/src/models/common';

@Component({
  selector: 'app-page-voucher-redeem',
  templateUrl: 'main-page.component.html',
  styleUrls: ['main-page.component.scss']
})
export class MainPageComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('swiper') swiper: SwiperComponent;

  public currentSystem: SystemCredential | null;
  public history: Array<VoucherHistory> = [];
  public upcomingEvents: Array<EventDate> = [];
  public config: RedemptionConfig;
  public isOffline: boolean;
  public isLoadingEvents;
  public isLoadingHistory;
  public environment = environment;
  public subscriptions: Subscription[] = [];
  private settings: DefinedSettings;

  public constructor(private authenticationService: AuthenticationService,
                     public connectionService: ConnectionService,
                     private redemptionService: RedemptionService,
                     private changeDetectorRef: ChangeDetectorRef,
                     private translateService: TranslateService,
                     private httpCachedClient: HttpCachedClient,
                     private activatedRoute: ActivatedRoute,
                     private storageService: StorageService,
                     private settingsService: SettingsService,
                     private uiManager: UIManagerService,
                     private uiFactoryService: UIFactoryService,
                     private cacheService: CacheService,
                     public systemService: SystemService,
  ) {
    this.subscriptions.push(
      this.connectionService.isOffline$.subscribe(isOffline => this.isOffline = isOffline),
      this.authenticationService.authenticatedUser.subscribe(currUser => this.currentSystem = currUser),
      this.settingsService.settings$.subscribe(s => this.settings = s)
    );
  }

  ngAfterContentChecked(): void {
  }

  public async ngOnInit() {
    await this.initialize();
  }

  private async fetchConfig() {
    try {
      this.config = await this.httpCachedClient.getRaw<RedemptionConfig>('/api/redeemapp/config');
    } catch (e) {
      console.log('Konfigurationen konnten nicht abgerufen werden');
    }
  }

  private async createLastSearchedCodesView(system: string) {
    this.subscriptions.push(this.cacheService.searchHistory$.subscribe(history => {
      this.history = history;
      this.history = this.history
        .filter(h => h.system === system)
        .slice(this.history.length - 5, this.history.length);
    }));
  }

  private async initialize() {
    this.isLoadingEvents = true;

    this.subscriptions.push(this.systemService.currentSystem$.subscribe(async system => {
      await this.createLastSearchedCodesView(system);
    }));

    await this.createUpcomingEventsView();
    await this.fetchConfig();
    await this.isInitialized();
  }

  private isInitialized() {
    setTimeout(() => {
      this.isLoadingEvents = false;
    }, 250);
  }

  private async createUpcomingEventsView() {
    try {
      if (this.canFetchEvents()) {
        await this.fetchUpcomingEvents();
      }
    } catch (e) {
    }
  }

  private async fetchUpcomingEvents() {
    this.upcomingEvents = await this.httpCachedClient.get('/api/redeemapp/upcomingEvents' + (this.settings.displayRelevantTickets ? '' : '?displayAll=true'), true, true) ?? [];
  }

  private canFetchEvents() {
    return !this.upcomingEvents.length && environment.isTicketEnv;
  }

  ngOnDestroy(): void {
    // TODO what? :D
    this.upcomingEvents = [];
    this.history = [];

    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
