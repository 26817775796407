import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from '../../shared-libs/lib-core/src/guards/authentication-guard.service';
import {LoginComponent} from './components/pages/login/login.component';
import {AppComponent} from './app.component';

const baseRoutes: Routes = [
  {
    path: 'main',
    loadChildren: () => import('./components/pages/main/main-routing.module').then(m => m.MainRoutingModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'contact',
    loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'imprint',
    loadChildren: () => import('./components/pages/imprint/imprint.module').then(m => m.ImprintModule)
  },
  {
    path: 'system',
    loadChildren: () => import('./components/pages/account-system/account-system.module').then(m => m.SystemModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./components/pages/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./components/pages/event-management/event-management.module').then(m => m.EventManagementModule)
  },
  {
    path: 'reservation',
    loadChildren: () => import('../../shared-libs/lib-core/src/modules/reservation/reservation.module').then(m => m.ReservationModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(baseRoutes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
