import {Injectable} from '@angular/core';
import {UIFactoryService, UIType} from '../../services/ui-factory.service';
import {ReservationCheckComponent} from './reservation-check/reservation-check.component';
import {getPlatform} from '../../utilities/helper/utils';
import {BehaviorSubject} from 'rxjs';
import {ReservationService} from '../../services/reservation.service';
import {TranslateService} from '@ngx-translate/core';
import {IonRouterOutlet, Platform} from '@ionic/angular';
import {ScanService} from '../../services/scan.service';
import {ReservationCheckAllComponent} from "./reservation-check/reservation-check-all/reservation-check-all.component";
import {RedemptionService} from "../../services/redemption.service";
import {RESERVATION, TICKET} from "../../models/status/product.constants";


@Injectable({
  providedIn: 'root'
})
export class ReservationUiHelperService {

  constructor(
    private reservationService: ReservationService,
    private translateService: TranslateService,
    private uiFactory: UIFactoryService,
    private platform: Platform,
    private scanService: ScanService,
    private redemptionService: RedemptionService
  ) {
  }

  public async showParticipansForId(reservationId, showNotFoundAlert = true) {
    const participants = await this.reservationService.findReservationsByReservationId(reservationId);
    await this.redemptionService.addCodeToLastSearchedHistory(reservationId, RESERVATION);
    if (participants.length) {
      await this.uiFactory.build(UIType.MODAL, {
        component: ReservationCheckAllComponent,
        showBackdrop: true,
        canDismiss: true,
        backdropDismiss: true,
        breakpoints: [1],
        handle: false,
        initialBreakpoint: 1,
        mode: 'ios',
        presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
        componentProps: {
          reservations: participants,
          reservationNr: reservationId
        }
      });
      return true;
    } else if (showNotFoundAlert) {
      await this.showReservationNotFoundAlert(reservationId);
    }
    return false;
  }

  private async showReservationNotFoundAlert(reservationId: string) {
    this.uiFactory.build(UIType.ALERT, {
      header: await this.translateService.get('reservations.reservationNotFound').toPromise(),
      message: await this.translateService.get('reservations.reservationNotExistStart').toPromise() + ' (' + reservationId + ') \n\r' + await this.translateService.get('reservations.reservationNotExistEnd').toPromise(),
      buttons: [
        {
          text: 'Ok'
        }
      ]
    }).then(t => t.present());
  }

  public async showReservationDialog(existingID?: string) {
    this.uiFactory.build(UIType.ALERT, {
      header: await this.translateService.get('reservations.showParticipants').toPromise(),
      message: await this.translateService.get('reservations.searchModalEnterCode').toPromise(),
      buttons: [
        {
          text: await this.translateService.get('reservations.showParticipants').toPromise(),
          id: 'value',
          handler: value => {
            if (value.reservation !== '') {
              this.showParticipansForId(value.reservation);
              return true;
            } else {
              return false;
            }
          }
        },
        {
          text: await this.translateService.get('common.cancelBtn').toPromise(),
          role: 'cancel',
        },
        {
          text: await this.translateService.get('main.simpleScanning').toPromise(),
          handler: () => {
            this.scanService.scanCode();
          }
        }
      ],
      inputs: [
        {
          name: 'reservation',
          type: 'text',
          value: existingID ?? '',
          placeholder: '00000-00000-00000-00000'
        }
      ]
    }).then(t => t.present());
  }

}
