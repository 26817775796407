import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {IncHeaderComponent} from './components/inc-header/inc-header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VoucherStateBackgroundColorPipe} from './utilities/pipes/voucher.state.background.color.pipe';
import {ObjectSomepropertiesPipe} from './utilities/pipes/object.someproperties.pipe';
import {VoucherCurrencyPipe} from './utilities/pipes/voucher.currency.pipe';
import {VoucherStateColorPipe} from './utilities/pipes/voucher.state.color.pipe';
import {VoucherStatePipe} from './utilities/pipes/voucher.state.pipe';
import {IncModalComponent} from './components/inc-modal/inc-modal.component';
import {IncSystemSwitchComponent} from './components/inc-system-switch/inc-system-switch.component';
import {SystemUrlPipe} from './utilities/pipes/system-url.pipe';
import {IncSkeletonComponent} from './components/inc-skeleton/inc-skeleton.component';
import {DateformatterPipe} from './utilities/pipes/dateformatter.pipe';
import {RedeemStatePipe} from './utilities/pipes/redeem-state.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {IncSpinnerComponent} from './components/inc-spinner/inc-spinner.component';
import {IncOfflineHeaderComponent} from './components/inc-offline-header/inc-offline-header.component';
import {IncNoSearchEntriesComponent} from './components/inc-no-search-entries/inc-no-search-entries.component';
import {IncModalCloseButtonComponent} from './components/inc-modal-close-button/inc-modal-close-button.component';
import {IncRedemptionBottomModalComponent} from './components/inc-redemption-bottom-modal/inc-redemption-bottom-modal.component';
import {IncUpdateIndicatorComponent} from './components/inc-update-indicator/inc-update-indicator.component';
import {IncSlideItemComponent} from './components/inc-slide-item/inc-slide-item.component';
import {IncImprintHeaderComponent} from './components/inc-imprint-header/inc-imprint-header.component';
import {IncInfoButtonComponent} from './components/inc-info-button/inc-info-button.component';
import {IncPopoverContentComponent} from './components/inc-info-button/inc-popover-content/inc-popover-content.component';
import {VoucherTypeIconPipe} from './utilities/pipes/voucher-type-icon.pipe';
import {IncFabComponent} from './components/inc-fab/inc-fab.component';
import {IncFabSingleComponent} from './components/inc-fab-single/inc-fab-single.component';
import {IncScanBarComponent} from './components/inc-scan-bar/inc-scan-bar.component';
import {MainScanCodeModalComponent} from '../../../src/app/components/pages/main/main-scan-code-modal/main-scan-code-modal.component';
import {VoucherStateBackgroundPipe} from './utilities/pipes/voucher-state-background.pipe';
import {IncInformationBarComponent} from './components/inc-information-bar/inc-information-bar.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {IncModalHeaderComponent} from './components/inc-modal-header/inc-modal-header.component';
import {IncDynamicBottomModalComponent} from './components/inc-dynamic-bottom-modal/inc-dynamic-bottom-modal.component';
import {WordSplitterPipe} from './utilities/pipes/word-splitter.pipe';
import {IncProductDisplayComponent} from './components/product-display/product-display.component';
import {SearchModePipe} from './utilities/pipes/search-mode.pipe';
import {IncNoContentComponent} from './components/inc-no-content/inc-no-content.component';
import {CardStatePipe} from './utilities/pipes/card-state.pipe';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {VersionInterceptorService} from './interceptors/version-interceptor.service';

@NgModule({
  declarations: [
    IncHeaderComponent,
    IncRedemptionBottomModalComponent,
    IncUpdateIndicatorComponent,
    IncModalComponent,
    IncSkeletonComponent,
    IncSystemSwitchComponent,
    IncNoSearchEntriesComponent,
    IncModalCloseButtonComponent,
    IncSpinnerComponent,
    IncInfoButtonComponent,
    IncDynamicBottomModalComponent,
    IncOfflineHeaderComponent,
    IncSlideItemComponent,
    IncPopoverContentComponent,
    MainScanCodeModalComponent,
    IncFabComponent,
    IncFabSingleComponent,
    IncProductDisplayComponent,
    IncScanBarComponent,
    IncImprintHeaderComponent,
    IncInformationBarComponent,
    IncModalHeaderComponent,
    VoucherStateBackgroundColorPipe,
    ObjectSomepropertiesPipe,
    VoucherCurrencyPipe,
    VoucherStateColorPipe,
    VoucherStatePipe,
    SystemUrlPipe,
    DateformatterPipe,
    RedeemStatePipe,
    VoucherTypeIconPipe,
    VoucherStateBackgroundPipe,
    WordSplitterPipe,
    SearchModePipe,
    IncNoContentComponent,
    CardStatePipe,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    IncHeaderComponent,
    IncRedemptionBottomModalComponent,
    IncUpdateIndicatorComponent,
    IncModalComponent,
    IncSkeletonComponent,
    IncSystemSwitchComponent,
    IncNoSearchEntriesComponent,
    IncModalCloseButtonComponent,
    IncSpinnerComponent,
    IncInfoButtonComponent,
    IncOfflineHeaderComponent,
    IncSlideItemComponent,
    IncPopoverContentComponent,
    MainScanCodeModalComponent,
    IncFabComponent,
    IncFabSingleComponent,
    IncScanBarComponent,
    IncImprintHeaderComponent,
    IncInformationBarComponent,
    IncModalHeaderComponent,
    IncProductDisplayComponent,
    ObjectSomepropertiesPipe,
    SystemUrlPipe,
    DateformatterPipe,
    RedeemStatePipe,
    ReactiveFormsModule,
    FormsModule,
    VoucherStateBackgroundColorPipe,
    VoucherCurrencyPipe,
    VoucherStateColorPipe,
    CardStatePipe,
    VoucherStatePipe,
    VoucherTypeIconPipe,
    IncDynamicBottomModalComponent,
    VoucherStateBackgroundPipe,
    WordSplitterPipe,
    SearchModePipe,
    IncNoContentComponent,
  ],
  providers: [
    DatePipe,
    AppVersion,
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptorService, multi: true}
  ]
})
export class SharedModule {
}
