import {Injectable, OnDestroy} from '@angular/core';
import {
  ProcessedResponse,
  ProcessedTickets,
  QueueItem,
  StoredSyncEntry,
  Ticket,
  TicketSyncRequestModel
} from './event-management.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SystemService} from '../../../../../shared-libs/lib-core/src/services/system.service';
import {ConnectionService} from '../../../../../shared-libs/lib-core/src/services/connection.service';
import {AuthenticationService} from '../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';
import {HttpCachedClient} from '../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {UIManagerService} from '../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {UIFactoryService, UIType} from '../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {EventManagementQueueComponent} from './event-management-queue/event-management-queue.component';
import {getPlatform} from '../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class EventManagementSyncService implements OnDestroy {

  private static intervalRef: any;

  public FLUSH_RATE = 5;
  private currentSystem: string;
  private systemSubscr: Subscription;
  #flushedTickets$: BehaviorSubject<Array<ProcessedTickets>> = new BehaviorSubject<Array<ProcessedTickets>>([]);

  constructor(
    private storageService: StorageService,
    private httpAccess: HttpCachedClient,
    private settings: SystemService,
    private connectivityService: ConnectionService,
    private systemService: SystemService,
    private authenticationService: AuthenticationService,
    private uiManager: UIManagerService,
    private uiFactory: UIFactoryService,
    private t: TranslateService,
  ) {
    this.systemSubscr = this.systemService.currentSystem$.subscribe(system => this.currentSystem = system);
  }

  public async initializeSynchronisation(schedulingRepeatTime: number) {
    try {
      if (EventManagementSyncService.intervalRef) {
        clearInterval(EventManagementSyncService.intervalRef);
      }
      EventManagementSyncService.intervalRef = setInterval(async () => {
        if (this.connectivityService.checkConnection() && this.authenticationService.authenticatedUser.getValue()) {
          const queue = await this.storageService.get<Array<QueueItem>>(StorageKey.QUEUE);
          if (queue) {
            for (const system of queue) {
              if (system.tickets) {
                await this.synchronize(system.tickets, system.system);
              }
            }
          } else {
            // No elements in Queue
          }
        } else {
          // Not authenticated. No Synchronisation
        }
      }, schedulingRepeatTime);
    } catch (e) {
      // Error: Could not synchronize
    }
  }

  private async saveToHistory(items: ProcessedResponse) {
    const stored = await this.storageService.get<Array<ProcessedTickets>>(StorageKey.REDEMPTIONHISTORY) ?? [];

    items.failed.forEach(item => item.validUntil = new Date().getTime() + 259_200_000);
    items.processed.forEach(item => item.validUntil = new Date().getTime() + 259_200_000);

    await this.storageService.set(StorageKey.REDEMPTIONHISTORY, [...stored, ...items.processed, ...items.failed]);
  }

  public async synchronize(storageItems: Array<StoredSyncEntry>, systemUri: string) {
    const serverResponseTickets = await this.flush(systemUri, storageItems);
    if (serverResponseTickets && (serverResponseTickets.processed.length || serverResponseTickets.failed.length)) {
      const elementsInStorage = await this.storageService.get<Array<QueueItem>>(StorageKey.QUEUE);
      if (elementsInStorage) {
        const newQueue: Array<QueueItem> = [];
        let tempArr: Array<StoredSyncEntry> = [];
        await this.saveToHistory(serverResponseTickets);
        const returnedTickets = serverResponseTickets.failed.concat(serverResponseTickets.processed);
        for (const system of elementsInStorage) {
          for (const ticket of system.tickets) {
            if (!returnedTickets.find(f => f.code === ticket.voucherCode)) {
              tempArr.push(ticket);
            }
          }
          if (tempArr.length) {
            newQueue.push({
              system: system.system,
              tickets: tempArr
            });
          }
          tempArr = [];
        }
        if (newQueue.length) {
          await this.storageService.set(StorageKey.QUEUE, newQueue);
        } else {
          await this.storageService.remove(StorageKey.QUEUE);
        }
      } else {
      }
    }
  }

  public async setEntries(system: string, items: Array<StoredSyncEntry>) {
    return await this.storageService.set(StorageKey.QUEUE, items);
  }

  public async getTicketsForCurrentSystem(): Promise<Array<StoredSyncEntry> | null> {
    return await this.storageService.get<Array<QueueItem>>(StorageKey.QUEUE).then(queue => {
      const queueTickets = queue?.find(q => q.system === this.currentSystem);

      if (queueTickets) {
        return queueTickets.tickets;
      } else {
        return null;
      }
    });
  }

  public async updateStorage(items: Array<StoredSyncEntry>): Promise<any>{
     return await this.storageService.get<Array<QueueItem>>(StorageKey.QUEUE).then((queue) => {
      if (!queue) {
        const obj: Array<QueueItem> = [
          {
            system: this.currentSystem,
            tickets: items
          }
        ];

        this.storageService.set(StorageKey.QUEUE, obj)
          .then(async () => await this.uiManager.voucherRedeemedSuccessfully());
      } else {
        const storedSystem: QueueItem | undefined = queue.find(q => q.system === this.currentSystem);

        if (storedSystem) {
          items.forEach(async (toBeStored) => {
            const exists = storedSystem.tickets.find(s => s.voucherCode === toBeStored.voucherCode && s.attributeKey === toBeStored.attributeKey);

            if (exists) {
              if (exists.action !== toBeStored.action) {
                storedSystem.tickets = storedSystem.tickets.filter(stored => stored !== exists);
              } else {
                await this.uiManager.redemptionFailedWithError(await this.t.get('redeemError.voucherFullyRedeemed').toPromise());
              }
            } else {
              storedSystem.tickets.push(toBeStored);
            }
          });

          this.storageService.set(StorageKey.QUEUE, queue)
            .then(async () => await this.uiManager.voucherRedeemedSuccessfully());
          this.storageService.get(StorageKey.QUEUE).then(q => console.log('Queue has been updated - ', q));
          return true;
        }
      }
    });
  }

  public async intersect(system: string, eventId: number, eventDateId: number, tickets: Array<Ticket>) {
    const stored: Array<any> = await this.storageService.get<any>(StorageKey.QUEUE);

    return stored ? tickets.filter(t => stored.some(s => +s === t.id)) : [];
  }

  public async ticketExists(ticketToExist: string): Promise<StoredSyncEntry | undefined | null> {
    return this.getTicketsForCurrentSystem().then((tickets: Array<StoredSyncEntry>) => {
      if (tickets) {
        return tickets.find(i => i.voucherCode === ticketToExist);
      } else {
        return null;
      }
    });
  }

  public async flush(system: string, itemsToProcess: Array<StoredSyncEntry>): Promise<ProcessedResponse | null> {
    const redeemArr: Array<TicketSyncRequestModel> = [];
    const unredeemArr: Array<TicketSyncRequestModel> = [];
    const processed: Array<ProcessedTickets> = [];
    const failed: Array<ProcessedTickets> = [];

    let res: any = [];

    if (itemsToProcess.length > this.FLUSH_RATE) {
      itemsToProcess = itemsToProcess.slice(0, this.FLUSH_RATE);
    }

    itemsToProcess.forEach(voucher => {
      const obj: TicketSyncRequestModel = {
        code: voucher.voucherCode,
        attributeKey: voucher.attributeKey ?? '',
        comment: '',
        reference: ''
      };

      if (voucher.action === 'redeem') {
        redeemArr.push(obj);
      } else {
        unredeemArr.push(obj);
      }
    });

    try {
      if (redeemArr.length) {
        res = [
          ...res,
          ...await this.httpAccess.post<Array<TicketSyncRequestModel>>('/api/events/tickets/redeem', redeemArr)
        ];
      }

      if (unredeemArr.length) {
        res = [
          ...res,
          ...await this.httpAccess.post<Array<TicketSyncRequestModel>>('/api/events/tickets/unredeem', unredeemArr)
        ];
      }
      res.forEach(item => {
        item.success ? processed.push(item) : failed.push(item);
      });

      this.flushedTickets$.next([...processed, ...failed]);
      if (processed.length || failed.length){
        await this.uiManager.showToast(
          await this.t.get('tickets.synchronized', {succeeded: processed.length, failed: failed.length}).toPromise(),
          'information',
          {
            buttons: [{
              text: await this.t.get('common.details').toPromise(),
              handler: async () => {
                return this.uiFactory.build(UIType.MODAL, {
                  component: EventManagementQueueComponent,
                  canDismiss: true,
                  breakpoints: [0.50, 0.85, 1],
                  initialBreakpoint: 0.85,
                  mode: 'ios',
                }).then(t => t.present());
              }
            }],
            duration: 5000,
          });
      }
      return {
        processed,
        failed
      };
    } catch (e) {
      return null;
    }
  }

  public get flushedTickets$(): BehaviorSubject<Array<ProcessedTickets>> {
    return this.#flushedTickets$;
  }

  ngOnDestroy(): void {
    this.systemSubscr.unsubscribe();
  }
}
