import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {IncPopoverContentComponent} from './inc-popover-content/inc-popover-content.component';
import {UIFactoryService, UIType} from '../../services/ui-factory.service';
import {TranslateService} from '@ngx-translate/core';

export interface InformationConfig {
  title: string;
  subTitle: string;
  content: string;
}

@Component({
  selector: 'app-inc-info-button',
  templateUrl: './inc-info-button.component.html',
  styleUrls: ['./inc-info-button.component.scss'],
})
export class IncInfoButtonComponent implements OnInit {

  public appUseInformation: Array<InformationConfig> = [];

  constructor(
    private popoverController: PopoverController,
    private uiFactory: UIFactoryService,
    private translateService: TranslateService
  ) {
  }

  async ngOnInit() {
    await this.initializeInformationSetings();
  }

  private async initializeInformationSetings() {
    this.appUseInformation = [
      {
        title: await this.translateService.get('informationModal.scanCodeTitle').toPromise(),
        subTitle: await this.translateService.get('informationModal.scanCodeSubTitle').toPromise(),
        content: await this.translateService.get('informationModal.scanCodeContent').toPromise(),
      },
      {
        title: await this.translateService.get('informationModal.permanentScanTitle').toPromise(),
        subTitle: await this.translateService.get('informationModal.permanentScanSubTitle').toPromise(),
        content: await this.translateService.get('informationModal.permanentScanContent').toPromise(),
      },
      {
        title: await this.translateService.get('informationModal.manualScanTitle').toPromise(),
        subTitle: await this.translateService.get('informationModal.manualScanSubTitle').toPromise(),
        content: await this.translateService.get('informationModal.manualScanContent').toPromise(),
      },
      {
        title: await this.translateService.get('informationModal.quickRedemptionTitle').toPromise(),
        subTitle: await this.translateService.get('informationModal.quickRedemptionSubTitle').toPromise(),
        content: await this.translateService.get('informationModal.quickRedemptionContent').toPromise(),
      }
    ];
  }

  private async create() {
    await this.uiFactory.build(UIType.MODAL, {
      component: IncPopoverContentComponent,
      mode: 'ios',
      showBackdrop: true,
      canDismiss: true,
      backdropDismiss: true,
      componentProps: {
        infoConfig: this.appUseInformation
      }
    });
  }

  async showInfo() {
    await this.create();
  }
}
