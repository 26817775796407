import {Component, OnInit} from '@angular/core';
import {SystemService} from '../../../../../shared-libs/lib-core/src/services/system.service';
import {UIFactoryService, UIType} from '../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';
import {environment} from '../../../../environments/environment';
import {Environment} from '../../../../../shared-libs/lib-core/src/models/environment';
import {ConnectionService} from '../../../../../shared-libs/lib-core/src/services/connection.service';
import {HttpCachedClient} from '../../../../../shared-libs/lib-core/src/services/http-cached-client.service';

export interface ContactData {
  signature?: string;
  passbook?: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  private contactData: ContactData;
  public environment: Environment = environment;
  public signature: string[] = [];
  public passbook: string[] = [];
  public isLoading = false;

  constructor(
    private httpCachedClient: HttpCachedClient,
    private storageService: StorageService,
    private systemService: SystemService,
    private uiFactoryService: UIFactoryService,
    private translationService: TranslateService,
    public settings: SystemService,
    public connectionService: ConnectionService,
  ) {
  }

  async ngOnInit() {
    await this.fetchContactInformation();
  }

  /**
   * Reworked, sth changed
   * @private
   */
  private async fetchContactInformation() {
    try {
      if (this.connectionService.isOffline$.getValue()) {
        this.contactData = await this.httpCachedClient.get('/api/redeemapp/contact', true, true);

        if (!this.contactData) {
          await this.showNoContactInformation();

          return;
        }
      } else {
        await this.updateContactData(true);
      }
    } catch (e) {
      await this.showNoContactInformation();
    }
  }

  private async updateContactData(checkCache?: boolean) {
    try {
      if (checkCache) {
        this.contactData = await this.httpCachedClient.get<ContactData>('/api/redeemapp/contact', true,  true);
      }

      if (!this.contactData) {
        this.contactData = {
          signature: await this.translationService.get('common.noContactInformation').toPromise()
        };

        return;
      }

      if (this.contactData.signature) {
        this.signature = this.contactData.signature.split(/\r?\n/);
      }

      if (this.contactData.passbook) {
        this.passbook = this.contactData.passbook.split(/\r?\n/);
      }
    } catch (e) {
    }
  }

  async refresh(event: any) {
    this.isLoading = true;
    await this.updateContactData(false);

    setTimeout(() => {
      this.isLoading = false;
      event.target.complete();
    }, 500);
  }

  private async showNoContactInformation() {
    await this.uiFactoryService.build(UIType.TOAST, {
      message: await this.translationService.get('common.noContactInformation').toPromise(),
      duration: 2000
    }).then(t => t.present);
  }
}
