import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CARD, LOYALITY, TICKET} from '../../../../../../../shared-libs/lib-core/src/models/status/product.constants';
import {VoucherStatus} from '../../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {AuthenticationService} from '../../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {calculateVoucherAmount} from '../../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {RedemptionService} from '../../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {Subscription} from 'rxjs';

export enum ProductType {
  VOUCHER = 'VOUCHER',
  TICKET = 'TICKET',
  CARD = 'CARD',
  LOYALITY = 'LOYALITY'
}

@Component({
  selector: 'app-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.scss'],
})
export class ProductDisplayComponent implements OnInit {

  @Input()
  product: VoucherStatus | Partial<VoucherStatus>;

  ProductType = ProductType;
  mappedType: ProductType;
  displayAmount: number;
  voucherSubscription: Subscription;

  constructor(
    public authService: AuthenticationService,
  ) {
  }

  private mapType(product: VoucherStatus | Partial<VoucherStatus>) {
    switch (product.ArticleType) {
      case TICKET: {
        this.mappedType = ProductType.TICKET;
        break;
      }
      case CARD: {
        this.mappedType = ProductType.CARD;
        break;
      }
      case LOYALITY: {
        this.mappedType = ProductType.LOYALITY;
        break;
      }
      default: {
        this.mappedType = ProductType.VOUCHER;
        break;
      }
    }
  }

  ngOnInit(): void {
    if (this.product) {
      this.displayAmount = this.product.CurrentAmount;
      this.mapType(this.product);
    }
  }
}
