import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {AppIcon} from '../../../../../../shared-libs/lib-core/src/models/app-icon';

@Component({
  selector: 'app-account-system-add-system',
  templateUrl: './account-system-add-system.component.html',
  styleUrls: ['./account-system-add-system.component.scss'],
})
export class AccountSystemAddSystemComponent implements OnInit {

  public formGroup: FormGroup;
  public hide = true;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private router: Router,
    private t: TranslateService
  ) {
    this.formGroup = this.fb.group(
      {
        host: ['', Validators.required],
        terminalID: ['', Validators.required],
        terminalPassword: ['', Validators.required]
      }
    );
  }

  ngOnInit() {
  }

  public async addSystem() {
    if (this.formGroup.valid) {
      try {
        const loader = await this.uiManager.showLoader('Login...');

        const loginResponseRequest = await this.authenticationService.authenticate(this.formGroup.value);

        if (!loginResponseRequest) {
          await loader.dismiss();

          await this.showFailureDialog();

          return;
        }

        await loader.dismiss();
        await this.uiFactory.dismissModal();

        const dialog = await this.showSuccessDialog();
        await dialog.present();

        setTimeout(async () => {
          await dialog.dismiss();
          await this.router.navigate(['main']);
        }, 2500);

      } catch (e) {
        await this.showFailureDialog();
      }
    } else {
      await this.showFailureDialog();
    }
  }

  private async showFailureDialog() {
    this.uiFactory.build(UIType.TOAST, {
      message: await this.t.get('login.wrongCredentials').toPromise(),
      icon: 'alert-circle-outline',
      position: 'top',
      duration: 2500
    }).then(t => t.present());
  }

  private async showSuccessDialog() {
    return await this.uiFactory.build(UIType.TOAST, {
      message: await this.t.get('accountSystem.systemAddedText').toPromise(),
      duration: 2000,
      icon: AppIcon.checkmark
    });
  }
}
