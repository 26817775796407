import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-inc-skeleton',
  templateUrl: './inc-skeleton.component.html',
  styleUrls: ['./inc-skeleton.component.scss'],
})
export class IncSkeletonComponent implements OnInit {

  @Input() headline = false;
  @Input() avatar = false;
  @Input() thumbnail = false;
  @Input() card = false;
  @Input() voucher = false;
  @Input() skeletonItems = 10;

  public skeletonIterator: Array<number>[] = [];

  constructor() {
  }

  ngOnInit() {
    this.fillSkeleton();
  }

  private fillSkeleton() {
    this.skeletonIterator = Array(this.skeletonItems).fill(this.skeletonItems);
  }

}
