import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Reservation} from '../../../models/reservation.model';
import {IonItemSliding, Platform} from '@ionic/angular';
import {ReservationService} from '../../../services/reservation.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {UIFactoryService, UIType} from '../../../services/ui-factory.service';
import {SettingsService} from '../../../services/settings.service';
import {DefinedSettings} from '../../../models/common';
import {
  ReservationCheckAllOverlayComponent
} from './reservation-check-all-overlay/reservation-check-all-overlay.component';
import {TranslateService} from '@ngx-translate/core';
import {getPlatform} from '../../../utilities/helper/utils';
import {ReservationCheckDetailsComponent} from './reservation-check-details/reservation-check-details.component';

@Component({
  selector: 'app-inc-reservation-check',
  templateUrl: 'reservation-check.component.html',
  styleUrls: ['reservation-check.component.scss'],
})
export class ReservationCheckComponent implements OnInit, OnDestroy {

  public get startDate(): string {
    return new Date(this.start).toLocaleDateString();
  }

  constructor(
    private reservationService: ReservationService,
    private settingsService: SettingsService,
    private uiFactory: UIFactoryService,
    private translationService: TranslateService,
    private platform: Platform,
  ) {
    this.subscriptions.push(this.settingsService.settings$.subscribe(settings => this.settings = settings));
  }

  @Input() public reservationTitle;

  @Input() public start: string;
  public readonly _reservations$: BehaviorSubject<Array<Reservation>>;

  public reservations: Array<Reservation> = [];
  public filteredReservations: Array<Reservation> = [];
  public search: string;
  public reservationId = null;
  public eventDateId = null;
  public checked = 0;
  public ratio = 0;
  public subscriptions: Subscription[] = [];
  public isEmptySearchResult = false;
  public settings: DefinedSettings;

  ngOnInit() {
    this.subscriptions.push(this._reservations$.subscribe(reservations => {
      this.reservations = reservations;
      this.checked = 0;
      this.reservations.forEach(r => {
        if (r.checked) {
          this.checked += 1;
        }

        if (!r.dob || r.dob[0] === '-') {
          r.dob = null;
        } else {
          r.dob = new Date(r.dob);
        }
      });

      if (this.reservations.length === 0) {
        this.ratio = 0;
      } else {
        this.ratio = (this.checked / reservations.length) * 100;
      }
    }));
    this.filteredReservations = [...this.reservations];
  }


  public async handleCheck(slider: IonItemSliding, reservation: Reservation) {
    await slider.close();
    const res = await this.reservationService.updateReservationStatus(reservation);

    if (res) {
      this.uiFactory.build(UIType.TOAST, {
        message: res.checked ?
          await this.translationService.get('reservations.checkInSuccessful').toPromise() :
          await this.translationService.get('reservations.checkOutSuccessful').toPromise()
      }).then(t => t.present());

      this.subscriptions.push(this._reservations$
        .pipe(take(1))
        .subscribe(reservations => {
          const checkedReservation = reservations.find(id => id.id === res.id);
          checkedReservation.checked = !checkedReservation.checked;

          // move to last position
          if (checkedReservation.checked) {
            this._reservations$.next([...reservations.filter(r => checkedReservation.id !== r.id), checkedReservation]);
          } else {
            this._reservations$.next([checkedReservation, ...reservations.filter(r => checkedReservation.id !== r.id)]);
          }
        }));
    }
  }

  public getDisplayData(reservation): string {
    const ownerData = reservation?.ownerData;
    let displayData = '';
    if (ownerData) {
      for (const key of ownerData) {
        if (key.value.length > 15) {
          displayData += key.value.substr(0, 15) + '... ';
        } else {
          displayData += key.value + ' ';
        }
      }
    } else {
      if (reservation.firstName && reservation.lastName) {
        displayData = reservation.firstName + ' ' + reservation.lastName;
      }
    }
    return displayData;
  }

  public async openSlider(slider: IonItemSliding, side: 'start' | 'end') {
    await slider.open(side);
  }

  public async showReservationDetail(reservation: Reservation, slider?: IonItemSliding) {
    if (slider) {
      await slider.close();
    }

    this.uiFactory.build(UIType.MODAL, {
      component: ReservationCheckDetailsComponent,
      canDismiss: true,
      showBackdrop: true,
      backdropDismiss: true,
      mode: 'ios',
      cssClass: 'inc-modal',
      presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
      componentProps: {
        reservationId: reservation.id,
      },
    }).then(t => t.present());
  }

  filterReservations(query: string) {
    this.filteredReservations = this.reservations.filter(reservation =>
      reservation.firstName.toLowerCase().includes(query.toLowerCase()) ||
      reservation.lastName.toLowerCase().includes(query.toLowerCase()) ||
      reservation.reservationNr.toLowerCase().includes(query.toLowerCase()) ||
      reservation.customer.customerStatus.customerStatusName.toLowerCase().includes(query.toLowerCase())
    );
    this.isEmptySearchResult = !this.filteredReservations.length && this.search !== '';
  }

  private async retrieveReservations(): Promise<Array<Reservation>> {
    if (this.reservationId) {
      // get by reservationId
      return await this.reservationService.findReservationsByReservationId(this.reservationId);
    } else {
      // get by eventId
      return await this.reservationService.findReservationsByEventId(this.eventDateId);
    }
  }

  public async openMultiCheckinModal() {
    this.subscriptions.push(this._reservations$
      .pipe(take(1))
      .subscribe(async reservations => {
        const modal = await this.uiFactory.build(UIType.MODAL, {
          component: ReservationCheckAllOverlayComponent,
          canDismiss: true,
          backdropDismiss: true,
          cssClass: 'inc-modal-auto',
          breakpoints: [0, 0.4, 0.6],
          initialBreakpoint: 0.4,
          componentProps: {
            reservations: reservations.filter(r => !r.checked),
            // if selectedReservationId is set, user searched for specific id, pre-set the reservationID
            selectedReservationNr: this.reservationId,
            // if not set, user navigated via sequence -> date, pre-set the date
            selectedEventDateId: !this.reservationId ? this.reservations[0].eventDateId : null
          }
        });

        modal.onDidDismiss().then(async res => {
          if (res.data) {
            this._reservations$.next(await this.retrieveReservations());
          }
        });
      }));
  }

  public isFullRedeemable() {
  }

  public identify(index, item: Reservation) {
    return item.reservationNr;
  }

  public async onRefresh(event: any) {
    this._reservations$.next(await this.retrieveReservations());
    event.target.complete();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
