import {Injectable} from '@angular/core';
import {HttpCachedClient} from './http-cached-client.service';
import {CheckResponse, Reservation, ReservationSequence, ReservationSequenceI} from '../models/reservation.model';
import {SettingsService} from './settings.service';
import {DefinedSettings} from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  private settings: DefinedSettings;

  constructor(
    private httpCachedClient: HttpCachedClient,
    private settingsService: SettingsService) {
    this.settingsService.settings$.subscribe(settings => this.settings = settings);
  }

  public async findEventsWithReservations(): Promise<Array<ReservationSequenceI>> {
    return this.httpCachedClient.get('/api/redeemapp/eventReservation' + (this.settings.displayRelevantTickets ? '' : '?displayAll=true'));
  }

  public async findReservationsByEventId(eventId: string): Promise<Array<Reservation>> {
    return this.httpCachedClient.get(`/api/eventReservation/app/participant?eventDateId=${eventId}&q=sort(%2Bchecked,%2BreservationNr)&addOwnerData=true&addCustomerData=true`);
  }

  public async findReservationsByReservationId(reservationId: string): Promise<Array<Reservation>> {
    return this.httpCachedClient.get(`/api/eventReservation/app/participant?reservationNr=${reservationId}&q=sort(%2Bchecked,%2BreservationNr)&includePurchased&addOwnerData=true`);
  }

  public async updateReservationStatus(reservation: Reservation): Promise<CheckResponse> {
    return this.httpCachedClient.put('/api/eventReservation/participant/check', {
      id: reservation.id,
      checked: !reservation.checked
    });
  }

  public async updateAllForDateWithReservation(reservationNr: string, id: string | number): Promise<any> {
    return this.httpCachedClient.put('/api/eventReservation/app/checkBy', {
      reservation: {reservationNr},
      eventDate: {id}
    });
  }

}
