export enum AppIcon {
  'close' = 'close-outline',
  'ticket' = 'ticket-outline',
  'wallet' = 'wallet-outline',
  'checkmark' = 'checkmark-done-outline',
  'key' = 'key-outline',
  'search' = 'search-outline',
  'barcode' = 'barcode-outline',
  'arrowRight' = 'chevron-forward-outline',
  'arrowDown' = 'chevron-down-outline',
  'information' = 'information-circle-outline',
  'warning' = 'alert-circle-outline',
  'copy' = 'copy-outline'
}
