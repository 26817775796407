import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Camera} from '@capacitor/camera';
import {Platform} from '@ionic/angular';
import {RedemptionConfig} from '../../models/redemptionConfig';
import {MID_SIZE_SCR, TBL_SIZE_SCR} from '../screens/screensizes';
import {UNSUFFICIENT_RIGHTS} from '../../models/status/voucher.status.constants';
import {BUNDLE_ARTICLE} from '../../models/status/product.constants';
import {VoucherStatus} from '../../models/voucher.status';

export const extractSystemShort = (systemUrl: string) => {
  try {
    return systemUrl.slice(8, systemUrl.length).split('.')[0];
  } catch (e) {
    console.warn('could not extract account-system. Returning the originate valid');
    return systemUrl;
  }
};

export const mapDate = (data: string) => {
  try {
    const date = new Date(data);
    const d = date.getDay().toString().length === 1 ? '0' + date.getDay() : date.getDay();
    const m = date.getMonth().toString().length === 1 ? '0' + date.getMonth() : date.getMonth();
    const y = date.getFullYear();

    return d + '.' + m + '.' + y.toString().slice(-2);
  } catch (e) {
    console.warn('could not map date to format DD.MM.YY, returning originate');

    return data;
  }
};

export const replaceHTMLChars = (str: string) => {
  const htmlReplaceRegex = /(<([^>]+)>)/ig;

  return str.replace(htmlReplaceRegex, ' ');
};

export const switchToStatusMode = async (style: keyof typeof Style) => {
  if (Capacitor.isPluginAvailable('StatusBar')) {
    await StatusBar.setStyle({style: Style[style]});
  }
};

export const checkForCameraPermission = async () => {
  const cameraPermission = await Camera.checkPermissions();

  if (cameraPermission.camera !== 'granted') {
    await Camera.requestPermissions(
      {permissions: ['camera']});
  } else {
    // good to go
  }
};

export const getPlatform = (platform: Platform) => {
  return platform.is('iphone') ||
    platform.is('ipad') ||
    platform.is('mobileweb') &&
    !platform.is('android');
};

export const getInitModalBreakpoint = (height) => {
  if (height > TBL_SIZE_SCR) {
    return 0.67;
  }
  if (height > MID_SIZE_SCR) {
    return 0.85;
  } else {
    return 1;
  }
};

export const notRequired = (c: RedemptionConfig, req: Array<keyof RedemptionConfig>) => {
  return !(req.every(toBeSet => c[toBeSet] === true));
};

export const unsufficientRights = () => {
  return {
    Error: {
      ErrorCode: UNSUFFICIENT_RIGHTS,
      ErrorDescription: ''
    }
  };
};

export const calculateVoucherAmount = (product: VoucherStatus) => {
  // if (product.ArticleType === BUNDLE_ARTICLE) { // product is typeof bundle
  //   if (!product.RedemptionUnits || product.RedemptionUnits?.length === 0) {
  //     /**
  //      * primarily as user should not see balances of units he cannot/should not redeem, nor see
  //      * product has no units to be redeemed (e.g. user does not have access to those -> show 0)
  //      */
  //     return 0;
  //   } else if (product.RedemptionUnits.length && product.ArticleType === BUNDLE_ARTICLE) {
  //     /**
  //      * product has from the users point of view redeemable units -> show the acc. sum
  //      */
  //     return product.RedemptionUnits.reduce((sum, current) => sum + +current.RedUnitAmount, 0);
  //   }
  // }

  return product.CurrentAmount;
};

export const mapToRedemptionUnitArray = (voucher: VoucherStatus) => {
  const redemptionUnits = voucher.RedemptionUnits;

  if (redemptionUnits && !Array.isArray(redemptionUnits)) {
    voucher.RedemptionUnits = [];

    Object.keys(redemptionUnits).forEach(obj => {
      if (voucher) {
        voucher.RedemptionUnits.push(redemptionUnits[obj]);
      }
    });
  }

  return voucher;
};
