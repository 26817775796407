import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'incertCurrency'
})
export class VoucherCurrencyPipe implements PipeTransform {

  public transform(value: any, ...args: any[]): any {
    value = parseFloat(value).toFixed(2);

    const currency = args[0];

    if (!currency) {
      throw new Error('No currency value given!');
    }

    if (currency === 'CHF') {
      return value + ' Fr';
    }
    if (currency === 'EUR') {
      return value + ' €';
    }
    if (currency === 'USD') {
      return value + ' $';
    }

    return value;
  }
}
