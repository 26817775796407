import {AttributeValue} from '../../../../../shared-libs/lib-core/src/models/common';


export interface StoredSyncEntry {
  voucherCode: string;
  attributeKey: string;
  action?: 'redeem' | 'cancel';
  // comment: string;
  // reference: string;
}

export interface TicketSyncRequestModel {
  code: string;
  attributeKey?: string;
  comment: string;
  reference: string;
}

export interface TicketAction {
  action: 'redeem' | 'cancel';
  code: string;
}

export interface SubProduct {
  opa_quantity: number;
  opa_name: number;
  attr_model_is_redeemed: number;
  attributes_model: string;
  redeemable: string;
  opa_red_quantit: number;
  orders_products_sub_id: string;
  products_name: string;
  products_price: string;
  final_price: string;
  products_quantity: string;
  products_tax: string;
}

export interface Event {
  id: number;
  description: string;
  image: string | null;
  imageLink: string | null;
  quota: number;
  duration: number;
  externalId: number;
  takeAwayEventSequence: boolean;
  redemptionPeriodEnabled: boolean;
  categoryId: number;
  saleEnd: string;
  address: string;
  productSeasonId: number;
  redemptionPeriodAfter: number;
  redemptionPeriodBefore: number;
  name: string;
  start: string;
  location: string;
  saleStart: string;
  categoryLink: string | null;
  notSaleableOnline: boolean;
  waitingListEnabled: boolean;
  automaticallyRedeemAfterPurchase: boolean;
  reservable?: boolean;
  eventDates?: Array<EventDate>;
}

export type TicketType = 18 | 23;

export interface Ticket {
  id: number;
  orderProduct: {
    orderProductAttributes: Array<AttributeValue>;
    type: number;
  };
  voucherCode: string;
  curVoucherStatusId: number;
  textFor: string;
  owner: TicketUser;
  customer: TicketUser;
  subproducts: any;
  ownerData?: Array<{label: string, value: any}>;
}

export interface TicketUser {
  firstName: string;
  lastName: string;
}

export interface EventDate {
  id: number;
  address: string;
  quota: number;
  available: number;
  duration: number;
  categoryId: number;
  externalId: number;
  productSeasonId: number;
  saleStart: string;
  name: string;
  assignedCustomersStatus: number;
  exclusivePurchaseMinQuota: number;
  end: string;
  redemptionPeriodStart: string;
  state: string;
  waitingListEnabled: number;
  start: string;
  categoryLink: string;
  saleEnd: string;
  location: string;
  description: string;
  eventSequenceId: number;
  redemptionPeriodEnd: string;
  notSaleableOnline: boolean;
  redemptionPeriodAfter: boolean;
  redemptionPeriodBefore: boolean;
  sold?: number;
  slotStart?: Date;
}

export interface FavouriteEvent {
  event: EventSequence;
  dateId?: number;
  eventDateBegin?: string;
}

export interface StoredFavourite {
  system: string;
  eventId: number;
  dateId?: number;
  eventDateBegin?: string;
}

export type SynchronisationState = 'success' | 'pending' | 'failed';

export enum SynchronizationState {
  SUCCESS = 'success',
  PENDING = 'pending',
  FAILED = 'failed'
}

export interface ProcessedTickets {
  success: string;
  action?: string;
  errors: string;
  code: string;
  speakingName: string;
  attributeKey?: string;
  validUntil: number;
}

export interface QueueItem {
  system: string;
  tickets: Array<StoredSyncEntry>;
}

export interface ProcessedResponse {
  processed: ProcessedTickets[];
  failed: ProcessedTickets[];
}

export interface EventSequence {
  id: number;
  name: string;
  dateCount: number;
}
