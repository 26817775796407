import {Injectable, OnDestroy} from '@angular/core';
import {UnredeemOrderStatus} from '../models/order.status';
import {UIFactoryService} from './ui-factory.service';
import {UIManagerService} from './ui-manager.service';
import {HttpCachedClient} from './http-cached-client.service';
import {RedemptionService} from './redemption.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UnredemtionService {

  constructor(
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private httpCachedClient: HttpCachedClient,
    private redemptionService: RedemptionService) {
  }
  public async unredeemProduct(voucherCode: string) {
    try {
      const loader = await this.uiManager.showLoader('Wird aufgehoben...');

      const response: UnredeemOrderStatus = await this.httpCachedClient.post('/api/redeemapp/voucher/cancel/code', {
        voucherType: '2',
        code: voucherCode
      });

      await loader.dismiss();

      if (response.status === 'OK') {
        await this.uiManager.showToast('Aufhebung erfolgreich', 'checkmark');
        return true;
      } else {
        await this.uiManager.showToast('Aufhebung fehlgeschlagen', 'information');
      }
    } catch (e) {
      await this.uiManager.showToast('Aufhebung fehlgeschlagen', 'information');
    }
    return false;
  }
}
