export let UNSUFFICIENT_RIGHTS = 987987;

export let VOUCHERCODE_NOT_FOUND = 0;
export let VOUCHERCODE_VALID = 1;
export let VOUCHERCODE_CANCELED = 2;
export let VOUCHERCODE_FULLY_REDEEMED = 3;
export let VOUCHERCODE_TYPE_NOT_ALLOWED = 4;
export let VOUCHERCODE_NOT_PAYED_YET = 5;
export let VOUCHERCODE_ALREADY_EXPIRED = 6;
export let VOUCHERCODE_NOT_VALID_FOR_THIS_STATION = 7;
export let VOUCHERCODE_NOT_ACTIVATED = 8;
export let VOUCHERCODE_NOT_VALID_FOR_THIS_RATE_CODE = 9;

export let CUSTOMER_CARD_NOT_ASSIGNED = 20;
export let CUSTOMER_CARD_NOT_ACTIVATED = 21;
export let CUSTOMER_CARD_ACTIVATED = 22;
export let CUSTOMER_CARD_REDEEMED = 23;
export let CUSTOMER_CARD_SOLD = 24;
