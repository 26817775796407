import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IonContent, IonItemSliding, Platform} from '@ionic/angular';
import {EventManagementService} from '../event-management.service';
import {EventDate} from '../event-management.model';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../../../../shared-libs/lib-core/src/services/system.service';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {take} from 'rxjs/operators';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {EventManagementTicketsComponent} from '../event-management-tickets/event-management-tickets.component';
import {Subscription} from 'rxjs';
import {getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {Event} from '../event-management.model';
import {SettingsService} from '../../../../../../shared-libs/lib-core/src/services/settings.service';
import {DefinedSettings} from '../../../../../../shared-libs/lib-core/src/models/common';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CacheService} from '../../../../../../shared-libs/lib-core/src/services/cache.service';

@Component({
  selector: 'app-event-management-dates',
  templateUrl: './event-management-dates.component.html',
  styleUrls: ['./event-management-dates.component.scss'],
})
export class EventManagementDatesComponent implements OnInit, OnDestroy {
  @Input()
  public eventId: number;

  public _eventDates: Array<EventDate> = [];
  public eventDates: Array<EventDate> = [];

  private settings: DefinedSettings;
  public isFetching = true;
  public filterFrom: string;
  public search = '';
  public currentSystem: string;
  private subscriptions: Subscription[] = [];
  public form: FormGroup;
  public timePeriodFilterIsActive = false;

  constructor(
    public eventManagementService: EventManagementService,
    private fb: FormBuilder,
    private connectionService: ConnectionService,
    private httpCachedClient: HttpCachedClient,
    private uiFactoryService: UIFactoryService,
    private systemService: SystemService,
    private uiFactory: UIFactoryService,
    private route: ActivatedRoute,
    public t: TranslateService,
    private platform: Platform,
    private settingsService: SettingsService,
    private cacheService: CacheService,
  ) {
    this.subscriptions.push(this.route.paramMap.subscribe(paramMap => this.eventId = Number(paramMap.get('eventId'))));
    this.subscriptions.push(this.systemService.currentSystem$.subscribe(system => this.currentSystem = system));
    this.subscriptions.push(this.settingsService.settings$.subscribe(settings => this.settings = settings));
  }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      startTime: [''],
      endTime: [''],
    });

    this.form.setValidators(form => {
      if (form.value.startTime && form.value.endTime) {
        if (Date.parse(form.value.startTime) <= Date.parse(form.value.endTime)) {
          return null;
        }
      }

      return { invalid: true };
    });

    await this.fetchDatesForEvent(true);

    this.isFetching = false;
  }

  private async fetchDatesForEvent(checkCache?: boolean) {
    try {
      if (!this.connectionService.isOffline$.getValue()) {
        this._eventDates = this.eventDates  = await this.httpCachedClient
          .get<Array<EventDate>>('/api/redeemapp/eventDate/' + this.eventId + (this.settings.displayRelevantTickets ? '' : '?displayAll=true'), checkCache, true);

      } else {
       const events = await this.cacheService.findOfflineVoucher<Array<Event>>('/events');
       events.map(event => {
         if (event.id === this.eventId){
           this._eventDates = this.eventDates = event.eventDates;
         }
       });
      }
    } catch (e) {
      await this.showCouldNotLoadEventDates();
    }
    this.isFetching = false;
  }

  public async addFavouriteEventDate(slidingItem: IonItemSliding, eventDate: EventDate) {
    setTimeout(() => slidingItem.close(), 250);
    await this.eventManagementService.addToFavourites(this.currentSystem, {
      system: this.currentSystem,
      eventId: this.eventId,
      dateId: eventDate.id,
      eventDateBegin: eventDate.start
    }).then(async res => {
      this.uiFactory.build(UIType.TOAST, {
        message: res ?
          await this.t.get('tickets.addToFavouritesAlert').toPromise() :
          await this.t.get('tickets.alreadyInFavourites').toPromise(),
        duration: 1500
      }).then(t => t.present());
    });
  }

  public async onFilter(ionContent: IonContent) {
    if (this.filterFrom) {
      await ionContent.scrollToTop(500);
      this.eventDates = this._eventDates.filter(e => new Date(e.start).getTime() >= (new Date(this.filterFrom).getTime() - 86400000));
    }
  }

  public async showTicketsForDate(eventDate: EventDate) {
    this.uiFactory.build(UIType.MODAL, {
      component: EventManagementTicketsComponent,
      mode: 'ios',
      canDismiss: true,
      presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
      componentProps: {
        eventDateId: eventDate.id,
        eventId: eventDate.eventSequenceId,
        eventTitle: eventDate.name
      }
    }).then(t => t.present());

  }

  public async onRefresh(event: any) {
    this.connectionService.isOffline$
      .pipe(take(1))
      .subscribe(async offline => {
        if (!offline) {
          this.isFetching = true;

          await this.fetchDatesForEvent(false);

          setTimeout(() => event.target.complete());

          this.isFetching = false;
        } else {
          await this.showNoInternetConnectionToast();
        }
      });
  }

  private async showNoInternetConnectionToast() {
    this.uiFactoryService.build(UIType.TOAST, {
      message: await this.t.get('tickets.offlineNoCamera').toPromise(),
      duration: 3500,

    }).then(t => t.present());
  }

  private async showCouldNotLoadEventDates() {
    this.uiFactory.build(UIType.TOAST, {
      message: await this.t.get('ticket.errorCouldNotLoadEvent').toPromise(),
      duration: 1500
    }).then(toast => toast.present());
  }

  public filterAvailableDates(dates: Array<EventDate>) {
    return dates.filter(d => Date.parse(d.end) >= Date.now());
  }

  private filterDatesByDateSpan() {
    const value = this.form.value;
    if (!value) {
      this.eventDates = this._eventDates;
      this.timePeriodFilterIsActive = false;
    } else {
      this.timePeriodFilterIsActive = true;
      this.eventDates = this._eventDates.filter(d => (Date.parse(d.start) >= Date.parse(value.startTime) && Date.parse(d.start) < Date.parse(value.endTime)));
    }
  }

  public resetFilter() {
    this.timePeriodFilterIsActive = false;
    this.eventDates = this._eventDates;
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
