export let NOT_PERMISSION_TO_CHARGE = 998;
export let WRONG_CREDENTIALS = 999;
export let CARDID_NOT_FOUND = 201;
export let CURRENCY_NOT_SUPPORTED = 202;
export let CHARGE_AMOUNT_TOO_LOW = 203;
export let CHARGE_AMOUNT_TOO_HIGH = 204;
export let PAYMENT_TYPE_NOT_ACCEPTED = 205;
export let CARD_IS_NOT_CHARGEABLE = 206;
export let CARD_CHANGE_CANCEL_ERROR = 207;
export let CARD_CHANGE_VALIDATION_ERROR = 208;
