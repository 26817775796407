import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-inc-information-bar',
  templateUrl: './inc-information-bar.component.html',
  styleUrls: ['./inc-information-bar.component.scss'],
})
export class IncInformationBarComponent implements OnInit {

  @Input() message: string;

  constructor() { }

  ngOnInit() {}

}
