import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-inc-spinner',
  templateUrl: './inc-spinner.component.html',
  styleUrls: ['./inc-spinner.component.scss'],
})
export class IncSpinnerComponent implements OnInit {

  @Input() message: string;

  constructor() { }

  ngOnInit() {}


}
