export interface SystemCredential {
  host: string;
  terminalID?: string;
  terminalPassword?: string;
  storeName?: string;
  isActive?: boolean;
  isPortal?: boolean;
}

export const cleanHostValue = (value: string) => {
  if (value.endsWith('/')) {
    value = value.substr(0, value.length - 1);
  }

  if (!value.startsWith('https://')) {
    value = 'https://' + value;
  }
  const adminPaths = ['/admin', '/incertAdmin'];
  for (const path of adminPaths) {
    value = value.replace(path, '');
  }
  return value;
};

