import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordSplitter'
})
export class WordSplitterPipe implements PipeTransform {
  private SPLIT_COUNT = 20;

  transform(value: string, ...args: unknown[]): unknown {
    return value.length > this.SPLIT_COUNT ? value.slice(0, this.SPLIT_COUNT) + '...' : value;
  }
}
