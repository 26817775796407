import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {createTranslateLoader} from '../main/main.module';
import {EventManagementListComponent} from './event-management-list/event-management-list.component';
import {EventManagementDatesComponent} from './event-management-dates/event-management-dates.component';
import {EventManagementRoutingModule} from './event-management.routing.module';
import { ChartsModule } from 'ng2-charts';
import {EventManagementQueueComponent} from './event-management-queue/event-management-queue.component';
import {EventManagementTicketsComponent} from './event-management-tickets/event-management-tickets.component';
import {EventManagementTicketsUtilizationComponent} from './event-management-tickets/event-management-tickets-utilization/event-management-tickets-utilization.component';
import {EventManagementTicketsSubproductComponent} from './event-management-tickets/event-management-tickets-subproduct/event-management-tickets-subproduct.component';
import {EventManagementTicketsModalComponent} from './event-management-tickets/event-management-tickets-modal/event-management-tickets-modal.component';
import {SharedModule} from '../../../../../shared-libs/lib-core/src/shared.module';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from "@angular/cdk/scrolling";

@NgModule({
    declarations: [
        EventManagementListComponent,
        EventManagementDatesComponent,
        EventManagementQueueComponent,
        EventManagementTicketsComponent,
        EventManagementTicketsSubproductComponent,
        EventManagementTicketsModalComponent,
        EventManagementTicketsUtilizationComponent,
    ],
    imports: [
        EventManagementRoutingModule,
        SharedModule,
        ChartsModule,
        CommonModule,
        RouterModule.forChild([]),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        IonicModule,
        ScrollingModule
    ],
  exports: []
})

export class EventManagementModule {
}
