import {
  CARD_CHANGE_CANCEL_ERROR, CARD_CHANGE_VALIDATION_ERROR,
  CARD_IS_NOT_CHARGEABLE,
  CARDID_NOT_FOUND, CHARGE_AMOUNT_TOO_HIGH, CHARGE_AMOUNT_TOO_LOW,
  CURRENCY_NOT_SUPPORTED, NOT_PERMISSION_TO_CHARGE, WRONG_CREDENTIALS
} from '../../models/status/card.activate.error.constants';
import {TranslateService} from '@ngx-translate/core';
import {UNSUFFICIENT_RIGHTS} from '../../models/status/voucher.status.constants';

export class CardActivateErrorMapper {

  public static async getMessage(t: TranslateService, code: number): Promise<string> {
    switch (code) {
      case UNSUFFICIENT_RIGHTS:
        return await t.get('redeemError.unsufficientRights').toPromise();
      case NOT_PERMISSION_TO_CHARGE:
        return await t.get('cardActivateError.noPermissionToCharge').toPromise();
      case WRONG_CREDENTIALS:
        return await t.get('login.wrongCredentials').toPromise();

      case CARDID_NOT_FOUND:
        return await t.get('cardActivateError.cardIDNotFound').toPromise();

      case CURRENCY_NOT_SUPPORTED:
        return await t.get('cardActivateError.currencyNotSupported').toPromise();

      case CHARGE_AMOUNT_TOO_LOW:
        return await t.get('cardActivateError.chargeAmountTooLow').toPromise();

      case CHARGE_AMOUNT_TOO_HIGH:
        return await t.get('cardActivateError.chargeAmountTooHigh').toPromise();

      case CARD_IS_NOT_CHARGEABLE:
        return await t.get('cardActivateError.cardIsNotChargeable').toPromise();

      case CARD_CHANGE_VALIDATION_ERROR:
        return await t.get('cardActivateError.cardChangeValidationError').toPromise();

      case CARD_CHANGE_CANCEL_ERROR:
        return await t.get('cardActivateError.cardChangeCancelError').toPromise();

      default:
        return await t.get('cardActivateError.default').toPromise();
    }
  }
}
