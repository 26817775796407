import {Component, Input, OnInit} from '@angular/core';
import {EventDate} from '../../event-management.model';

@Component({
  selector: 'app-event-management-info-modal',
  templateUrl: './event-management-tickets-modal.component.html',
  styleUrls: ['./event-management-tickets-modal.component.scss'],
})
export class EventManagementTicketsModalComponent implements OnInit {
  @Input() eventDate: EventDate;

  constructor() { }

  ngOnInit() {
  }

}
