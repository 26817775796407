import {Environment} from '../../shared-libs/lib-core/src/models/environment';

export const environment: Environment = {
  appIdentifier: 'at.incert.mobile',
  uri: 'https://devp3tzb.myincert.com',
  production: false,
  imageFolder: 'incert',
  name: 'incert',
  appName: 'incert',
  isTicketEnv: true,
  isSystemEnv: true,
};
