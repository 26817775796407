import { Pipe, PipeTransform } from '@angular/core';
import {SearchMode} from '../../models/common';

@Pipe({
  name: 'searchMode'
})
export class SearchModePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    switch (value) {
      case SearchMode.CODE: {
        return 'main.enterBarcode';
      }
      case SearchMode.ORDER: {
        return 'main.enterOrderNumber';
      }
      case SearchMode.RESERVATION: {
        return 'main.enterReservationNumber';
      }
    }
  }

}
