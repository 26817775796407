import {Component, Input, OnInit} from '@angular/core';
import {Reservation} from '../../../../models/reservation.model';
import {HttpCachedClient} from '../../../../services/http-cached-client.service';

@Component({
  selector: 'app-reservation-check-details',
  templateUrl: './reservation-check-details.component.html',
  styleUrls: ['./reservation-check-details.component.scss'],
})
export class ReservationCheckDetailsComponent implements OnInit {

  @Input()
  public reservationId: number;
  public reservation: Reservation;

  constructor(
    private httpClient: HttpCachedClient,
  ) {
  }

  public get customerKeys() {
    return Object.keys(this.reservation.customer);
  }

  async ngOnInit() {
    this.reservation = (await this.httpClient.getRaw<Array<Reservation>>(`/api/eventReservation/app/participant/details/${this.reservationId}?addOwnerData=1&addCustomerData=1`))[0];
  }

  public getDisplayData(ownerData): string {
    let displayData = '';

    if (ownerData.value.length > 15) {
      displayData = ownerData.value.substr(0, 15) + '... ';
    } else {
      displayData = ownerData.value + ' ';
    }

    return displayData;
  }


}
