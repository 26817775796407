import {Injectable, OnDestroy} from '@angular/core';
import {CustomerCard, VoucherChargeRange, VoucherStatus} from '../models/voucher.status';
import {SystemService} from './system.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {BarcodeScannerOptions} from '@ionic-native/barcode-scanner';
import {StatusSortedOrders} from '../models/order.status';
import {StorageKey, StorageService} from './storage.service';
import {ConnectionService} from './connection.service';
import {ORDER} from '../models/status/product.constants';
import {CacheService} from './cache.service';
import {HttpCachedClient} from './http-cached-client.service';
import {CameraNotSupportedError, CameraUnavailableError} from '../errors/camera-unavailable.error';
import {ScanningAbortedError} from '../errors/scanning-aborted-error';
import {OrderNotFoundError} from '../errors/order-not-found-error';
import {VoucherNotFoundError} from '../errors/voucher-not-found-error';
import {ChargeCardResponse, RedemptionResponse, VoucherHistory} from '../models/voucher';
import {
  checkForCameraPermission,
  mapToRedemptionUnitArray,
  notRequired,
  unsufficientRights
} from '../utilities/helper/utils';
import {RedemptionConfig} from '../models/redemptionConfig';
import {SettingsService} from './settings.service';
import {DefinedSettings} from '../models/common';
import {Camera} from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class RedemptionService implements OnDestroy {

  #voucher: BehaviorSubject<VoucherStatus | null> = new BehaviorSubject<VoucherStatus | null>(null);

  private scannerOptions: BarcodeScannerOptions = {};
  private settings: DefinedSettings = null;
  private currentSystem: string;
  private readonly subscriptions: Subscription[];

  public constructor(private storageService: StorageService,
                     private httpCachedClient: HttpCachedClient,
                     private systemService: SystemService,
                     private connectionService: ConnectionService,
                     private cacheService: CacheService,
                     private settingsService: SettingsService,
                     private barcodeScanner: BarcodeScanner) {
    this.subscriptions = [
      this.systemService.currentSystem$.subscribe(system => this.currentSystem = system),
      this.settingsService.settings$.subscribe(settings => this.settings = settings)
    ];
  }

  public get voucher$(): Subject<VoucherStatus | null> {
    return this.#voucher;
  }

  public async prepareScanner() {
    this.scannerOptions = {
      showTorchButton: true,
      resultDisplayDuration: 0,
      showFlipCameraButton: true,
    };

    await checkForCameraPermission();
  }

  public async addCodeToLastSearchedHistory(voucherCode: string, type: string) {
    const history = await this.storageService.get<VoucherHistory[]>(StorageKey.HISTORY) ?? [];

    const historyForSystem = history.filter(h => h.system === this.currentSystem);

    if (!historyForSystem.find(t => t.voucherCode === voucherCode)) {
      if (historyForSystem.length >= 5) {
        historyForSystem.pop();
      }

      const newEntry: VoucherHistory = {
        validUntil: new Date().getTime() + +this.settings.lastSearchVouchersValidForMs,
        system: this.currentSystem,
        voucherCode,
        type
      };

      historyForSystem.unshift(newEntry);

      this.cacheService.searchHistory$.next(historyForSystem);
    } else {
      // code is already stored
    }
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async redeemFull(config: RedemptionConfig, voucher: VoucherStatus, comment?: string): Promise<RedemptionResponse> {
    if (notRequired(config, ['FullyRedeemPermission'])) return unsufficientRights();

    if (voucher.RedemptionUnits) {
      if (voucher.RedemptionUnits.length > 0) {
        return await this.redeemFullRedemptionUnitArticle(config, voucher, comment);
      }
    }

    const payload: any = {
      Code: voucher.Code,
      Currency: voucher.Currency,
      Amount: voucher.CurrentAmount,
      Comment: comment,
    };
    return await this.httpCachedClient.post('/api/redeemapp/voucher/redeem', payload);
  }

  /**
   * @throws {VoucherNotFoundError, HTTPRequestError}
   */
  public async redeemFullWithCode(config: RedemptionConfig, voucherCode: string, comment?: string): Promise<RedemptionResponse> {
    if (notRequired(config, ['FullyRedeemPermission'])) return unsufficientRights();

    const voucher = await this.fetchVoucher(voucherCode);
    return await this.redeemFull(config, voucher, comment);
  }

  /**
   * @WriteTest()
   * When redeeming Redemption units fully, add 'all' and add no attributeID (inclusiveID)
   * @throws {HTTPRequestError}
   */
  public async redeemFullRedemptionUnitArticle(config: RedemptionConfig, voucher: VoucherStatus, comment?: string): Promise<RedemptionResponse> {
    if (notRequired(config, ['FullyRedeemPermission'])) return unsufficientRights();

    const payload: any = {
      Code: voucher.Code,
      Currency: voucher.Currency,
      Amount: 'all',
      InclusiveId: 'all',
      Comment: comment,
    };

    return await this.httpCachedClient.post('/api/redeemapp/voucher/redeem', payload);
  }

  /**
   * Redeem a redemption unit with the amount of the unit plus the attributeID (inclusiveID)
   * @throws {HTTPRequestError}
   */
  public async redeemOneRedemptionUnit(config: RedemptionConfig, voucher: VoucherStatus, redemptionUnit: any, comment?: string): Promise<RedemptionResponse> {
    if (notRequired(config, ['RedemptionUnitRedeemPermission'])) return unsufficientRights();

    const payload: any = {
      Code: voucher.Code,
      Currency: voucher.Currency,
      Amount: redemptionUnit.RedUnitAmount,
      Comment: comment,
      InclusiveId: redemptionUnit.RedUnitID
    };

    return await this.httpCachedClient.post('/api/redeemapp/voucher/redeem', payload);
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async redeemPartly(config: RedemptionConfig, voucher: VoucherStatus, amount, comment?: string, email?: string): Promise<RedemptionResponse> {
    if (notRequired(config, ['PartialRedeemPermission'])) return unsufficientRights();

    const payload: any = {
      Code: voucher.Code,
      Currency: voucher.Currency,
      Amount: amount,
      Comment: comment,
      EmailAddress: email
    };

    return await this.httpCachedClient.post('/api/redeemapp/voucher/redeem', payload);
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async redeemPartlyWithCode(config: RedemptionConfig, voucherCode: string, amount, comment?: string, email?: string): Promise<RedemptionResponse> {
    if (notRequired(config, ['PartialRedeemPermission'])) return unsufficientRights();

    const voucher = await this.fetchVoucher(voucherCode);
    return await this.redeemPartly(config, voucher, amount, comment, email);
  }

  /**
   * @throws {HTTPRequestError}
   */
  public async chargeVoucherCard(config: RedemptionConfig, card: VoucherStatus, parameters: { Code: string, Currency: string }): Promise<ChargeCardResponse> {
    if (notRequired(config, ['ChargeCardPermission'])) return unsufficientRights();

    parameters.Code = card.Code;
    parameters.Currency = card.Currency;

    return await this.httpCachedClient.post('/api/redeemapp/card/charge', parameters);
  }

  /**
   * @throws {OrderNotFoundError, HTTPRequestError}
   */
  public async fetchArticlesWithOrderNumber(orderId: string): Promise<StatusSortedOrders> {
    const order = await this.httpCachedClient.getRaw<StatusSortedOrders>('/api/invoiceorders/' + orderId);

    if (order.redeemed.length || order.unredeemed.length) {
      await this.addCodeToLastSearchedHistory(orderId, ORDER);
      return order;
    } else {
      throw new OrderNotFoundError('No order was found');
    }
  }

  public async fetchCardDetails(code): Promise<CustomerCard> {
    return await this.httpCachedClient.getRaw('/api/invoiceorders/card/charge-details/' + code);
  }

  /**
   * TODO verify in tests
   * @throws {VoucherNotFoundError, HTTPRequestError}
   */
  public async fetchVoucher(code: string): Promise<VoucherStatus> {
    let voucher = await this.httpCachedClient.getRaw<VoucherStatus>('/api/redeemapp/voucher/status/' + code.trim() + '?addOwnerData=true' );
    // map object redemption units to array
    voucher = mapToRedemptionUnitArray(voucher);

    this.voucher$.next(voucher);

    if (voucher.Status) {
      await this.addCodeToLastSearchedHistory(voucher.Code, voucher.ArticleType);
      return voucher;
    } else {
      throw new VoucherNotFoundError('Invalid Voucher');
    }
  }

  /**
   * TODO does not belong here
   * @throws {CameraUnavailableError, ScanningAbortedError, CameraNotSupportedError}
   */
  public async startScanning() {
    await this.prepareScanner();

    let scanResult = {text: '', cancelled: false};

    const permission = await Camera.checkPermissions();

    if (permission.camera !== 'granted') {
      throw new CameraUnavailableError('Camera permissions are not set');
    }

    try {
      scanResult = await this.barcodeScanner.scan(this.scannerOptions);
    } catch (e) {
      throw new CameraNotSupportedError('Device does not support camera');
    }

    if (scanResult.cancelled) {
      throw new ScanningAbortedError('Scanning was aborted by the user');
    }

    if (scanResult.text) {
      return scanResult.text;
    }
  }

  public async getChargeValue(code: string): Promise<VoucherChargeRange> {
    return await this.httpCachedClient.get('/api/redeemapp/card/charge-range/' + code);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
