import {Component, Input, OnInit} from '@angular/core';
import {DynamicBottomModal} from '../../models/common';

@Component({
  selector: 'inc-dynamic-bottom-modal',
  templateUrl: './inc-dynamic-bottom-modal.component.html',
  styleUrls: ['./inc-dynamic-bottom-modal.component.scss'],
})
export class IncDynamicBottomModalComponent implements OnInit {

  @Input() config: DynamicBottomModal;

  constructor() { }

  ngOnInit() {

  }
}
