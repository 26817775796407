import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-inc-no-search-entries',
  templateUrl: './inc-no-search-entries.component.html',
  styleUrls: ['./inc-no-search-entries.component.scss'],
})
export class IncNoSearchEntriesComponent implements OnInit {

  @Input() searchResult: string;
  @Output() emittableObsv: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) { }

  ngOnInit() {}

  public onResetSearch() {
    this.emittableObsv.emit('');
  }

}
