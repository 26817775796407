import {Injectable} from '@angular/core';
import {UIFactoryService, UIType} from './ui-factory.service';
import {VoucherStatus} from '../models/voucher.status';
import {
  IncRedemptionBottomModalComponent
} from '../components/inc-redemption-bottom-modal/inc-redemption-bottom-modal.component';
import {RedeemErrorMapper} from '../utilities/mapper/redeem.error.mapper';
import {TranslateService} from '@ngx-translate/core';
import {RedemptionError} from '../models/voucher';
import {AppIcon} from '../models/app-icon';
import {ToastOptions} from '@ionic/core';
import {CachedConfig, CacheService} from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class UIManagerService {

  constructor(
    private uiFactoryService: UIFactoryService,
    private translateService: TranslateService,
    private cacheService: CacheService,
  ) {
  }

  public async showLoader(message: string) {
    const loader = await this.uiFactoryService.build(UIType.LOADR, {message});

    await loader.present();

    return loader;
  }

  public async showFastRedemptionSuccess(voucher: VoucherStatus) {
    return await this.uiFactoryService.build(UIType.MODAL, {
      component: IncRedemptionBottomModalComponent,
      cssClass: 'inc-modal-auto',
      mode: 'ios',
      backdropDismiss: false,
      breakpoints: [0.50, 0.85, 1],
      initialBreakpoint: 0.85,
      componentProps: {
        title: await this.translateService.get('main-detail.main.result.successTitle').toPromise(),
        subTitle: await this.translateService.get('main-detail.main.result.default').toPromise(),
        voucherCode: voucher.Code,
        orderCode: voucher.OrderID,
        success: true,
        additionalInformation: voucher
      }
    });
  }

  public async showFastRedemptionError(error: RedemptionError, voucher: VoucherStatus | null) {
    return await this.uiFactoryService.build(UIType.MODAL, {
      component: IncRedemptionBottomModalComponent,
      cssClass: 'inc-modal-auto',
      showBackdrop: true,
      backdropDismiss: false,
      mode: 'ios',
      breakpoints: [0.50, 0.85, 1],
      initialBreakpoint: 0.85,
      componentProps: {
        title: await this.translateService.get('ui.redemptionFailed').toPromise(),
        subTitle: await RedeemErrorMapper.getMessage(this.translateService, error?.ErrorCode),
        voucherCode: voucher.Code,
        orderCode: voucher.OrderID,
        success: false,
        additionalInformation: voucher
      }
    });
  }

  public async showVoucherNotFoundError(offline?: boolean) {
    const cachedConfig = offline ? await this.cacheService.findOfflineVoucher<CachedConfig>('/cachedConfig') : null;

    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('main.showDetailErrorCaption').toPromise(),
      subHeader: await this.translateService.get('main.showDetailNotFound').toPromise(),
      message: offline ? await this.translateService.get('main.lastCachedAt', {date: new Date(cachedConfig.cachedAt).toLocaleString() }).toPromise() : '',
      buttons: [
        {
          text: await this.translateService.get('main.showDetailbuttonText').toPromise(),
          handler: () => {
            alert.dismiss({value: 'dismiss'});
          }
        }
      ],
      cssClass: 'alertCustomCss'
    });

    await alert.present();

    return alert;
  }

  public async showUnknownError() {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('main.showDetailErrorCaption').toPromise(),
      subHeader: await this.translateService.get('main.showDetailUnknownError').toPromise(),
      buttons: [
        await this.translateService.get('main.showDetailbuttonText').toPromise(),
      ],
      cssClass: 'alertCustomCss'

    });

    await alert.present();
  }

  public async showNoInternetConnectionToast() {
    this.uiFactoryService.build(UIType.TOAST, {
      message: await this.translateService.get('tickets.offlineNoCamera').toPromise(),
      duration: 3500,

    }).then(t => t.present());
  }

  public async showHTTPConnectionErrorWhileFetching() {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('ui.noConnectionCodeRequest').toPromise(),
      subHeader: await this.translateService.get('ui.noConnectionCodeRequestText').toPromise(),
      buttons: [
        await this.translateService.get('main.showDetailbuttonText').toPromise(),
      ],
      cssClass: 'alertCustomCss'

    });

    await alert.present();
  }

  public async showInvalidBarcodeAlert() {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('main.enterValidBarcodeTitle').toPromise(),
      subHeader: await this.translateService.get('main.enterValidBarcodeSubTitle').toPromise(),
      buttons: [await this.translateService.get('main.enterValidBarcodeOkButton').toPromise()],
      cssClass: 'alertCustomCss'
    });

    await alert.present();
  }

  public async showNoOrderFound(orderId: number | string) {
    return await this.uiFactoryService.build(UIType.ALERT, {
      mode: 'ios',
      header: await this.translateService.get('ui.orderNotFound').toPromise(),
      message: await this.translateService.get('ui.orderNotExist').toPromise() + ' (' + orderId + ')\n\r' + await this.translateService.get('reservations.reservationNotExistEnd').toPromise(),
      buttons: [
        {
          text: 'Ok'
        }
      ]
    }).then(t => t.present());
  }

  public async showCameraUnavailable() {
    this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('ui.openCameraFailed').toPromise(),
      message: await this.translateService.get('ui.openCameraFailedText').toPromise(),
      buttons: [{text: 'Ok'}]
    }).then(t => t.present());
  }

  public async showDeviceNotSupportingCamera() {
    this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('ui.openCameraFailed').toPromise(),
      message: await this.translateService.get('ui.openCameraNotSupported').toPromise(),
      buttons: [{text: 'Ok'}]
    }).then(t => t.present());
  }

  public async showTicketFoundForEvent(voucherCode: string, duration: number) {
    this.uiFactoryService.build(UIType.TOAST, {
      message: await this.translateService.get('tickets.ticketFound').toPromise() + ': ' + voucherCode,
      icon: 'checkmark-outline',
      duration
    }).then(t => t.present());
  }

  public async showTicketNotFoundForEvent(voucherCode: string, duration: number) {
    this.uiFactoryService.build(UIType.TOAST, {
      message: await this.translateService.get('tickets.ticketNotFound').toPromise() + ': ' + voucherCode,
      icon: 'alert-circle-outline',
      duration
    }).then(t => t.present());
  }

  public async showConfirmDialog(header: string, message: string, confirmText: string, cancelText: string, confirmFn: () => any, cancelFn?: () => any): Promise<HTMLIonAlertElement> {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header,
      message,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => cancelFn() ?? null
        }, {
          text: confirmText,
          id: 'confirm-button',
          handler: () => confirmFn()
        }
      ]
    });

    await alert.present();

    return alert;
  }

  public async showToast(message: string, icon: keyof typeof AppIcon, optionalOptions?: ToastOptions) {
    const toast = await this.uiFactoryService.build(UIType.TOAST, {
      message,
      icon: AppIcon[icon],
      duration: 1500,
      ...optionalOptions
    });

    await toast.present();
    return toast;
  }

  public async unableToFetchVoucherCode() {
    this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('ui.requestError').toPromise(),
      message: await this.translateService.get('ui.requestErrorText').toPromise(),
      buttons: [{text: 'Ok'}]
    }).then(t => t.present());
  }

  public async redemptionFailedWithError(errorMsg: string) {
    const alert = await this.uiFactoryService.build(UIType.ALERT, {
      header: await this.translateService.get('ui.redemptionError').toPromise(),
      message: errorMsg,
      buttons: [{text: 'OK'}]
    });

    await alert.present();
    return alert;
  }

  public redemptionFailedWithErrorToast(errorMsg: string, icon?: keyof typeof AppIcon) {
    this.uiFactoryService.build(UIType.TOAST, {
      message: errorMsg,
      duration: 3000,
      icon
    }).then(t => t.present());
  }

  public async voucherRedeemedSuccessfully() {
    this.uiFactoryService.build(UIType.TOAST, {
      message: await this.translateService.get('ui.redemptionSuccess').toPromise(),
      duration: 1500,
      icon: AppIcon.checkmark
    }).then(t => t.present());
  }

  public async invalidRedemptionValue() {
    this.uiFactoryService.build(UIType.TOAST, {
      message: await this.translateService.get('ui.invalidAmount').toPromise(),
      duration: 1500
    }).then(t => t.present());
  }

  public async showSwitchedToSystem(system: string) {
    this.uiFactoryService.build(UIType.TOAST, {
      message: (await this.translateService.get('accountSystem.newSystem').toPromise()) + ' ' + system,
      icon: 'repeat-outline',
      duration: 3000
    }).then(t => t.present());
  }

  public async dismiss() {
    await this.uiFactoryService.dismissModal();
  }

}
