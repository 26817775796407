import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {stripeCASVoucher} from '../utilities/helper/voucher.helper';
import {VoucherNotFoundError} from '../errors/voucher-not-found-error';
import {UIFactoryService, UIType} from './ui-factory.service';
import {RedeemOverviewComponent} from '../../../../src/app/components/pages/redeem-overview/redeem-overview.component';
import {getPlatform} from '../utilities/helper/utils';
import {CameraNotSupportedError, CameraUnavailableError} from '../errors/camera-unavailable.error';
import {ScanningAbortedError} from '../errors/scanning-aborted-error';
import {Injectable, Injector} from '@angular/core';
import {ConnectionService} from './connection.service';
import {RedemptionService} from './redemption.service';
import {UIManagerService} from './ui-manager.service';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ReservationUiHelperService} from '../modules/reservation/reservation.ui-helper.service.ts.service';
import {RedemptionConfig} from '../models/redemptionConfig';
import {HttpCachedClient} from './http-cached-client.service';
import {CacheService} from './cache.service';
import {Ticket} from '../../../../src/app/components/pages/event-management/event-management.model';
import {isArray} from 'rxjs/internal-compatibility';
import {RedeemShowOfflineVoucherComponent} from '../../../../src/app/components/pages/redeem-overview/redeem-show/redeem-show-offline-voucher/redeem-show-offline-voucher.component';

@Injectable({
  providedIn: 'root'
})
export class ScanService {

  constructor(
    private connectionService: ConnectionService,
    private redemptionService: RedemptionService,
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private platform: Platform,
    private translateService: TranslateService,
    private httpCachedClient: HttpCachedClient,
    private injector: Injector,
    private cacheService: CacheService,
    // used Injector to fix circular dependency issue with reservationUIHelper :57
  ) {
  }
  public async scanCode(): Promise<void> {
    let config: RedemptionConfig;

    if (this.connectionService.isOffline$.getValue()){
      config = await this.cacheService.findOfflineVoucher<RedemptionConfig>('/config');
    }else{
      config = await this.httpCachedClient.getRaw<RedemptionConfig>('/api/redeemapp/config');
    }

    await this.uiFactory.dismissModal();
    let innerLoader;

    // if (this.connectionService.isOffline$.getValue()) {
    //   await this.uiManager.showNoInternetConnectionToast();
    //   return;
    // }

    await Haptics.impact({style: ImpactStyle.Medium});
    const loader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());

    try {
      setTimeout(async () => {
        await loader.dismiss();
      }, 200);
      let scanResult = await this.redemptionService.startScanning();
      // todo this should be at the lowest possible layer, DRY!
      scanResult = stripeCASVoucher(scanResult);

      const reservationPrefix = config.EventReservation?.reservationNrPrefix;
      const handleReservation = async () => {
        const reservationUIHelper = this.injector.get(ReservationUiHelperService);
        await loader.dismiss();
        const result = await reservationUIHelper.showParticipansForId(scanResult, false);
        if (!result) {
          throw new VoucherNotFoundError('');
        }
      };

      if (reservationPrefix && scanResult.startsWith(reservationPrefix)) {
        await handleReservation();
      } else {
        try {
          if (scanResult) {
            if (this.connectionService.isOffline$.getValue()){
              innerLoader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());
              const ticket: Partial<Ticket> | Partial<Ticket>[] = await this.cacheService.findOfflineVoucher('/' + scanResult);
              await innerLoader.dismiss();
              if (!isArray(ticket)) {
                this.uiFactory.build(UIType.MODAL, {
                  component: RedeemShowOfflineVoucherComponent,
                  componentProps: {
                    config,
                    voucher: {
                      Code: ticket.voucherCode,
                      Status: ticket.curVoucherStatusId,
                      ArticleType: '18',
                    }
                  },
                  canDismiss: true,
                  showBackdrop: true,
                  backdropDismiss: true,
                  breakpoints: [1],
                  handle: false,
                  initialBreakpoint: 1,
                  presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
                  mode: 'ios',
                }).then(m => m.present());

              } else return;
            } else {
              innerLoader = await this.uiManager.showLoader(await this.translateService.get('common.loading').toPromise());
              const voucher = await this.redemptionService.fetchVoucher(scanResult);
              await innerLoader.dismiss();
              this.redemptionService.voucher$.next(voucher);
              await this.redemptionService.addCodeToLastSearchedHistory(voucher.Code, voucher.ArticleType);
              this.uiFactory.build(UIType.MODAL, {
                component: RedeemOverviewComponent,
                canDismiss: true,
                showBackdrop: true,
                backdropDismiss: true,
                breakpoints: [1],
                handle: false,
                initialBreakpoint: 1,
                presentingElement: getPlatform(this.platform) ? await this.uiFactory.getModalController().getTop() : null,
                mode: 'ios',
              }).then(m => m.present());
            }
          }
        } catch (e) {
          if (e instanceof VoucherNotFoundError) {
            await handleReservation();
          } else {
            throw e;
          }
        }
      }
    } catch (e) {
      if (e instanceof CameraUnavailableError) {
        await this.uiManager.showCameraUnavailable();
      } else if (e instanceof CameraNotSupportedError) {
        await this.uiManager.showDeviceNotSupportingCamera();
      } else if (e instanceof ScanningAbortedError) {
        // do nothing
      } else await this.uiManager.showVoucherNotFoundError(this.connectionService.isOffline$.getValue());
    } finally {
      if (innerLoader) {
        await innerLoader.dismiss();
      }
      await loader.dismiss();
    }
  }
}
