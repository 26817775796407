import {Component, Input, OnInit} from '@angular/core';
import {MenuController} from '@ionic/angular';
import {Router} from '@angular/router';
import {Haptics, ImpactStyle} from '@capacitor/haptics';

@Component({
  selector: 'app-header',
  templateUrl: './inc-header.component.html',
  styleUrls: ['./inc-header.component.scss'],
})
export class IncHeaderComponent implements OnInit {

  @Input() public label = 'inc-header not set';
  @Input() public disableMenu = false;
  @Input() public showBackButton = false;
  @Input() public backgroundColor = '#f6f6f6';
  @Input() public main = false;

  constructor(
    private menuController: MenuController,
    private router: Router
  ) { }

  ngOnInit() {}

  public async pop() {
    await Haptics.impact({style: ImpactStyle.Light});

    await this.router.navigate(['events']);
  }

  async open() {
    await Haptics.impact({style: ImpactStyle.Light});
    await this.menuController.open();
  }
}
