export interface Page {
  title: string;
  component?: any;
  hidden?: boolean;
  onClick?: () => void;
  icon: string;
  route?: string;
  display: DisplayMode;
  cyTag?: string;
}

export interface MenuAction {
  title: string;
  onClick?: () => void;
  icon: string;
  display: keyof typeof DisplayMode;
}

export enum DisplayMode {
  REQUIRE_AUTHENTICATION,
  ALLOW_ANONYMOUS,
}

export enum Route {
  LOGIN = 'login',
  MAIN = 'main',
  RESERVATION = 'reservation',
  EVENTS = 'events',
  SYSTEMS = 'system',
  SETTINGS = 'settings',
  CONTACT = 'contact',
  IMPRINT = 'settings',
}
