import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventManagementService} from '../event-management.service';
import {EventManagementQueueComponent} from '../event-management-queue/event-management-queue.component';
import {EventDate, StoredSyncEntry, Ticket, TicketAction} from '../event-management.model';
import {StorageService} from '../../../../../../shared-libs/lib-core/src/services/storage.service';
import {ConnectionService} from '../../../../../../shared-libs/lib-core/src/services/connection.service';
import {UIFactoryService, UIType} from '../../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {EventManagementTicketsModalComponent} from './event-management-tickets-modal/event-management-tickets-modal.component';
import {Subscription} from 'rxjs';
import {EventManagementSyncService} from '../event-management-sync.service';
import {ActionSheetController, IonContent, IonItemSliding, Platform} from '@ionic/angular';
import {EventManagementTicketsUtilizationComponent} from './event-management-tickets-utilization/event-management-tickets-utilization.component';
import {EventManagementTicketsSubproductComponent} from './event-management-tickets-subproduct/event-management-tickets-subproduct.component';
import {SystemService} from '../../../../../../shared-libs/lib-core/src/services/system.service';
import {SettingsService} from '../../../../../../shared-libs/lib-core/src/services/settings.service';
import {AuthenticationService} from '../../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {BarcodeScannerOptions, BarcodeScanResult} from '@ionic-native/barcode-scanner';
import {RedeemOverviewComponent} from '../../redeem-overview/redeem-overview.component';
import {RedemptionService} from '../../../../../../shared-libs/lib-core/src/services/redemption.service';
import {take} from 'rxjs/operators';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {HttpCachedClient} from '../../../../../../shared-libs/lib-core/src/services/http-cached-client.service';
import {VoucherStatus} from '../../../../../../shared-libs/lib-core/src/models/voucher.status';
import {UIManagerService} from '../../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {RedemptionError} from '../../../../../../shared-libs/lib-core/src/models/voucher';
import {VOUCHERCODE_FULLY_REDEEMED} from '../../../../../../shared-libs/lib-core/src/models/status/voucher.status.constants';
import {checkForCameraPermission, getPlatform} from '../../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {DefinedSettings} from '../../../../../../shared-libs/lib-core/src/models/common';
import {RedemptionConfig} from '../../../../../../shared-libs/lib-core/src/models/redemptionConfig';
import {CacheService} from '../../../../../../shared-libs/lib-core/src/services/cache.service';

enum CameraMode {
  SINGLE,
  REPEAT
}

@Component({
  selector: ' app-event-mangement-show',
  templateUrl: './event-management-tickets.component.html',
  styleUrls: ['./event-management-tickets.component.scss'],
})
export class EventManagementTicketsComponent implements OnInit, OnDestroy {

  @ViewChild('slider') yourList: IonItemSliding;
  @ViewChild(IonContent, {static: false}) ionContent: IonContent;

  @Input() public eventDateId: number;
  @Input() public eventId: number;
  @Input() public eventTitle: string;

  private settings: DefinedSettings;

  public ticketList: Array<Ticket> = [];
  public eventDate: EventDate;
  public ticketListUnredeemed: Array<Ticket> = [];
  public _ticketListUnredeemed: Array<Ticket> = [];
  public ticketListRedeemed: Array<Ticket> = [];
  public _ticketListRedeemed: Array<Ticket> = [];
  public config: RedemptionConfig;
  public subscriptions: Array<Subscription>;
  public ticketsRedeemedCount = 0;
  public showTab = 'redeemable';
  public search = '';
  public isFetching = false;
  public isFullyRedeemed = false;
  public isEmptySearchResult = false;
  public isShowingRedeemedTickets = false;
  public CameraMode = CameraMode;

  public getDisplayData(ownerData): string {
    let displayData = '';

    for (const key of ownerData){
        displayData += key.value + ' ';
    }
    if (displayData.length > 40) {
      displayData = displayData.substr(0, 40) + '... ';
    }
    return displayData;
  }

  public get startDate(): string {
    return this.eventDate?.start ? new Date(this.eventDate.start).toLocaleDateString() : '';
  }


  constructor(
    private eventManagementService: EventManagementService,
    private actionSheetController: ActionSheetController,
    private syncService: EventManagementSyncService,
    private uiFactoryService: UIFactoryService,
    private uiManagerService: UIManagerService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private barcodeScanner: BarcodeScanner,
    private redemptionService: RedemptionService,
    private systemService: SystemService,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private httpCachedClient: HttpCachedClient,
    public t: TranslateService,
    public connectionService: ConnectionService,
    private platform: Platform,
    private cacheService: CacheService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions = [
      this.eventManagementService.subproductAdded$.subscribe(code => this.filterOutSubProductCode(code)),
      this.eventManagementService.ticketAction$.subscribe(code => this.syncTicket(code)),
      this.settingsService.settings$.subscribe(settings => this.settings = settings)
    ];
  }

  async ngOnInit(): Promise<void> {
    this.isFetching = true;
    await this.fetchTickets(this.eventDateId);
    this.isShowingRedeemedTickets = this.settingsService.settings$.getValue().showTicketRedemptions;
    this.isFetching = false;
    if (this.connectionService.isOffline$.getValue()) {
      this.config = await this.cacheService.findOfflineVoucher('/config');
    } else {
      this.config = await this.httpCachedClient.getRaw('/api/redeemapp/config');
    }
  }


  private filterOutSubProductCode(code: string) {
    this.ticketListRedeemed = this._ticketListRedeemed.filter(t => t.voucherCode !== code);
    this.ticketListUnredeemed = this._ticketListUnredeemed.filter(t => t.voucherCode !== code);
  }

  public async downloadTickets() {
    await this.httpCachedClient.storeForOffline(this.eventDateId) ?
      await this.uiManagerService.showToast(this.t.instant('tickets.ticketDownloadSuccess'), 'ticket') :
      await this.uiManagerService.showToast(this.t.instant('tickets.ticketDownloadFailed'), 'ticket' );
  }

  private async fetchEventDate(eventDateId: number) {
    if (!this.connectionService.isOffline$.getValue()) {
      return await this.httpCachedClient.getRaw<EventDate>('/api/events/dates/' + eventDateId);
    } else {
      const cachedDates = await this.cacheService.findOfflineVoucher<Array<EventDate>>('/events');
      return cachedDates.find(date => date.id === eventDateId);
    }
  }

  private async fetchTickets(eventDateId: number) {
    try {
      this.ticketListRedeemed = this._ticketListRedeemed = [];
      this._ticketListUnredeemed = this.ticketListUnredeemed = [];

      if (!this.connectionService.isOffline$.getValue()) {
        this.ticketList = await this.httpCachedClient.get('/api/events/dates/' + eventDateId + '/tickets?addOwnerData=true', false, true);
      } else {
        this.ticketList = await this.cacheService.findOfflineVouchersEventDate(eventDateId);
      }

      this.eventDate = await this.fetchEventDate(eventDateId);

      const queue = await this.syncService.getTicketsForCurrentSystem() ?? [];

      this.ticketList.forEach((ticket) => {
        if (ticket.orderProduct) {
          if (ticket.orderProduct.type === 23) {
            this.synchronizeBundleProduct(ticket, queue);
          } else {
            this.synchronizeStandaloneProduct(ticket, queue);
          }
        }
      });
      this.ticketsRedeemedCount = this.ticketListRedeemed.length;
      this.markForChange();

    } catch (e) {
      console.log(e);
    }
  }

  public onSearch(searchString: string) {
    this.search = searchString;

    if (searchString.length === 0) {
      this.ticketListRedeemed = [...this._ticketListRedeemed];
      this.ticketListUnredeemed = [...this._ticketListUnredeemed];
    } else {
      this.isShowingRedeemedTickets = true;
    }

    this.ticketListRedeemed = this.filterList(this._ticketListRedeemed, searchString);
    this.ticketListUnredeemed = this.filterList(this._ticketListUnredeemed, searchString);
    this.isEmptySearchResult = this.ticketListRedeemed.length === 0 && this.ticketListUnredeemed.length === 0 && searchString.length > 0;
  }

  private filterList(list: Array<Ticket>, s: string) {
    return list.filter(ticket =>
     ticket.voucherCode.toLowerCase().includes(s.toLowerCase()) ||
      ticket.ownerData?.some(acd => acd.value.toLowerCase().includes(s.toLowerCase()))
    );
  }

  private synchronizeBundleProduct(ticket: Ticket, queue: Array<StoredSyncEntry>) {
    let allSubproductsInStorage = false;
    let latestStatus: 'redeem' | 'cancel' | undefined;

    ticket?.subproducts?.forEach(subProduct => {
      if (!ticket.subproducts.hasOwnProperty(subProduct)) {
        return;
      }

      if (queue.length) {
        const productInQueue = queue.find(t => t.attributeKey === ticket.subproducts[subProduct].attributes_model && t.voucherCode === ticket.voucherCode);
        if (productInQueue) {
          if (!latestStatus) {
            latestStatus = productInQueue.action;
          } else {
            if (latestStatus === productInQueue.action) {
              allSubproductsInStorage = true;
            }
          }
        }
      }
    });

    if (allSubproductsInStorage) {
      if (latestStatus === 'redeem') {
        this._ticketListRedeemed.push(ticket);
      } else {
        this._ticketListUnredeemed.push(ticket);
      }
    } else {
      if (ticket.curVoucherStatusId === 9) {
        this._ticketListRedeemed.push(ticket);
      } else {
        this._ticketListUnredeemed.push(ticket);
      }
    }

    this.markForChange();
  }

  public changeTab(event: any){
    this.showTab = event.detail.value;
  }


  private synchronizeStandaloneProduct(ticket: Ticket, queue: Array<StoredSyncEntry>) {
    if (queue.length) {
      const locallyStoredTicket = queue.find(t => t.voucherCode === ticket.voucherCode);

      if (locallyStoredTicket) {
        if (ticket.curVoucherStatusId === 9) {
          if (locallyStoredTicket.action === 'cancel') {
            this._ticketListUnredeemed.push(ticket);
          } else {
            this._ticketListRedeemed.push(ticket);
          }
        }
        this.cdr.detectChanges();
        return;
      }
    }

    if (ticket.curVoucherStatusId === 9) {
      this._ticketListRedeemed.push(ticket);
    } else {
      this._ticketListUnredeemed.push(ticket);
    }
    this.cdr.detectChanges();
  }

  public async showUtilization() {
    if (this.ticketList && this.ticketList.length) {
      return this.uiFactoryService.build(UIType.MODAL, {
        component: EventManagementTicketsUtilizationComponent,
        canDismiss: true,
        mode: 'ios',
        showBackdrop: true,
        backdropDismiss: false,
        componentProps: {
          total: this.ticketList.length,
          current: this.ticketListRedeemed.length,
          event: this.eventDate.name,
          eventDateId: this.eventDateId
        },
        presentingElement: getPlatform(this.platform) ? await this.uiFactoryService.getModalController().getTop() : null,
      }).then(m => m.present());
    } else {
      this.uiFactoryService.build(UIType.TOAST, {
        message: await this.t.get('tickets.utilizationNoTickets').toPromise(),
        duration: 2000
      }).then(t => t.present());
    }
  }

  private markForChange() {
    this.ticketListRedeemed = [...this._ticketListRedeemed];
    this.ticketListUnredeemed = [...this._ticketListUnredeemed];

    this.isFullyRedeemed = this._ticketListUnredeemed.length === 0 && this.ticketList.length > 0;

    if (this.isFullyRedeemed) {
      this.isShowingRedeemedTickets = true;
    }
  }

  public async showQueue() {
    return this.uiFactoryService.build(UIType.MODAL, {
      component: EventManagementQueueComponent,
      presentingElement: getPlatform(this.platform) ? await this.uiFactoryService.getModalController().getTop() : null,
      canDismiss: true,
      mode: 'ios',
      backdropDismiss: false,
      showBackdrop: true
    }).then(t => t.present());
  }

  private async showRedemptionResult(success: boolean, voucher: VoucherStatus, redemptionError?: RedemptionError) {
    if (success) {
      await this.uiManagerService.showFastRedemptionSuccess(voucher);
    } else {
      await this.uiManagerService.showFastRedemptionError(redemptionError, voucher);
    }
  }

  private async updateTicketList(ticket: Ticket) {
    {
      this._ticketListRedeemed = this._ticketListRedeemed.filter(r => r.voucherCode !== ticket.voucherCode);
      this._ticketListUnredeemed.push(ticket);
      this.ticketsRedeemedCount = this._ticketListRedeemed.length;
      await this.syncService.updateStorage([{
        voucherCode: ticket.voucherCode,
        action: 'cancel',
        attributeKey: ''
      }]);

      this.markForChange();
    }
  }

  public async redeemTicketViaSyncService(ticket: Ticket, slider?: IonItemSliding | null) {
    if (this.config && !this.config.FullyRedeemPermission) {
      this.uiFactoryService.build(UIType.TOAST, {
        message: await this.t.get('main.permissionToCancel').toPromise(),
        duration: 2000
      }).then(t => t.present());
    }

    if (slider) {
      await slider.close();
    }

    try {
      if (ticket.orderProduct.type === 23) {
        await this.uiFactoryService.build(UIType.MODAL, {
          canDismiss: true,
          mode: 'ios',
          component: EventManagementTicketsSubproductComponent,
          componentProps: {
            ticket
          }
        }).then(t => t.present());
      } else {
        await this.syncService.updateStorage([({
          voucherCode: ticket.voucherCode,
          action: 'redeem',
          attributeKey: ''
        })]);
      }
      this._ticketListRedeemed.push(ticket);
      this.ticketsRedeemedCount = this._ticketListRedeemed.length;
      this._ticketListUnredeemed = this._ticketListUnredeemed.filter(u => u.voucherCode !== ticket.voucherCode);
      this.markForChange();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async cancelTicket(ticket: Ticket, slider: IonItemSliding) {

    if (this.config && !this.config.CancelRedemptionPermission) {
      this.uiFactoryService.build(UIType.TOAST, {
        message: await this.t.get('main.permissionToCancel').toPromise(),
        duration: 2000
      }).then(t => t.present());

      if (slider) {
        await slider.close();
      }

      return;
    }

    const isLocalStored = await this.syncService.ticketExists(ticket.voucherCode);

    if (isLocalStored) {
      this.ticketListRedeemed = this._ticketListRedeemed = this._ticketListRedeemed.filter(i => i.voucherCode !== ticket.voucherCode);
      await this.syncService.updateStorage([{
        voucherCode: ticket.voucherCode,
        action: 'cancel',
        attributeKey: ''
      }]);
    } else {
      if (this.settingsService.settings$.getValue().askForCancellations) {
        this.uiFactoryService.build(UIType.ALERT, {
          header: await this.t.get('tickets.resetRedemption').toPromise(),
          message: await this.t.get('tickets.resetRedemptionText').toPromise(),
          buttons: [
            {
              text: await this.t.get('common.cancelBtn').toPromise(),
              role: 'cancel'
            },
            {
              text: await this.t.get('common.confirmBtn').toPromise(),
              handler: () => this.updateTicketList(ticket)
            },
          ]
        }).then(alert => alert.present());
      } else {
        await this.updateTicketList(ticket);
      }
    }
  }

  public async onRefresh(event) {
    this.connectionService.isOffline$
      .pipe(take(1))
      .subscribe(async offline => !offline ? await this.fetchTickets(this.eventDateId) : null);

    setTimeout(() => {
      event.target.complete();
    }, 1000);

    this.ionContent.getScrollElement().then(el => el.style.transform = '');
  }

  public async openCamera(mode: CameraMode) {
    await checkForCameraPermission();

    const barcodeScannerOptions: BarcodeScannerOptions = {
      showTorchButton: true,
      resultDisplayDuration: 0,
      showFlipCameraButton: true,
    };

    try {
      const result = await this.barcodeScanner.scan(barcodeScannerOptions);

      if (this.barcodeScanningWasAborted(result)) {
        return;
      }

      const existingTicket = await this.ticketWasNotFoundInTicketList(result);

      if (!existingTicket) {
        this.uiFactoryService.build(UIType.TOAST, {
          message: await this.t.get('tickets.ticketNotFound').toPromise() + ': ' + result.text,
          duration: 2500
        }).then(t => t.present());
        return;
      }

      if (mode === CameraMode.SINGLE) {
        if (existingTicket.orderProduct.type === 23) {
          await this.redeemTicketViaSyncService(existingTicket, null);

          return;
        }

        this.eventManagementService.searchObs$.next(result.text);

        if (this.ticketList.find(t => t.voucherCode === result.text)) {
          this.isShowingRedeemedTickets = true;
          this.onSearch(result.text);
          await this.uiManagerService.showTicketFoundForEvent(result.text, +this.settings.timeSingleRedemption);
        } else {
          await this.uiManagerService.showTicketNotFoundForEvent(result.text, +this.settings.timeSingleRedemption);
        }
      } else {
        if (this._ticketListUnredeemed.find(t => t.voucherCode === existingTicket.voucherCode)) {
          const isInStorage = await this.syncService.ticketExists(existingTicket.voucherCode);
          if (isInStorage && isInStorage.action === 'redeem') {
            await this.showRedemptionResult(true, await this.t.get('tickets.ticketRedemptionSuccessful').toPromise());
            await this.restartPermanentScanningAfterTimeout();
          } else if (!isInStorage || (isInStorage && isInStorage.action === 'cancel')) {
            const res = await this.redeemTicketViaSyncService(existingTicket, null);
            if (res) {
              await this.showRedemptionResult(true, await this.t.get('tickets.ticketRedemptionSuccessful').toPromise());
              await this.restartPermanentScanningAfterTimeout();
            } else {
              await this.showRedemptionResult(false, null, null);
            }
          }
        } else {
          await this.showRedemptionResult(false, await this.t.get('tickets.ticketAlreadyRedeemed').toPromise(), {
            ErrorCode: VOUCHERCODE_FULLY_REDEEMED,
            ErrorDescription: await this.t.get('tickets.ticketAlreadyRedeemed').toPromise()
          });
          await this.restartPermanentScanningAfterTimeout();
        }
      }
    } catch (e) {
      await this.uiManagerService.showUnknownError();
    }
  }

  private async restartPermanentScanningAfterTimeout() {
    setTimeout(async () => await this.openCamera(CameraMode.REPEAT),
      Number(this.settingsService.settings$.getValue().timeQuickRedemption));
  }

  public async showInformation() {
    this.uiFactoryService.build(UIType.MODAL, {
      component: EventManagementTicketsModalComponent,
      canDismiss: true,
      mode: 'ios',
      cssClass: 'inc-modal-lg',
      presentingElement: getPlatform(this.platform) ? await this.uiFactoryService.getModalController().getTop() : null,
      componentProps: {
        eventDate: this.eventDate
      },
    }).then(t => t.present());
  }

  async slide(slider: any, side: 'start' | 'end') {
    if (slider.el.classList.contains('item-sliding-active-slide') || slider.el.classList.contains('item-sliding-active-options-end')) {
      await slider.close();
    } else {
      await slider.open(side);
    }
  }

  async showTicketDetail(ticket: Ticket, slider?: IonItemSliding) {
    if (slider) {
      await slider.close();
    }

    if (this.connectionService.isOffline$.getValue()) {
      this.uiFactoryService.build(UIType.TOAST, {
        message: await this.t.get('tickets.offlineNoCamera').toPromise(),
        duration: 3000
      }).then(t => t.present());
    } else {
      const data: any = await this.httpCachedClient.getRaw(`/api/redeemapp/voucher/status/${ticket.voucherCode}?addOwnerData=true`);

      if (!data.Status) {
        this.uiFactoryService.build(UIType.ALERT, {
          message: 'Ungültiger Gutschein. Bitte wende dich an den Support.',
          buttons: [{text: 'Ok'}]
        }).then(t => t.present());
      }

      this.redemptionService.voucher$.next(data);

      this.uiFactoryService.build(UIType.MODAL, {
        component: RedeemOverviewComponent,
        canDismiss: true,
        showBackdrop: true,
        backdropDismiss: true,
        mode: 'ios',
        cssClass: 'inc-modal',
        presentingElement: getPlatform(this.platform) ? await this.uiFactoryService.getModalController().getTop() : null,
        componentProps: {
          closeAfterAction: true,
          attributeData: ticket.orderProduct.orderProductAttributes ? ticket.orderProduct.orderProductAttributes : null,
        },
      }).then(t => t.present());
    }
  }

  public syncTicket(action: TicketAction) {
    if (action.action === 'redeem') {
      const ticket: Ticket | undefined = this._ticketListUnredeemed.find(t => t.voucherCode === t.voucherCode);

      if (ticket) {
        this._ticketListRedeemed = this.ticketListRedeemed = [...this._ticketListRedeemed, ticket];
      }

      this._ticketListUnredeemed = this.ticketListUnredeemed = this._ticketListUnredeemed.filter(t => t.voucherCode !== action.code);

    } else {
      const ticket: Ticket | undefined = this._ticketListUnredeemed.find(t => t.voucherCode === t.voucherCode);

      if (ticket) {
        this._ticketListRedeemed = this.ticketListRedeemed = [...this._ticketListRedeemed, ticket];
      }
      this._ticketListRedeemed = this.ticketListRedeemed = this._ticketListRedeemed.filter(t => t.voucherCode !== action.code);
    }
  }

  public async fabButtonClicked() {
    await Haptics.impact({style: ImpactStyle.Light});
  }

  private async ticketWasNotFoundInTicketList(result: BarcodeScanResult) {
    return this.ticketList.find(t => t.voucherCode === result.text);
  }

  private barcodeScanningWasAborted(result) {
    return result.cancelled || !result.text;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
