import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {SettingsService} from './settings.service';
import {DefinedSettings} from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService{

  public _internetConnectivity: Observable<boolean> = new Observable<boolean>();
  public isOffline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private settings: DefinedSettings;

  constructor(
    private settingsService: SettingsService,
  ) {
    this.initConnectivityMonitoring();

    this.settingsService.settings$.subscribe(settings => {
      if (settings){
        this.settings = settings;
      }
    });
  }

  public get connectivity() {
    return this._internetConnectivity;
  }

  public checkConnection() {
    if (!this.settings.isOffline){
      if (navigator.onLine) {
        this.isOffline$.next(false);
        return true;
      } else {
        this.isOffline$.next(true);
        return false;
      }
    } else {
      return false;
    }
  }

  private initConnectivityMonitoring() {
    try {
      if (!window || !navigator || !('onLine' in navigator)) {
        return;
      }

      this._internetConnectivity = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      ).pipe(map(() => navigator.onLine));
    } catch (e) {
      this._internetConnectivity.pipe(map(() => false));
    }
  }
}
