import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptorService implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authenticationService.authToken;

    if (token) {
      const modReq = req.clone({
        setHeaders: {Authorization: 'Bearer ' + token}
      });

      return next.handle(modReq)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.Unauthorized){
              this.authenticationService.logout();
              return throwError(error.message);
            }
          })
        );
    } else {
      return next.handle(req);
    }
  }
}
