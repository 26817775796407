import { Pipe, PipeTransform } from '@angular/core';
import {
    VOUCHERCODE_CANCELED, VOUCHERCODE_FULLY_REDEEMED,
    VOUCHERCODE_VALID
} from '../../models/status/voucher.status.constants';

@Pipe(
    {
        name: 'invoucherstatecolor'
    }
)
export class VoucherStateColorPipe implements PipeTransform {

    public transform(value: any, ...args: any[]) {
        switch (value) {

            case VOUCHERCODE_VALID:
                return '#066c00';
            case VOUCHERCODE_FULLY_REDEEMED:
                return '#39D130';
            case VOUCHERCODE_CANCELED:
                return '#cc3749';
            default:
                return '#000000';
        }
    }

}
