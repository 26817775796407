import {Injectable} from '@angular/core';
import {Storage} from '@capacitor/storage';
import * as CryptoJS from 'crypto-js';

export enum StorageKey {
  SYSTEMS = 'ACS',
  HISTORY = 'HSY',
  QUEUE = 'QUE',
  SETTINGS = 'SET',
  FAVOURITES = 'FAV',
  TOKENCRED = 'MCR',
  REDEMPTIONHISTORY = 'RHY',
  CACHE = 'CAC',
  OFFLINE = 'OLT'
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  #s = 'dshj12hd43f';

  public async set<T>(key: StorageKey, data: T) {

    try {
      await Storage.set({key, value: this.encode(JSON.stringify(data))});

      return true;
    } catch (e) {
      return false;
    }
  }

  public async get<T>(key: StorageKey): Promise<T | null> {
    try {
      const decodeData = await Storage.get({key});

      if (decodeData && decodeData.value) {
        return JSON.parse(this.decode(decodeData.value));
      } else {
        return null;
      }
    } catch (e) {}
  }

  public async clearStorage() {
    await Storage.clear();
  }

  public async remove(key: StorageKey) {
    await Storage.remove({key});
  }

  public async keys() {
    return Storage.keys();
  }

  private encode(value: string) {
    return CryptoJS.AES.encrypt(value, this.#s.trim()).toString();
  }

  private decode(value: string) {
    return CryptoJS.AES.decrypt(value, this.#s.trim()).toString(CryptoJS.enc.Utf8);
  }
}
