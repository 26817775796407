export class CameraUnavailableError extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, CameraUnavailableError.prototype);
  }

  printError() {
    return this.message;
  }
}

export class CameraNotSupportedError extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, CameraUnavailableError.prototype);
  }

  printError() {
    return this.message;
  }
}
