import {ComponentRef, Injectable} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {AlertOptions, LoadingOptions, ModalOptions, ToastOptions} from '@ionic/core';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from './settings.service';
import {getPlatform, switchToStatusMode} from '../utilities/helper/utils';

export enum UIType {
  TOAST = 'TOAST',
  MODAL = 'MODAL',
  ALERT = 'ALERT',
  LOADR = 'LOADER',
}

export type FactoryParam = AlertOptions & ModalOptions & ToastOptions & LoadingOptions;

@Injectable({
  providedIn: 'root'
})
export class UIFactoryService {

  constructor(private toastController: ToastController,
              private alertController: AlertController,
              private modalController: ModalController,
              private translateService: TranslateService,
              private settingsService: SettingsService,
              private loadingController: LoadingController,
              private platform: Platform
  ) {
  }

  private static readonly ALERT_CSS_FALLBACK = 'inc-alert';
  private static readonly MODAL_CSS_FALLBACK = 'inc-modal';
  private static readonly TOAST_CSS_FALLBACK = 'inc-toast';
  private static readonly LOADER_CSS_FALLBACK = 'inc-loader';

  public static modalBreakpoints() {
    return [0.25, 0.5, 0.67, 0.75, 1];
  }

  /**
   * Provide generic way of building ui interfaces.
   * Ideally, use the UIManager for shared instances of UI Interfaces
   */
  public async build(type: UIType.ALERT, config: Partial<AlertOptions>): Promise<HTMLIonAlertElement>;
  public async build(type: UIType.MODAL, config: Partial<ModalOptions>): Promise<HTMLIonModalElement>;
  public async build(type: UIType.TOAST, config: Partial<ToastOptions>): Promise<HTMLIonToastElement>;
  public async build(type: UIType.LOADR, config: Partial<LoadingOptions>): Promise<HTMLIonLoadingElement>;
  public async build(type: UIType, config: FactoryParam): Promise<HTMLIonAlertElement |
    HTMLIonModalElement |
    HTMLIonToastElement |
    HTMLIonLoadingElement |
    HTMLIonActionSheetElement> {

    switch (type) {
      case UIType.ALERT: {
        return await this.alertController.create({
          ...config,
          cssClass: config.cssClass ? config.cssClass : UIFactoryService.ALERT_CSS_FALLBACK
        });
      }
      case UIType.MODAL: {
        await switchToStatusMode('Dark');
        const model = await this.modalController.create({
          ...config,
          mode: getPlatform(this.platform) ? 'ios' : 'md',
          cssClass: this.determineCSSClass(config),
        });

        await model.present();
        model.onDidDismiss().then(() => switchToStatusMode('Light'));
        return model;
      }
      case UIType.TOAST: {
        return await this.toastController.create({
          duration: 1500,
          ...config,
          cssClass: config.cssClass ? config.cssClass : UIFactoryService.TOAST_CSS_FALLBACK
        });
      }
      case UIType.LOADR: {
        return await this.loadingController.create({
          ...config,
          cssClass: config.cssClass ? config.cssClass : UIFactoryService.LOADER_CSS_FALLBACK
        });
      }
    }
  }

  private determineCSSClass(config: ModalOptions): string | string[] {
    if (!getPlatform(this.platform)) {
      if (!config.cssClass) {
        return UIFactoryService.MODAL_CSS_FALLBACK;
      } else {
        return config.cssClass;
      }
    } else {
      return UIFactoryService.MODAL_CSS_FALLBACK;
    }
  }

  public async dismissModal(data?: any) {
    try {
      await this.modalController.dismiss(data ?? {dismiss: true});
    } catch (e) {}
  }

  public getModalController() {
    return this.modalController;
  }
}
