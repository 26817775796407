import {Pipe, PipeTransform} from '@angular/core';
import {
  CUSTOMER_CARD_ACTIVATED,
  CUSTOMER_CARD_NOT_ACTIVATED,
  CUSTOMER_CARD_NOT_ASSIGNED, CUSTOMER_CARD_REDEEMED, CUSTOMER_CARD_SOLD,
} from '../../models/status/voucher.status.constants';
import {TranslateService} from '@ngx-translate/core';
import {CustomerCard} from '../../models/voucher.status';

@Pipe(
  {
    name: 'customerCardPipe'
  }
)
export class CardStatePipe implements PipeTransform {

  public constructor(private t: TranslateService) {
  }

  public async transform(value: CustomerCard, ...args: any[]): Promise<string> {
    switch (value.cardStatus) {
      case CUSTOMER_CARD_NOT_ASSIGNED:
        return await this.t.get('customerCardState.notAssigned').toPromise();
      case CUSTOMER_CARD_NOT_ACTIVATED:
        return await this.t.get('customerCardState.notActivated').toPromise();
      case CUSTOMER_CARD_ACTIVATED:
        return await this.t.get('customerCardState.activated').toPromise();
      case CUSTOMER_CARD_SOLD:
        return await this.t.get('customerCardState.sold').toPromise();
      case CUSTOMER_CARD_REDEEMED:
        return await this.t.get('customerCardState.redeemed').toPromise();
    }
  }
}
