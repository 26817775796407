import {Component, OnDestroy, OnInit} from '@angular/core';
import {IonItemSliding, IonRouterOutlet} from '@ionic/angular';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SystemCredential} from '../../../../../shared-libs/lib-core/src/authentication/authentication';
import {SystemService} from '../../../../../shared-libs/lib-core/src/services/system.service';
import {UIFactoryService, UIType} from '../../../../../shared-libs/lib-core/src/services/ui-factory.service';
import {AuthenticationService} from '../../../../../shared-libs/lib-core/src/authentication/authentication.service';
import {Environment} from '../../../../../shared-libs/lib-core/src/models/environment';
import {environment} from '../../../../environments/environment';
import {AccountSystemAddSystemComponent} from './account-system-add-system/account-system-add-system.component';
import {ConnectionService} from '../../../../../shared-libs/lib-core/src/services/connection.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import {SwiperOptions} from 'swiper';
import {Platform} from '@ionic/angular';
import {UIManagerService} from '../../../../../shared-libs/lib-core/src/services/ui-manager.service';
import {getPlatform} from '../../../../../shared-libs/lib-core/src/utilities/helper/utils';
import {Route} from '../../../../../shared-libs/lib-core/src/models/page.interface';
import {StorageKey, StorageService} from '../../../../../shared-libs/lib-core/src/services/storage.service';

export interface SystemConfiguration {
  storeName: string;
  isPortal: string;
}

@Component({
  selector: 'app-system',
  templateUrl: './account-system.component.html',
  styleUrls: ['./account-system.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(10px)'}),
        animate('500ms', style({opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        animate('500ms', style({opacity: 0, transform: 'translateY(10px)'})),
      ]),
    ]),
  ]
})
export class AccountSystemComponent implements OnInit, OnDestroy {
  public systems: SystemCredential[] = [];
  public subscriptions: Subscription[] = [];
  public environment: Environment = environment;
  public system: string;
  public swiperOptions: SwiperOptions;

  constructor(
    private authenticationService: AuthenticationService,
    private systemService: SystemService,
    private uiFactory: UIFactoryService,
    private uiManager: UIManagerService,
    private ionRouterOutlet: IonRouterOutlet,
    private platform: Platform,
    private storageService: StorageService,
    private router: Router,
    public t: TranslateService,
    public connectionService: ConnectionService,
  ) {
    this.subscriptions.push(this.systemService.currentSystem$.subscribe(async system => {
      this.system = system;
      await this.updateRegisteredSystems();
    }));

    this.adjustSlidingView(this.platform);
  }

  ngOnInit(): void {
  }

  private adjustSlidingView(platform: Platform) {
    this.platform.ready().then(() => {
      if (platform.width() >= 660) {
        this.swiperOptions = {

          slidesPerView: 3,
          spaceBetween: 2,
          initialSlide: 0,
        };
      } else if (platform.width() >= 1000) {
        this.swiperOptions = {
          slidesPerView: 4,
          spaceBetween: 2,
          initialSlide: 0,
        };
      } else {
        this.swiperOptions = {
          slidesPerView: 1.15,
          spaceBetween: 5,
          initialSlide: 0,
        };
      }
    });
  }

  public async updateRegisteredSystems() {
    await this.systemService.getSystems().then(systems => {
      systems.forEach(s => s.host === this.system ? s.isActive = true : s.isActive = false);
      systems.sort((s1, s2) => s1.isActive ? -1 : 1);

      this.systems = systems;
    });
  }

  public async switchSystem(system: SystemCredential) {
    await Haptics.impact({style: ImpactStyle.Medium});

    if (this.connectionService.isOffline$.getValue()) {
      this.uiFactory.build(UIType.TOAST, {
        message: await this.t.get('common.changeSystemFailure').toPromise(),
        duration: 1500
      }).then(t => t.present());

      return;
    }
    const loader = await this.uiFactory.build(UIType.LOADR, {message: await this.t.get('common.loading').toPromise()});
    await loader.present();
    this.authenticationService.switchSystemTo(system).then(() => {
      setTimeout(async () => {
        await loader.dismiss();
        await this.storageService.remove(StorageKey.CACHE);
        await this.uiManager.showSwitchedToSystem(system.storeName);
        await this.router.navigate([Route.MAIN]);
      }, 1500);
    });
  }

  public async showAddSystemModal() {
    await Haptics.impact({style: ImpactStyle.Medium});

    this.uiFactory.build(UIType.MODAL, {
      component: AccountSystemAddSystemComponent,
      mode: 'ios',
      showBackdrop: true,
      presentingElement: getPlatform(this.platform) ? this.ionRouterOutlet.nativeEl : null,
      canDismiss: true,
    }).then(t => t.present());
  }

  public async onRemoveSystem(system: SystemCredential) {
    await Haptics.impact({style: ImpactStyle.Heavy});

    this.uiFactory.build(UIType.ALERT, {
      header: await this.t.get('accountSystem.removeSystem').toPromise(),
      subHeader: await this.t.get('common.confirmSystemDelete').toPromise(),
      buttons: [
        {
          role: 'cancel',
          text: await this.t.get('common.cancelBtn').toPromise(),
        },
        {
          text: await this.t.get('accountSystem.remove').toPromise(),
          handler: () => this.removeSystem(system)
        },
      ]
    }).then(t => t.present());
  }

  private async removeSystem(system: SystemCredential) {
    await this.uiFactory.build(UIType.LOADR, {
      message: await this.t.get('common.loading').toPromise(),
      duration: 1000
    }).then(l => l.present());
    try {
      await this.systemService.removeSystem(system);
    } catch (e) {
      this.uiFactory.build(UIType.ALERT, {
        header: e.message
      }).then(t => t.present());
    }

    setTimeout(async () => {
      this.uiFactory.build(UIType.TOAST, {
        message: await this.t.get('common.inactiveSystemDelete').toPromise(),
        icon: 'checkmark-outline',
        duration: 1000
      })
        .then(toast => {
          toast.present();
          this.updateRegisteredSystems();
        });
    }, 1500);
  }

  public async slide(slider: IonItemSliding, side: 'start' | 'end') {
    await slider.open(side);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
